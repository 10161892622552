import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { serverUrl } from "../../variables/serverUrl";
import noPage from "../../assets/img/no_page.png";

export default function FAQ() {
  const [postList, setPostList] = useState([]);
  const [errorImage, setErrorImage] = useState();
  const [tab, setTab] = useState("전체");

  const category = ["전체", "탄소발자국", "챌린지", "랭킹", "회원"];

  const slideTab = {
    slidesPerView: "auto",
    spaceBetween: 30,
  };

  const loadPostData = async () => {
    const param = "&subject=" + tab;
    try {
      const res = await axios.get(
        serverUrl + "community/index?category=faq" + param
      );
      setPostList(res.data.data.boardInfo);
    } catch (err) {
      setErrorImage(noPage);
    }
  };

  useEffect(() => {
    loadPostData();
  }, [tab]);

  return (
    <div id="faq" className="container">
      <Swiper className="faq_tab_slide" {...slideTab}>
        {category.map((el, idx) => {
          return (
            <SwiperSlide
              key={idx}
              className={el === tab ? "select" : ""}
              onClick={() => {
                setTab(el);
              }}
            >
              {el}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="list_wrap list_ty01">
        {postList.map((el, idx) => {
          return <PostItem key={idx} data={el} />;
        })}
      </div>
    </div>
  );
}

function PostItem({ data }) {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <div className="list">
      <div
        onClick={() => {
          setShowDetail(!showDetail);
        }}
        className={"list_question " + (showDetail ? "show" : "")}
      >
        <span className="category c_darkGray">[{data.wr_subject}]</span>
        <p className="text question">{data.wr_seo_title}</p>
        <button className="btn_more"></button>
      </div>
      <div className="list_answer">
        <p className="text answer">{data.wr_content}</p>
      </div>
    </div>
  );
}
