import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useCheckToken } from "../../hooks/bundle_hooks";
import QnaHis from "./QnaHis";
import QnaInquiry from "./QnaInquiry";

export default function Qna() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { mb_no, postData } = useCheckToken();
  const [categoryList, setCategoryList] = useState([]);

  const loadCategoryData = async () => {
    const res = await postData("inquire/index", { mb_no, qa_status: "" });
    setCategoryList(res.data.category);
  };

  useEffect(() => {
    loadCategoryData();
  }, []);

  return (
    <div id="qna" className="container">
      <ul className="tab_btn">
        {[
          ["문의내역", "qnaHis"],
          ["문의하기", "inquiry"],
        ].map((el, idx) => {
          return (
            <li key={idx} className={id === el[1] ? "select" : ""}>
              <button
                type="botton"
                onClick={() => {
                  navigate("/Qna/" + el[1]);
                }}
              >
                {el[0]}
              </button>
            </li>
          );
        })}
      </ul>
      {/* {id === "qnaHis" && (
        <div className="filter_box d-flex flex-ac flex-js">
          <span>전체보기</span>
          <button className="btn_filter" onClick={setCloseToast}></button>
        </div>
      )} */}
      {
        {
          qnaHis: <QnaHis />,
          inquiry: <QnaInquiry categoryList={categoryList} />,
        }[id]
      }
      {/* {closeToast ? (
        <div className="toast_wrap">
          <div className="dim" onClick={() => setCloseToast(false)}></div>
          <div className="toast">
            <div className="toast_head">
              <h3 className="toast_tit">필터</h3>
              <button className="btn_close" onClick={() => setCloseToast(false)}>
                &times;
              </button>
            </div>
            <div className="toast_body">
              <ul className="select_list">
                <li className="select">전체보기</li>
                <li>답변 완료</li>
                <li>답변 대기중</li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
}
