import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { joinDataAtom, referralAtom } from "../../recoil/atoms";
import { passUrl, serverUrl } from "../../variables/bundle_variables";
import arrowRight from "../../assets/img/icon/angle_thin_right_g.svg";
import axios from "axios";

export default function JoinAgree() {
  const navigate = useNavigate();
  const [joinData, setJoinData] = useRecoilState(joinDataAtom);
  const [referral, setReferral] = useRecoilState(referralAtom);
  const [checked, setChecked] = useState([]);
  const [agreeText, setAgreeText] = useState();
  const [agreeType, setAgreeType] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("referral");

  const agreeItems = [
    {
      id: "agree01",
      type: "service",
      dsec: "(필수) 서비스 이용약관에 동의합니다.",
      required: true,
    },
    {
      id: "agree02",
      type: "handling_privacy",
      dsec: "(필수) 개인정보 처리방침에 동의합니다.",
      required: true,
    },
    {
      id: "agree03",
      type: "collecting_privacy",
      dsec: "(필수) 개인정보 수집 및 이용에 동의합니다.",
      required: true,
    },
    {
      id: "agree04",
      type: "marketing",
      dsec: "(선택) 마케팅 수신에 동의합니다.",
      required: false,
    },
  ];

  const loadAgreeText = async () => {
    const res = await axios.get(serverUrl + "auth/policy");
    // const res = await axios.get("https://gl-iot-api.wizclass.kr/auth/policy");
    const dataObj = {};
    res?.data?.data.forEach((el) => {
      dataObj[el.ca_name] = el;
    });
    setAgreeText({ ...dataObj });
  };

  const agreeCheck = (e) => {
    if (e.target.checked) setChecked([...checked, e.target.id]);
    else setChecked([...checked].filter((el) => el !== e.target.id));
  };

  const agreeCheckAll = (e) => {
    if (e.target.checked) setChecked(agreeItems.map((el) => el.id));
    else setChecked([]);
  };

  const essentialCheck = () => {
    const requiredItem = agreeItems.filter((el) => el.required);
    return !requiredItem.every((el) => checked.includes(el.id));
  };

  const nextStep = async () => {
    try {
      const res = await axios.get(passUrl + "kcpcert_generator.php");
      if (agreeItems.length === checked.length) {
        // console.log("광고동의");
        setJoinData({ agreeAD: 1, uuid: res.data.uuid });
      } else {
        // console.log("광고거부");
        setJoinData({ agreeAD: 0, uuid: res.data.uuid });
      }
      navigate("/JoinPass");
    } catch (err) {
      return alert("다시 시도해주세요.");
    }
    // navigate("/JoinPass", { state: { uuid: "2127ce75b9ff86225f0ff72b3dfd287dc10acb051ac0f7638a5ed87b742331e9" } });
  };

  const searchReferral = async () => {
    const url = "referral/find";
    const queryString = `target=${paramValue}&type=referral_link`;
    const referralRes = await axios.get(`${serverUrl}${url}?${queryString}`);
    if (referralRes.data.code === 200) {
      setReferral({
        link: referralRes.data.searchResult[0].mb_referral_link,
        mb_id: referralRes.data.searchResult[0].mb_id,
      });
    } else {
      return alert("유효하지 않은 추천인입니다.");
    }
  };

  useEffect(() => {
    setJoinData({});
    loadAgreeText();
    if (paramValue !== null && paramValue !== "") {
      searchReferral();
    }
  }, []);

  return (
    <div
      id="joinAgree"
      className={
        agreeType ? "join stop_scroll calc_content" : "join calc_content"
      }
    >
      <div className="inner">
        <h2 className="join_tit">
          반가워요! <br /> <span className="c_green">그린톡</span> 입니다.
        </h2>
        <div className="agree_box">
          <h3 className="agree_tit">약관동의</h3>
          <div className="check_box">
            <div
              className={
                "input_wrap check_type radius all_check " +
                (agreeItems.length === checked.length ? "active" : "")
              }
            >
              <label htmlFor="allAgree">
                <input
                  type="checkbox"
                  id="allAgree"
                  checked={agreeItems.length === checked.length}
                  readOnly
                  onClick={agreeCheckAll}
                />
                <span>전체 약관에 동의합니다.</span>
              </label>
            </div>
            {agreeItems.map((el, idx) => {
              return (
                <div
                  key={idx}
                  className="input_wrap check_type radius check_item"
                >
                  <label htmlFor={el.id}>
                    <input
                      type="checkbox"
                      id={el.id}
                      checked={checked.includes(el.id)}
                      readOnly
                      onClick={agreeCheck}
                    />
                    <span>{el.dsec}</span>
                  </label>
                  <img
                    src={arrowRight}
                    onClick={() => {
                      setAgreeType(el.type);
                    }}
                    alt="arrow"
                  ></img>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* 필수항목 전체 동의 시 활성화 > disabled 클래스 삭제 */}
      <button
        className={"btn_confirm bBtn btn_ty01"}
        disabled={essentialCheck()}
        onClick={nextStep}
      >
        휴대폰 본인 인증
      </button>
      {agreeType && (
        <div className="agree_detail">
          <div className="agree_contents post">
            <h1>{agreeText[agreeType].wr_subject}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: agreeText[agreeType].wr_content,
              }}
            ></p>

            <div className="btn_wrap">
              <button
                className="btn_ty01 bBtn"
                onClick={() => {
                  setAgreeType();
                }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
