import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import noPage from "../../assets/img/no_page.png";
import { serverUrl } from "../../variables/serverUrl";

export default function EventNewsDetail() {
  const { pathname } = useLocation();
  const [postData, setPostData] = useState();
  const [errorImage, setErrorImage] = useState();

  const loadPostData = async () => {
    const wr_data = pathname.split("/").reverse();
    try {
      const res = await axios.get(
        serverUrl +
          "community/show?category=" +
          wr_data[1] +
          "&wr_id=" +
          wr_data[0]
      );
      console.log(res);
      setPostData(res.data.data.boardInfo[0]);
    } catch (err) {
      setErrorImage(noPage);
    }
  };

  useEffect(() => {
    loadPostData();
  }, []);

  if (errorImage) {
    return (
      <div className="no_data_wrap">
        <img src={errorImage} alt="error_image"></img>
      </div>
    );
  }

  return (
    <div id="event_news_detail" className="container community">
      <div className="detail_head">
        <Link to="/EventNews" className="back_to_list">
          <span className="arrow arrow_left"></span>
          <span>목록으로</span>
        </Link>
      </div>

      {postData?.topimage && (
        <div className="banner_area">
          <img
            src={serverUrl + "images" + postData.topimage}
            alt="상단 이미지"
          />
        </div>
      )}
      <div className="detail_wrap">
        <h4 className="title">{postData?.wr_seo_title}</h4>
        <div className="text_wrap">
          <p>{postData?.wr_content}</p>
        </div>
      </div>
    </div>
  );
}
