import { useRecoilState } from "recoil";
import { calcDataAtom } from "../recoil/atoms";
import { cloneDeep } from "../utils/bundle_utils";

export function useCalcSelect(cat) {
  const [calcData, setCalcData] = useRecoilState(calcDataAtom);

  const questCat = (arr, target) => {
    if (arr?.title === target) 
    {
      //죽은 로직으로 판단됨
      console.log("1차 리턴");
      return (arr.select = !arr.select);
    }

    for (let el of arr.category) 
    {
      //하위 카테고리가 있으면 재귀
      if (el.category) 
      {
        console.log("재귀 탑니다" + el.category);
        questCat(el, target);
      }
      else if (el.title === target) 
      {
        //선택한 카테고리면 상태 반전
        el.select = !el.select;
      }
      else
      {
        //선택한게 아니면 모두 취소
        el.select = false;
      }

    
    }
  };

  const selectMainCat = e => {
    let copy = cloneDeep(calcData);
    //선택된 카테고리의 현 선택상태를 가져온다
    const find = copy.find(el => el.title === e.target.dataset.title);
    let nowSelected = find.select;

    //전부 돌면서 select를 False로 바꾼다
    for (let item of copy) {
      item.select = false;
    }

    //선택된 카테고리만 반대 상태로 바꾼다
    find.select = !nowSelected;
    setCalcData(copy);
  };

  const selectSubCat = e => {
    let copy = cloneDeep(calcData);
    const find = copy.find(el => el.title === cat);
    questCat(find, e.target.dataset.title);
    setCalcData(copy);
  };

  const selectCheck = (title, arr) => {
    if (!arr) {

      if (cat) 
      {
        arr = calcData.find(el => el.title === cat).category;
      }
      else
      {
        arr = calcData;
      } 
    }

    let result = "";

    for (let el of arr) {
      if (el.title === title && el.select) 
      {
        return "active";
      }
      else if (el.category) 
      {
        result = selectCheck(title, el.category);
        if (result === "active") 
        {
          return "active";
        }
      }
    }
    return result;
  };

  return { selectMainCat, selectSubCat, selectCheck };
}
