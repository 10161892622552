import logo from "../../assets/img/login_logo.png";
import introImg from "../../assets/img/intro01@2x.png";
import introImg2 from "../../assets/img/intro02@2x.png";

export default function Intro() {
  return (
    <div id="intro" className="intro d-flex">
      <img src={logo} alt="GREEN TALK" className="intro_logo" />
      <div className="img_wrap">
        <img src={introImg} alt="인트로 이미지" className="intro_img intro01" />
        <img src={introImg2} alt="인트로 이미지2" className="intro_img intro02" />  
      </div>
    </div>
  );
}