let serverUrl = "http://localhost:5050/";
let passUrl = "https://gl-iot-hpauth.wizclass.kr/";

if (window.location.href.includes("greentalk")) {
  serverUrl = "https://api.greentalk.kr/";
} else if (window.location.href.includes("gl-iot")) {
  serverUrl = "https://gl-iot-api.wizclass.kr/";
}

export { serverUrl, passUrl };
