import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import "../../assets/scss/device.css";
import backIcon from "../../assets/img/device/1.START-_-Back-icon.png";
import deviceIcon from "../../assets/img/device/1.START-_-barimi-icon.png";
import questionIcon from "../../assets/img/device/1.START-_-question-icon.png";

export default function DeviceHeader(props) {

  const navigate = useNavigate();

  function moveToBack() {
    navigate(-1);
  }

  return (
    <>
      <div className="device_header">
        <Link >
          <img src={backIcon} alt="뒤로가기" onClick={moveToBack} />
        </Link>
        {/* <Link to={"/DeviceHome/main"}>
          <img src={deviceIcon} alt="바리미_로고" />
        </Link> */}
        <p className="header_msg">{props.msg}</p>
        <Link to={"https://barimi.kr/"}>
          <img src={questionIcon} alt="문의하기" />
        </Link>
      </div>
      {/* {props.intro === true && (
        <div className="device_intro">
          <h4>안녕하세요 고객님</h4>
        </div>
      )} */}
    </>
  );
}
