import { useEffect, useState } from "react";
import Spiner from "../../components/SpinerAni";
import { useCheckToken } from "../../hooks/bundle_hooks";
import bannerImg from "../../assets/img/ch_bn.png";
import noHistory from "../../assets/img/no_history.png";
import { serverUrl } from "../../variables/serverUrl";

export default function ActiveChallenge() {
  const [closeToast, setCloseToast] = useState(false);
  const [filter, setFilter] = useState("참여중");
  const { mb_no, postData, resData, errorImage } = useCheckToken();
  const [isLoadData, setIsLoadData] = useState(false);

  const loadData = async () => {
    await postData("history/point/index", {
      mb_no,
      filter: filter === "참여중" ? 0 : 1,
    });
    setIsLoadData(true);
    console.log(resData);
  };

  useEffect(() => {
    loadData();
  }, [filter]);

  return (
    <>
      <div className="filter_box d-flex flex-ac flex-js"  style={{display: 'none'}}>
        <span>{filter}</span>
        <button className="btn_filter" onClick={setCloseToast}></button>
      </div>
      <div className="content_wrap">
        <div className="my_challenge">
          {!isLoadData && <Spiner />}
          {isLoadData && !resData?.challengeList[0] && (
            <div className="no_data_wrap">
              <img src={errorImage ? errorImage : noHistory}></img>
            </div>
          )}
          {resData?.challengeList.map((el, idx) => {
            return <ChellengItem key={idx} data={el} filter={filter} />;
          })}
        </div>
      </div>
      {closeToast && (
        <div className="toast_wrap">
          <div className="dim" onClick={() => setCloseToast(false)}></div>
          <div className="toast">
            <div className="toast_head">
              <h3 className="toast_tit">필터</h3>
              <button
                className="btn_close"
                onClick={() => setCloseToast(false)}
              >
                &times;
              </button>
            </div>
            <div className="toast_body">
              <ul className="select_list">
                {["참여중", "참여완료"].map((el, idx) => {
                  return (
                    <li
                      key={idx}
                      className={filter === el ? "select" : ""}
                      onClick={() => {
                        setFilter(el);
                        setCloseToast(false);
                      }}
                    >
                      {el}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function ChellengItem({ data, filter }) {
  const [showList, setShowList] = useState(false);
  const { mb_no, postData, resData } = useCheckToken();

  const loadDetailData = () => {
    postData("history/point/show", { mb_no, challenge_no: data.challenge_no });
  };

  if (data)
    
    return (
      <>
        <div
          className={"challenge_box " + (filter === "참여완료" ? "ch_end" : "")}
          onClick={() => {
            if (!showList && !resData) loadDetailData();
            setShowList(!showList);
          }}
        >
          <div className="inner">
            <div className="banner">
              {data.file && (
                <img src={serverUrl + "images" + data.file} alt="" />
              )}
            </div>
            <p className="ch_range">
              {data.ch_start_at} ~ {data.ch_end_at}
            </p>
            <p className="ch_title">{data.ch_title}</p>
            <p className="ch_description">
              {data.ch_stamp_board}회 참여시 도장 1개 적립 , 일일 최대 도장 {data.ch_stamp_count}개 적립,<br></br>
              도장 {data.stamp_to_point}개 적립 시{" "}
              {Number(data.ch_point_price)?.toLocaleString("ko-KR")}포인트 지급
            </p>
            <div className="info_box d-flex flex-jc">
              <div className="box">
                <span className="info_tit">시작일</span>
                <strong className="info_desc">
                  {data.start_at.split("T")[0].replace(/-/g, ".")}
                </strong>
              </div>
              <div className="box point">
                <span className="info_tit">적립 포인트</span>
                <strong className="info_desc">
                  {Number(data.points)?.toLocaleString("ko-KR") || 0}
                </strong>
              </div>
              <div className="box">
                <span className="info_tit">내가 쓴 글 개수</span>
                <strong className="info_desc">{data.boards}</strong>
              </div>
            </div>
            <button
              className={showList ? "btn_arrow up" : "btn_arrow"}
              onClick={() => {
                if (!showList && !resData) loadDetailData();
                setShowList(!showList);
              }}
            ></button>
            {showList && resData && (
              <ul className="ch_list">
                <li className="ch_list_tit d-flex flex-ac flex-js">
                  <span>글등록일</span>
                  <span>적립 포인트</span>
                  <span>좋아요 수</span>
                </li>
                {resData.history.map((el, idx) => {
                  return (
                    <li key={idx} className="d-flex flex-ac flex-js">
                      <span>{el.date.replace(/-/g, ".")}</span>
                      <span>{el.po_point?.toLocaleString("ko-KR") || 0}</span>
                      <span>
                        {Number(el.likes)?.toLocaleString("ko-KR") || 0}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </>
    );
}
