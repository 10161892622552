import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCheckToken } from "../../hooks/bundle_hooks";
import logo from "../../assets/img/login_logo.png";

export default function LoginIntro() {
  const { login } = useCheckToken();
  const { state } = useLocation();
  const [account, setAccout] = useState({ mb_id: "", mb_password: "" });

  const handleAccount = (e) => {
    setAccout({ ...account, [e.target.dataset.type]: e.target.value });
  };

  const dataSubmit = async (e) => {
    login(account, state?.url);
  };

  const activeEnter = (e) => {
    if (e.key === "Enter") {
      dataSubmit();
    }
  };

  // console.log(state?.url);

  return (
    <div id="login">
      <div className="logo_wrap">
        <img src={logo} alt="GREEN TALK" className="logo" />
      </div>
      <div className="login_area">
        <div className="input_ty01">
          <label htmlFor="">
            아이디
            <input
              type="text"
              placeholder="아이디를 입력하세요."
              data-type="mb_id"
              value={account.mb_id}
              onChange={handleAccount}
              onKeyDown={(e) => activeEnter(e)}
            />
          </label>
        </div>
        <div className="input_ty01">
          <label htmlFor="">
            비밀번호
            <input
              type="password"
              placeholder="비밀번호를 입력하세요."
              data-type="mb_password"
              value={account.mb_password}
              onChange={handleAccount}
              onKeyDown={(e) => activeEnter(e)}
            />
          </label>
        </div>
        {/* <div className="input_wrap check_type">
          <label htmlFor="saveId">
            <input
              type="checkbox"
              id="saveId"
              checked={saveId}
              onChange={e => {
                setSaveId(!saveId);
              }}
            />
            <span>아이디 저장</span>
          </label>
        </div> */}
        <button
          className="btn_ty01"
          disabled={!(account.mb_id && account.mb_password && true)}
          onClick={dataSubmit}
        >
          로그인
        </button>
      </div>
      <div className="find_area">
        <Link to="/FindID" className="find_btn">
          아이디 찾기
        </Link>
        <Link to="/FindPw" className="find_btn pw">
          | 비밀번호 찾기 |
        </Link>
        <Link to="/JoinAgree" className="find_btn">
          회원가입
        </Link>
      </div>
      <div className="to_main_area">
        <Link to="/">로그인하지 않고 둘러보기</Link>
      </div>
      <div className="another_login" style={{display: 'none'}}>
        <button className="hidden kakao">카카오로 로그인</button>
        <button className="hidden naver">네이버로 로그인</button>
        <button className="hidden google">구글로 로그인</button>
        <button className="hidden apple">애플로 로그인</button>
      </div>
    </div>
  );
}
