import { useState } from "react";
import { useUploadFile, useCheckToken } from "../../hooks/bundle_hooks";
import { useNavigate } from "react-router-dom";

export default function QnaInquiry({ categoryList }) {
  const navigate = useNavigate();
  const allowType = ["jpg",  "png", "pdf"];
  const [inquiryTypeSel, setInquiryTypeSel] = useState(false);
  const [inquiryType, setInquiryType] = useState("");
  const { fileData, letter, uploadFile, deleteFile, writing } = useUploadFile(allowType, 8, 3);
  const { mb_no, postData } = useCheckToken();
  //중복 입력 방지
  const [isSubmit, setIsSubmit] = useState(false);

  const dataSubmit = async () => {
    
    //중복 뮤택스
    if (isSubmit) 
    {
      return;
    }
    setIsSubmit(true);

    const formData = new FormData();
    formData.append("mb_no", mb_no);
    formData.append("qa_category", categoryList.indexOf(inquiryType));
    formData.append("letter", letter);
    fileData.forEach(el => {
      formData.append("inquirefile", el.file);
    });
    const res = await postData("inquire/create", formData);
    console.log("res : " + res);
    if(res != undefined)
    {
       //뮤택스 해제 - 성공이든 실패든 (일시적 실패일수 있으니 재시도 기회를 위하여)
       setIsSubmit(false);

      if (res.code === 200) 
      {
        alert("등록되었습니다.");
        return navigate("/Qna/qnaHis");
      }
      else
      {
        
        alert("등록 실패");
      }
    }
    else
    {
      alert("등록 실패");
    }
  };

  return (
    <div className="qa_wrap inner">
      <div className={inquiryTypeSel ? "input_ty02 select_input show" : "input_ty02 select_input"} onClick={() => setInquiryTypeSel(!inquiryTypeSel)}>
        <input type="text" placeholder="문의유형 선택" value={inquiryType} readOnly />
        {inquiryTypeSel && (
          <ul className="select_box">
            {categoryList.map((el, idx) => {
              return (
                <li key={idx} onClick={() => setInquiryType(el)}>
                  {el}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <textarea placeholder="내용입력" className="textarea" value={letter} onChange={writing}></textarea>
      <div className="file_wrap input_ty02">
        <label htmlFor="file">+ 파일첨부</label>
        <input type="file" id="file" onChange={uploadFile} multiple />
        {fileData[0] &&
          fileData.map((el, idx) => {
            return (
              <div className="file_list_wrap block" key={idx}>
                <input type="text" className="file_name" value={el.file.name} readOnly />
                <button className="btn_del" data-url={el.url} onClick={deleteFile}>
                  &times;
                </button>
              </div>
            );
          })}
        <p className="file_info c_darkGray">jpg, png, pdf / 8MB 이하 첨부 가능 </p>
      </div>
      <div className="btn_wrap">
        <button className="btn_ty01 gray bBtn half" onClick={() => navigate(-1)}>취소</button>
        {/* 클릭 시 모달 */}
        <button className="btn_ty01 bBtn half right" onClick={dataSubmit} disabled={!(letter && inquiryType)}>
          문의하기
        </button>
      </div>
    </div>
  );
}
