import { useState, useEffect } from "react";
import Chart from "react-apexcharts";

export default function ChartAreaStacked({ chartData }) {
  const [options, setOptions] = useState({
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
      background: "transparent",
      stacked: true,
    },
    fill: {
      gradient: {
        opacityFrom: 0.3,
        opacityTo: 0.08,
      },
    },
    labels: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    grid: { show: false },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      fontSize: "12px",
      labels: {
        colors: "#8A92A6",
      },
      fontFamily: "Pretendard-Regular",
      itemMargin: {
        horizontal: 16,
      },
      offsetY: -10,
      markers: {
        width: 10,
        height: 10,
        offsetX: -6,
        offsetY: 1,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 3,
      labels: {
        show: false,
      },
    },
  });
  const [chartSeries, setChartSeries] = useState([
    {
      name: "투입량",
      data: [],
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setChartSeries([
        {
          ...chartSeries[0],
          data: chartData,
        },
      ]);
    };

    fetchData();
  }, [chartData]);

  return (
    <div className="chart_area">
      <Chart options={options} series={chartSeries} type="area" height={350} />
    </div>
  );
}
