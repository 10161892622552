import { useRecoilState } from "recoil";
import { calcDataAtom } from "../../recoil/atoms";
import { useCheckToken } from "../../hooks/bundle_hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CalculatorResult({ initial }) {
  const navigate = useNavigate();
  const [calcData, setCalcData] = useRecoilState(calcDataAtom);
  const [resultData, setResultData] = useState();
  const { isLogin, mb_no, postData } = useCheckToken(true);
  const [memberName, setMemberName] = useState();

  const dataSubmit = async () => {
    const res = await postData("calculator/carbon", {
      mb_no,
      carbon_arr: calcData,
    });
    const mbRes = await postData("dashboard/index", {
      mb_no,
    });

    console.log("res : " + res);
    console.log("res.data : " + res.data);
    console.log("res.data.총계산 : " + res.data.총계산);

    setResultData(res.data);
    setMemberName(mbRes.data);
  };

  const resultSum = (type) => {
    if (!resultData[type]) return;
    const target = resultData[type];
    let result = 0;
    if (typeof Object.values(target)[0] !== "object") {
      result += target["계산"];
    } else {
      for (let prop in target) {
        if (prop === "계산") continue;
        result += target[prop]["계산"];
      }
    }
    return result?.toLocaleString("ko-KR") || 0;
  };

  const resetData = () => {
    setCalcData(initial);
  };

  useEffect(() => {
    if (!resultData) dataSubmit();
    return () => {
      resetData();
    };
  }, []);

  return (
    <div id="calculator_result" className="container">
      <div className="inner">
        <div className="calc_result_head">
          <strong className="result_head_text">
            {memberName?.memInfo.mb_name
              ? memberName?.memInfo.mb_name + "님이 "
              : ""}
            입력하신 정보로
            <br />
            계산 된 이산화탄소 배출량은 <br />
            <span className="c_green">
              총 {resultData?.총계산?.toLocaleString("ko-KR") || 0}kg
            </span>{" "}
            입니다.
          </strong>
          <p className="result_head_bottom_text">
            이만큼의 이산화탄소를 없애기 위해서는 <br />{" "}
            <span className="c_green">
              약 {resultData?.필요나무?.toLocaleString("ko-KR") || 0}
            </span>{" "}
            그루의 소나무를 심어야 합니다.
          </p>
        </div>
        <div className="calc_result">
          <ul className="list">
            {resultData &&
              Object.keys(resultData).map((el, idx) => {
                if (["총계산", "필요나무"].includes(el)) return;
                const target = Object.values(resultData[el]);
                const calcResult = target.pop();
                const stringData = target.join(" / ");
                return (
                  <li key={idx}>
                    <p className="category_total d-flex flex-ac flex-js">
                      <em className="left">{el}</em>
                      <span className="c_green right"> {resultSum(el)}kg</span>
                    </p>

                    {typeof Object.values(resultData[el])[0] === "object" &&
                      Object.keys(resultData[el]).map((el02, idx) => {
                        if (el02 === "계산") return;
                        const target = Object.values(resultData[el][el02]);
                        const calcResult = target.pop();
                        console.log("calcResult : " + calcResult);
                        const stringData = el02.includes("항공")
                          ? el02 +
                            " / " +
                            target.shift() +
                            " / " +
                            target.join("-")
                          : el02.split("(")[0] + " / " + target.join(" / ");
                        return (
                          <p key={idx} className="d-flex flex-ac flex-js">
                            <span className="left">{stringData} </span>
                            <span className="right">
                              {calcResult?.toLocaleString("ko-KR") || 0}kg
                            </span>
                          </p>
                        );
                      })}

                    {typeof Object.values(resultData[el])[0] !== "object" && (
                      <p key={idx} className="d-flex flex-ac flex-js">
                        <span className="left">{stringData} </span>
                        <span className="right">
                          {calcResult?.toLocaleString("ko-KR") || 0}kg
                        </span>
                      </p>
                    )}
                  </li>
                );
              })}
          </ul>
          <p className="calc_total d-flex flex-ac flex-js">
            <em className="left">총</em>
            <span className="c_green right">
              {resultData?.총계산?.toLocaleString("ko-KR") || 0}kg
            </span>
          </p>
        </div>
        <div className="btn_wrap">
          <button
            className={"btn_ty01 gray bBtn " + (isLogin ? "half" : "")}
            onClick={() => {
              navigate("/Calculator/Start");
            }}
          >
            계산 다시 하기
          </button>
          {isLogin && (
            <button
              className="btn_ty01 bBtn half right"
              onClick={() => {
                navigate("/MyActive/calc");
              }}
            >
              지난 계산 내역
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
