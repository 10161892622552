import { Link } from "react-router-dom";
import "../../assets/scss/device.css";
import DeviceHeader from "./DeviceHeader";
import DeviceLodingIcon from "../../assets/img/device/6.Done_icon.png";
import DeviceLodingImage from "../../assets/img/device/6.done_image.png";

export default function DeviceBluetoothDone() {
  return (
    <div id="device" className="container init">
      <DeviceHeader msg={`안녕하세요 고객님.`} />
      <div className="device_wrap">
        <div className="device_inner">
          <div className="device_guide">
            <p>기기 등록이 완료되었습니다.</p>
          </div>
          <div className="device_bluetooth" style={{ paddingTop: "0px" }}>
            <img
              className="device_img"
              src={DeviceLodingImage}
              alt="바리미_이미지"
            />
          </div>
        </div>

        <div className="device_btn">
          <Link to={"/DeviceHome/weight"}>다음</Link>
        </div>
      </div>
    </div>
  );
}
