import { useEffect, useState } from "react";
import { useEssentialInfo } from "../../hooks/bundle_hooks";
import axios from "axios";
import { serverUrl } from "../../variables/serverUrl";
import { useRecoilState } from "recoil";
import { findIDAtom } from "../../recoil/atoms";
import { useNavigate } from "react-router-dom";

export default function FindID() {
  const { form, valid, validPass, errorCheck } = useEssentialInfo();
  const [count, setCount] = useState({ min: 5, sec: 0 });
  const [isGetCertNum, setIsGetCertNum] = useState(false);
  const [certNum, setCertNum] = useState();
  const [findID, setFindID] = useRecoilState(findIDAtom);
  const navigate = useNavigate();

  const startCount = () => {
    if (count.min === 0 && count.sec === 0) return;
    setTimeout(() => {
      if (count.sec === 0) {
        return setCount({ min: count.min - 1, sec: 59 });
      } else {
        setCount({ ...count, sec: count.sec - 1 });
      }
    }, 1000);
  };

  // 인증시간 카운팅
  const countFormatting = () => {
    let min = "0" + count.min;
    let sec = count.sec > 9 ? count.sec : "0" + count.sec;
    return min + ":" + sec;
  };

  // 인증번호 받기
  const getCertNum = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("mb_hp", form.mb_hp.val);
    formData.append("mb_name", form.mb_name.val);
    formData.append("mb_birth", form.mb_birth.val);
    const res = await axios.post(
      "https://gl-iot-hpauth.wizclass.kr/register_auth_phone.php",
      formData
    );
    // 문자 발송 완료 시 인증번호 입력란 노출
    if (res.data.code === 200) {
      setIsGetCertNum(true);
    }
    alert(res.data.msg);
  };

  // 인증번호 유효성 검사
  const handleCertNum = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/, "");
    if (e.target.value.length > 6) e.target.value = e.target.value.slice(0, 6);
    setCertNum(e.target.value);
    console.log(certNum);
  };

  // 인증번호 검사, 성공 시 아이디 찾기 결과화면으로 이동
  const dataSubmit = async (e) => {
    e.preventDefault();
    console.log({
      mb_name: form.mb_name.val,
      mb_birth: form.mb_birth.val,
      mb_hp: form.mb_hp.val,
      cert_num: certNum,
    });
    const res = await axios.post(`${serverUrl}auth/check`, {
      mb_name: form.mb_name.val,
      mb_birth: form.mb_birth.val,
      mb_hp: form.mb_hp.val,
      cert_num: certNum,
    });
    if (res.data.code === 200) {
      setFindID({ mb_id: res.data.data });
      navigate("/FindIDComplete");
    } else alert(res.data.msg);
  };

  useEffect(() => {
    if (isGetCertNum) startCount();
  }, [count]);

  return (
    <div id="findId" className="join inner find">
      <div className="form_wrap">
        <form action="">
          <div className={"input_ty01 " + errorCheck("mb_name")?.state}>
            <label htmlFor="">
              이름
              <input
                type="text"
                placeholder="홍길동"
                data-type="mb_name"
                value={form.mb_name.val}
                onChange={valid}
                readOnly={isGetCertNum}
                maxLength={10}
              />
            </label>
            {errorCheck("mb_name")?.alert}
          </div>
          <div className={"input_ty01 " + errorCheck("mb_birth")?.state}>
            <label htmlFor="">
              생년월일
              <input
                type="text"
                placeholder="YYYYMMDD"
                data-type="mb_birth"
                value={form.mb_birth.val}
                onChange={valid}
                readOnly={isGetCertNum}
                maxLength={8}
              />
            </label>
            {errorCheck("mb_birth")?.alert}
          </div>
          <div className={"d-flex flex_wrap " + errorCheck("mb_hp")?.state}>
            <div className="input_ty01">
              <label htmlFor="">
                휴대폰 인증
                <input
                  type="text"
                  placeholder="숫자만 입력"
                  data-type="mb_hp"
                  value={form.mb_hp.val}
                  onChange={valid}
                  readOnly={isGetCertNum}
                  maxLength={11}
                />
              </label>
            </div>
            {/* 인증받기 클릭 시 모달 창 - 디자인 참고 */}
            <button
              className="btn_ty01 small radius line"
              disabled={!form.mb_hp.isValid}
              onClick={getCertNum}
            >
              인증받기
            </button>
            {errorCheck("mb_hp")?.alert}
          </div>
          {/* 인증받기 클릭 시 노출 - 디자인 참고 */}
          {isGetCertNum && (
            <div className="d-flex flex_wrap">
              <div className="input_ty01 accredit_num">
                <label htmlFor="">
                  인증번호
                  <input type="text" onChange={handleCertNum} />
                </label>
                <span className="time">{countFormatting()}</span>
              </div>
              {/* 인증번호 입력 완료 후 disabled 클래스 삭제 */}
              <button className="btn_ty01 small radius" onClick={dataSubmit}>
                인증확인
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
