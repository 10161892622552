import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useSelectInfo,
  useSelectBox,
  useCheckToken,
} from "../../hooks/bundle_hooks";
import { editInfo } from "../../utils/bundle_utils";
import FindAddr from "../../components/SearchAddr";

export default function MypageSelect() {
  const navigate = useNavigate();
  const { choiceForm, setChoiceForm, dataSel } = useSelectInfo();
  const { resData, mb_no, postData, updateUserInfo, errorImage } =
    useCheckToken();
  const [addrBox, setAddrBox] = useState("");

  const { selectList, handleSelectBox } = useSelectBox({
    transSel: false,
    carSel: false,
    oilSel: false,
    methodSel: false,
    jobSel: false,
    abilitySel: false,
  });

  const dataSubmit = async (e) => {
    e.preventDefault();
    const res = await postData("mypage/update", {
      ...choiceForm,
      mb_no,
      type: "select",
    });
    console.log(res);
    if (res.code === 200) {
      alert("정보 수정이 완료되었습니다.");
      return navigate("/Mypage");
    } else {
      alert("정보 수정에 실패했습니다.");
    }
  };

  useEffect(() => {
    postData("mypage/edit", { mb_no, type: "select" });
  }, []);

  useEffect(() => {
    updateUserInfo(choiceForm, setChoiceForm);
  }, [resData]);

  if (errorImage) {
    return (
      <div className="no_data_wrap">
        <img src={errorImage}></img>
      </div>
    );
  }
  return (
    <div id="mypage_select" className="container join">
      <div className="inner mypage ">
        <h3 className="mypage_tit">
          선택정보
          {/* <span className="s_info">
            (선택정보 모두 입력하시면 <span className="c_green">1,000</span>{" "}
            포인트를 선물해드려요!)
          </span> */}
        </h3>

        <div className="form_wrap select">
          <form action="">
            <div className="d-flex flex_wrap">
              <div className="input_ty01 no-margin">
                <label htmlFor="">
                  주소
                  <input
                    placeholder="주소입력"
                    type="text"
                    value={choiceForm.addr}
                    readOnly
                  />
                </label>
              </div>
              <button
                type="button"
                className="btn_ty01 small radius line"
                onClick={() => {
                  setAddrBox("active");
                }}
              >
                주소찾기
              </button>
            </div>
            <div className="input_ty01 edit">
              <button className="btn_edit" onClick={editInfo}></button>
              <label htmlFor="">
                상세주소
                <input
                  type="text"
                  placeholder="상세주소입력"
                  data-type="detail_addr"
                  value={choiceForm.detail_addr}
                  onChange={dataSel}
                  readOnly
                />
              </label>
            </div>
            <div className="radio_group">
              <span className="tit">주택형태</span>
              {[
                ["아파트", "apartment"],
                ["다세대", "multi"],
                ["단독", "alone"],
              ].map((el, idx) => {
                return (
                  <div key={idx} className="check_type radio">
                    <label htmlFor={el[1]}>
                      <input
                        type="radio"
                        id={el[1]}
                        name="house"
                        data-type="residence_type"
                        data-value={el[0]}
                        onClick={dataSel}
                        checked={
                          choiceForm.residence_type === el[0] ? true : false
                        }
                      />
                      <span>{el[0]}</span>
                    </label>
                  </div>
                );
              })}
            </div>
            <div
              className="input_ty01 select_input"
              onClick={() => handleSelectBox("transSel")}
            >
              <label htmlFor="">주요 이동수단</label>
              <input
                type="text"
                placeholder="선택하세요"
                value={choiceForm.transportation}
                readOnly
              />
              {selectList.transSel && (
                <ul className="select_box">
                  {[
                    "자가용",
                    "지하철",
                    "버스",
                    "기차",
                    "오토바이",
                    "자전거",
                    "도보",
                  ].map((el, idx) => {
                    return (
                      <li
                        key={idx}
                        data-type="transportation"
                        data-value={el}
                        onClick={dataSel}
                      >
                        {el}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div
              className="input_ty01 select_input"
              onClick={() => handleSelectBox("carSel")}
            >
              <label htmlFor="">차종</label>
              <input
                type="text"
                placeholder="선택하세요"
                value={choiceForm.car_type}
                readOnly
              />
              {selectList.carSel && (
                <ul className="select_box">
                  {[
                    "없음",
                    "경형",
                    "소형",
                    "준중형",
                    "중형",
                    "준대형",
                    "대형",
                    "스포츠카",
                  ].map((el, idx) => {
                    return (
                      <li
                        key={idx}
                        data-type="car_type"
                        data-value={el}
                        onClick={dataSel}
                      >
                        {el}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            {choiceForm.car_type !== "없음" && (
              <>
                <div className="input_ty01 CC">
                  <label htmlFor="">
                    차종 배기량
                    <input
                      type="text"
                      placeholder="숫자만 입력"
                      data-type="cc"
                      value={choiceForm.cc}
                      onChange={dataSel}
                    />
                  </label>
                </div>
                <div
                  className="input_ty01 select_input"
                  onClick={() => handleSelectBox("oilSel")}
                >
                  <label htmlFor="">유종</label>
                  <input
                    type="text"
                    placeholder="선택하세요"
                    value={
                      choiceForm.car_type === "없음"
                        ? "없음"
                        : choiceForm.oil_type
                    }
                    readOnly
                  />
                  {selectList.oilSel && (
                    <ul className="select_box">
                      {[
                        "없음",
                        "가솔린",
                        "디젤",
                        "하이브리드",
                        "LPG",
                        "전기",
                        "수도",
                      ].map((el, idx) => {
                        return (
                          <li
                            key={idx}
                            data-type="oil_type"
                            data-value={el}
                            onClick={dataSel}
                          >
                            {el}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </>
            )}

            <div className="radio_group">
              <span className="tit">음식물 처리기 소유 여부</span>
              {[
                ["있음", "yes"],
                ["없음", "no"],
              ].map((el, idx) => {
                return (
                  <div key={idx} className="check_type radio">
                    <label htmlFor={el[1]}>
                      <input
                        type="radio"
                        id={el[1]}
                        name="have"
                        data-type="ownership"
                        data-value={el[0]}
                        onClick={dataSel}
                        checked={choiceForm.ownership === el[0] ? true : false}
                      />
                      <span>{el[0]}</span>
                    </label>
                  </div>
                );
              })}
            </div>
            {choiceForm["ownership"] === "있음" ? (
              ""
            ) : (
              <div
                className="input_ty01 select_input"
                onClick={() => handleSelectBox("methodSel")}
              >
                <label htmlFor="">음식물 쓰레기 처리 방식</label>
                <input
                  type="text"
                  placeholder="선택하세요"
                  value={choiceForm.handling}
                  readOnly
                />
                {selectList.methodSel && (
                  <ul className="select_box">
                    {[
                      "공동주택 세대별 카드",
                      "공동주택 종량제 스티커",
                      "음식물 전용 봉투 및 전용 용기",
                    ].map((el, idx) => {
                      return (
                        <li
                          key={idx}
                          data-type="handling"
                          data-value={el}
                          onClick={dataSel}
                        >
                          {el}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            )}
            <div
              className="input_ty01 select_input"
              onClick={() => handleSelectBox("jobSel")}
            >
              <label htmlFor="">직업</label>
              <input
                type="text"
                placeholder="선택하세요"
                value={choiceForm.job}
                readOnly
              />
              {selectList.jobSel && (
                <ul className="select_box">
                  {["주부", "공무원", "회사원", "자영업", "학생", "무직"].map(
                    (el, idx) => {
                      return (
                        <li
                          key={idx}
                          data-type="job"
                          data-value={el}
                          onClick={dataSel}
                        >
                          {el}
                        </li>
                      );
                    }
                  )}
                </ul>
              )}
            </div>
            <div
              className="input_ty01 select_input"
              onClick={() => handleSelectBox("abilitySel")}
            >
              <label htmlFor="">최종학력</label>
              <input
                type="text"
                placeholder="선택하세요"
                value={choiceForm.graduation}
                readOnly
              />
              {selectList.abilitySel && (
                <ul className="select_box">
                  {[
                    "대학원",
                    "대학",
                    "전문대",
                    "고등학교",
                    "중학교",
                    "초등학교",
                    "해당사항 없음",
                  ].map((el, idx) => {
                    return (
                      <li
                        key={idx}
                        data-type="graduation"
                        data-value={el}
                        onClick={dataSel}
                      >
                        {el}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="radio_group marry">
              <span className="tit">결혼여부</span>
              {[
                ["기혼", "married"],
                ["미혼", "single"],
              ].map((el, idx) => {
                return (
                  <div key={idx} className="check_type radio">
                    <label htmlFor={el[1]}>
                      <input
                        type="radio"
                        id={el[1]}
                        name="marry"
                        data-type="marriage"
                        data-value={el[0]}
                        onClick={dataSel}
                        checked={choiceForm.marriage === el[0] ? true : false}
                      />
                      <span>{el[0]}</span>
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="btn_wrap">
              <Link to="/Mypage" className="btn_ty01 cancel bBtn half">
                취소
              </Link>
              <button
                type="submit"
                className="btn_ty01 bBtn half right"
                onClick={dataSubmit}
              >
                수정
              </button>
            </div>
          </form>
        </div>
      </div>
      <FindAddr
        addrBox={addrBox}
        setAddrBox={setAddrBox}
        choiceForm={choiceForm}
        setChoiceForm={setChoiceForm}
      />
    </div>
  );
}
