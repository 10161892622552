import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useCheckToken } from "../../hooks/bundle_hooks";
import "swiper/css/pagination";
import Profile from "../../components/Profile";

export default function Ranking() {
  const { isLogin, mb_no, postData, errorImage } = useCheckToken();
  const [myRanking, setMyRanking] = useState();
  const [rankerList, setRankerList] = useState([]);
  const [moreView, setMoreView] = useState(false);

  // console.log(serchDate.toLocaleDateString());

  const loadRankingData = async () => {
    const res = await postData("ranking/all", { mb_no });
    console.log(res);
    setMyRanking(res.data.myRanking[0]);
    setRankerList(res.data.rankResult);
  };

  useEffect(() => {
    loadRankingData();
  }, []);

  if (errorImage) {
    return (
      <div className="no_data_wrap">
        <img src={errorImage}></img>
      </div>
    );
  }
  return (
    <div id="ranking" className="container">
      {isLogin && (
        <div className="profile_box line d-flex flex-ac flex-js">
          <div className="pf d-flex flex-ac">
            <Profile width="70" height="70" url={myRanking?.mb_profile} />
            <span className="user_id">{myRanking?.mb_id}</span>
          </div>
          <div className="current_ranking">
            <p className="text">
              현재 랭킹 : {myRanking?.ranking === 0 ? "-" : myRanking?.ranking}
              위
            </p>
            {/* 주소 미입력시 */}
            {/* <Link to="/MypageSelect" className="text empty_addr">주소 입력하고 <br />내 랭킹을 확인하세요</Link> */}
          </div>
        </div>
      )}

      <div className="total_ranking line">
        <div className="ranking_graph">
          <h5 className="tit">명예의 전당</h5>
          {/* <div className="graph_box inner d-flex">
            {rankerList &&
              rankerList.map((el, idx) => {
                if (idx > 4) return false;
                return (
                  <div key={idx} className="graph">
                    <span className="user_name">{el.mb_id === myRanking?.mb_id ? "Me" : el.mb_id}</span>
                    <Profile width="25" height="25" url={el.profile} />
                    <div className="graph_bar">
                      <span className="ranking_num">{idx + 1}</span>
                    </div>
                  </div>
                );
              })}
          </div> */}
        </div>
        {/* 랭킹 최대 30위 까지 노출 */}
        <ul className="ranking_list">
          {rankerList &&
            rankerList.map((el, idx) => {
              if (!moreView && idx > 4) return false;
              return (
                <li key={idx} className="list">
                  <span className="num">{el.ranking}</span>
                  <div className="pf d-flex flex-ac">
                    <Profile width="44" height="44" url={el.mb_profile} />
                    <div className="text_wrap">
                      <span className="user_name">{el.mb_id}</span>
                      {/* 사용자가 선택정보에서 주소 입력한 경우에만 주소 노출 */}
                    </div>
                  </div>
                  <span className="point">
                    {Number(el.point)?.toLocaleString("ko-KR") || 0}p
                  </span>
                </li>
              );
            })}
        </ul>
        {rankerList?.length > 5 && !moreView && (
          <button
            className="btn_more"
            onClick={() => {
              setMoreView(true);
            }}
          ></button>
        )}
      </div>

      <div className="foot_banner">
        <Link to="">
          <p>
            자연으로 되돌리는 지속가능한 <br />
            GL시스템 음식물 처리기
          </p>
        </Link>
      </div>
    </div>
  );
}
