export default function TrafficType05(props) {
  const { handleInput, dataForm } = props;
  return (
    <div className="calc_traffic">
      <div className="traffic_wrap step_box step05">
        <div className="q_item">
          <h5 className="question small">이동 거리를 입력해주세요.</h5>
          <div className="input_box input_ty02 d-flex flex-ac">
            <input type="text" placeholder="숫자만 입력" data-type="이동거리" value={dataForm["이동거리"]} onChange={handleInput} />
            <span>km/월</span>
          </div>
        </div>
      </div>
    </div>
  );
}
