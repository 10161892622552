import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "../../variables/bundle_variables";

export default function JoinAgreeDetail() {
  const { id } = useParams();
  const [agreeText, setAgreeText] = useState();

  const loadAgreeText = async () => {
    const res = await axios.get(serverUrl + "auth/policy");
    // const res = await axios.get("https://gl-iot-api.wizclass.kr/auth/policy");
    const dataObj = {};
    res?.data?.data.forEach(el => {
      dataObj[el.ca_name] = el;
    });
    setAgreeText({ ...dataObj });
  };

  useEffect(() => {
    loadAgreeText();
  }, []);

  if (agreeText)
    return (
      <div className="agree_detail">
        <div className="agree_contents post">
          <h1>{agreeText.all.wr_subject}</h1>
          <p dangerouslySetInnerHTML={{ __html: agreeText.all.wr_content }}></p>

          <div className="btn_wrap"></div>
        </div>
      </div>
    );
}
