import { useCalcSelect } from "../../../hooks/bundle_hooks";
export default function TravelType01() {
  const { selectSubCat, selectCheck } = useCalcSelect("여행");
  return (
    <div className="calc_travel">
      <div className="travel_wrap step_box step01 pt20">
        <h5 className="question">
          여행 시, 어떤 교통 수단을 <br />
          이용하셨나요?
        </h5>
        {/* <span className="multi">복수선택 가능</span> */}
        <ul className="category_list d-flex">
          {["자가용", "택시(여행)", "국내선항공", "국제선항공", "버스(여행)", "지하철(여행)", "일반기차(여행)"].map((el, idx) => {
            return (
              <li key={idx} className={selectCheck(el)} data-title={el} onClick={selectSubCat}>
                {el.split("(")[0]}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
