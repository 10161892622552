import { useParams, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import RankingMonthly from "./Ranking_monthly";
import RankingHof from "./Ranking_hof";

export default function Ranking() {
  const navigate = useNavigate();
  const { id } = useParams();

  const slideTab = {
    slidesPerView: "auto",
    slidesOffsetBefore: 18,
    slidesOffsetAfter: 18,
    spaceBetween: 30,
  };

  return (
    <div id="ranking" className="container">
      <Swiper className="active_tab_slide" {...slideTab}>
        {[
          ["월간 랭킹", "monthly"],
          ["명예의 전당", "hof"],
        ].map((el, idx) => {
          return (
            <SwiperSlide key={idx}>
              <button
                type="botton"
                className={id === el[1] ? "btn_link active" : "btn_link"}
                onClick={() => {
                  navigate("/Ranking/" + el[1]);
                }}
              >
                {el[0]}
              </button>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {
        {
          monthly: <RankingMonthly />,
          hof: <RankingHof />,
        }[id]
      }
    </div>
  );
}
