import { Link } from "react-router-dom";
import "../../assets/scss/device.css";
import { useEffect, useState } from "react";
import { useCheckToken } from "../../hooks/bundle_hooks";

export default function DeviceHomeStatus() {
  const { isLogin, mb_no, device_mac_id, postData } = useCheckToken();
  const [myDevice, setMyDevice] = useState({});
  const errArray = [
    "UV 에러",
    "팬 에러",
    "카트리지 에러",
    "모터 에러",
    "드럼히터 에러",
  ];

  useEffect(() => {
    const loadDeviceInfo = async () => {
      const res = await postData("device/data", {
        mb_no,
        mac_id: device_mac_id,
      });

      if (res.code === 200) {
        setMyDevice(res.data);
      }
    };

    loadDeviceInfo();
  }, []);


  function Connect_AS() {
    console.log('Connect_AS Call');

    if (window.flutter_inappwebview) {
      
      window.flutter_inappwebview.callHandler('InAPP_Connect_AS', '')
        .then(function (response) {

        });
    }
    else
    {
      alert('고객센터 1670-0972');
    }
  }

  return (
    <>
      {isLogin && (
        <div className="device_inner">
          <div className="device_status">
            {errArray.map((el, idx) => {
              return (
                <li
                  key={idx}
                  className={
                    myDevice.device_breakdown === idx + 1 ? "warning" : ""
                  }
                >
                  {el}
                </li>
              );
            })}
          </div>
          {myDevice.device_breakdown > 0 ? (
            <div className="device_status_msg">
              <p className="main_msg">
                제품 {errArray[myDevice.device_breakdown - 1]} 입니다.
              </p>
              <p className="contact_msg">고객센터에 연락하여 주세요.</p>
              <div className="contact_btn"  onClick={Connect_AS}>
              <a>연락하기</a>
              </div>
            </div>
          ) : (
            <div className="device_status_msg">
              <p className="main_msg">제품 상태가 정상입니다.</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}
