import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import "../../assets/scss/device.css";
import DeviceHeader from "./DeviceHeader";
import DeviceConnectIcon from "../../assets/img/device/3.Connection_icon.png";

export default function DeviceBluetoothStep1() {
  const navigate = useNavigate();

  var btSucData = "";

  //블루트스 연결
  function BT_ConnectClick(event) {
    // window.flutter_inappwebview 객체가 존재하는지 확인
    if (window.flutter_inappwebview) {
      // flutterHandler라는 이름의 핸들러로 메시지를 보냅니다.
      window.flutter_inappwebview.callHandler('InAPP_BT_Connect', '')
        .then(function (response) {
          // Flutter로부터의 응답을 콘솔에 출력합니다.
          btSucData = response;
          console.log('Response from Flutter: ', response);
          console.log('btSucData : ', btSucData);
        });
    } else {
      // window.flutter_inappwebview 객체가 존재하지 않으면 경고창을 띄웁니다.
      alert('해당 동작은 앱에서만 가능합니다.');
    }
  }


  function moveToNextStep() {
    // window.flutter_inappwebview 객체가 존재하는지 확인
    if (window.flutter_inappwebview) {
      // flutterHandler라는 이름의 핸들러로 메시지를 보냅니다.
      window.flutter_inappwebview.callHandler('InAPP_BT_Connect_Next', '')
        .then(function (response) {
          if(response) // 연결 성공이면 다음 페이지 이동
          {
            navigate('/DeviceBluetoothStep2');
          }
          else 
          {
          alert('GL015KP와 연결이 확인되지 않습니다.\n 블루투스 연결을 확인해 주세요');
          }
        });
    } else {
      // window.flutter_inappwebview 객체가 존재하지 않으면 경고창을 띄웁니다.
      alert('해당 동작은 앱에서만 가능합니다.');
    }
    
  }


  return (
    <div id="device" className="container init">
      <DeviceHeader msg={`바리미 AI를 시작하겠습니다.`} />
      <div className="device_wrap">
        <div className="device_inner">
          <div className="device_guide">
            <p>스마트폰에서 블루투스를 연결하여 주세요.</p>
          </div>
          <div className="device_bluetooth" style={{ paddingTop: `20px` }}>
            <img
              className="device_img"
              src={DeviceConnectIcon}
              alt="바리미_이미지"
            />
            <div className="device_bluetooth_content">
              <p>스마트폰에서 블루투스로 GL015KP 연결을 시도 합니다.</p>
              <p>
                {/*  <span>GL015KP</span>를 선택하여 주세요. */}
              </p>
              <p>
                {/* <span>GL015KP</span>를 선택한 다음 뒤로가기로 돌아오세요. */}
              </p>
            </div>
            <div className="device_btn" style={{ width: `120px`, backgroundColor: 'rgb(0, 126, 244)' }}>
              <a href="#" onClick={BT_ConnectClick}>블루투스 연결</a>
            </div>
          </div>
        </div>

        <div className="device_btn" onClick={moveToNextStep}>
          {/* <Link to={"/DeviceBluetoothStep2"}>다음</Link> */}
          <a>다음</a>
        </div>
      </div>
    </div>
  );
}
