import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import "swiper/css/pagination";
import banner from "../../assets/img/GL_banner.png";
import productImg from "../../assets/img/GL_product.png";

export default function RecommendProduct() {
  const slide = {
    modules: [Pagination],
    slidesPerView: 1,
    loop: true,
    pagination: {
      type: "fraction",
    }
  }

  return(
    <div id="recommend_product" className="container">
      <div className="inner">
        <Swiper className="basic_slide w100" {...slide}>
          <SwiperSlide><img src={banner} alt="‘편리한 친환경’ GL 미생물 음식물 처리기" /></SwiperSlide>
          <SwiperSlide><img src={banner} alt="‘편리한 친환경’ GL 미생물 음식물 처리기" /></SwiperSlide>
          <SwiperSlide><img src={banner} alt="‘편리한 친환경’ GL 미생물 음식물 처리기" /></SwiperSlide>
        </Swiper>
        <div className="product_wrap">
          <div className="img_wrap box_ty01 shadow">
            <img src={productImg} alt="음식물 처리기 이미지" />
          </div>
          <div className="text_wrap">
            <p className="pd_tit"><strong>BEST</strong><span className="line">|</span><span>음식물 처리기</span></p>
            <p className="pd_info">지엘 미생물 음식물 처리기 가정용 GL-010 시리즈</p> 
            <p className="pd_info">블랙/ 화이트 1.2kg 분쇄기 건조기</p>
          </div>
        </div>
      </div>
    </div>
  )
}