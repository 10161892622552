import { useState } from "react";
import axios from "axios";
import { serverUrl } from "../variables/serverUrl";

export default function FindReferral(props) {
  const [keyword, setKeyword] = useState("");
  const [result, setResult] = useState([]);
  const {
    referralBox,
    setReferralBox,
    choiceForm,
    setChoiceForm,
    setReferral,
  } = props;

  const SearchReferral = async (e) => {
    e.preventDefault();
    const url = "referral/find";
    const queryString = `target=${keyword}&type=mb_id`;
    const res = await axios.get(`${serverUrl}${url}?${queryString}`);

    if (res.data.code === 200) {
      setResult(res.data.searchResult);
    } else {
      alert(res.data.msg);
    }
  };

  const close = () => {
    setResult([]);
    setKeyword("");
    setReferralBox("");
  };

  return (
    <div className={"search_addr_background " + referralBox}>
      <div className="search_addr_wrap">
        <header>
          <button className="btn_close" onClick={close}>
            &times;
          </button>
          <h3>추천인 검색</h3>
          <form>
            <input
              type="text"
              value={keyword}
              placeholder="추천인 아이디 입력(3자 이상)"
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
            <button type="hidden" onClick={SearchReferral}></button>
          </form>
        </header>

        <dl className="search_addr_list">
          <p style={{ fontSize: "12px", color: "#777", marginBottom: "14px" }}>
            개인정보 보호를 위해 일부 아이디와 이름만 표시됩니다.
          </p>
          {result.map((el, idx) => {
            return (
              <dd
                key={idx}
                data-value={el.mb_id}
                data-type="referral"
                onClick={(e) => {
                  close();
                  setChoiceForm({
                    ...choiceForm,
                    referral: el.mb_referral_link,
                  });
                  setReferral(el.mb_id);
                }}
              >
                <p>{el.mb_id ? el.mb_id : el.mb_id}</p>
                <span>{el.mb_name}</span>
              </dd>
            );
          })}
        </dl>
      </div>
    </div>
  );
}
