import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import noPage from "../../assets/img/no_page.png";
import { serverUrl } from "../../variables/serverUrl";

export default function TipReference() {
  const navigate = useNavigate();
  const [postList, setPostList] = useState([]);
  const [errorImage, setErrorImage] = useState();

  const loadPostData = async () => {
    try {
      const res = await axios.get(serverUrl + "community/index?category=tip");
      setPostList(res.data.data.boardInfo);
    } catch (err) {
      setErrorImage(noPage);
    }
  };

  useState(() => {
    loadPostData();
  }, []);

  if (errorImage) {
    return (
      <div className="no_data_wrap">
        <img src={errorImage}></img>
      </div>
    );
  }
  return (
    <div id="tip_reference" className="container community">
      {/* 게시글 0개일 때 */}
      {!postList[0] && <p className="empty_text">게시글이 없습니다.</p>}
      <div className="inner">
        {postList.map((el, idx) => {
          return (
            <div
              key={idx}
              className="tip_box"
              onClick={() => {
                navigate("/TipReferenceDetail", { state: { wr_id: el.wr_id, category: "TIP" } });
              }}
            >
              <div className="img_wrap">
                <img src={serverUrl + "images" + el.topimage} alt="탄소중립 실천가이드 자료" />
              </div>
              <h4 className="title">{el.wr_seo_title}</h4>
            </div>
          );
        })}
      </div>
    </div>
  );
}
