import { useEffect, useState } from "react";
import { useCheckToken, useDatePicker } from "../../hooks/bundle_hooks";
import Graph from "../../components/PieGraph";
import "react-datepicker/dist/react-datepicker.css";
import Profile from "../../components/Profile";
import noHistory from "../../assets/img/no_history.png";

export default function ActivePoint() {
  // const { mb_id, resData, postData } = useCheckToken("dashboard/index?mb_no=");

  const { date, start_at, end_at } = useDatePicker();
  const { mb_no, resData, postData, errorImage } = useCheckToken();
  const [tab, setTab] = useState("전체");
  const [subTab, setSubTab] = useState("전체");
  const [memInfo, setMemInfo] = useState();
  const [pointHis, setPointHis] = useState();
  const [usePoint, setUsePoint] = useState();

  const loadData = async (type) => {
    const res = await postData("dashboard/index", { mb_no });
    const pointHisRes = await postData("point/p-check", {
      mb_no,
      start_at,
      end_at,
      type: type,
    });
    if (res.data) {
      setMemInfo(res.data);
    }
    if (pointHisRes.data) {
      setPointHis(pointHisRes.data.pointHistory);
      setUsePoint(pointHisRes.data.usePoint);
    }
  };

  useEffect(() => {
    loadData("전체");
  }, []);

  return (
    <div id="my_point" className="container">
      <div className="info_box">
        <div className="profile d-flex flex-ac">
          <Profile width="70" height="70" url={memInfo?.memInfo.mb_profile} />
          <div className="text_wrap">
            <p className="user_name">{memInfo?.memInfo.mb_name}</p>
            <span className="user_id c_darkGray">{memInfo?.memInfo.mb_id}</span>
          </div>
        </div>
        <div className="point d-flex flex-ac flex-js">
          <p>MY ECO Point</p>
          <strong>
            {Number(memInfo?.memInfo.mb_point)?.toLocaleString("ko-KR") === "NaN"
              ? 0
              : Number(memInfo?.memInfo.mb_point)?.toLocaleString("ko-KR")}
            p
          </strong>
        </div>
        {/*         <div className="menu d-flex flex-ac flex-js">
          <button className="btn btn_coin">코인 변환</button>
          <button className="btn btn_emission">배출권 교환</button>
        </div> */}
      </div>
      <div className="total_point">
        <h4 className="point_tit">포인트 지급·사용 내역</h4>
        <div className="date_picker_wrap d-flex flex-ac flex-jc">
          {date.start}
          <span>~</span>
          {date.end}
        </div>
        <button
          className="btn_ty01 btn_search"
          onClick={() => {
            loadData("전체");
            setTab("전체");
          }}
        >
          검색
        </button>

        <div className="graph_area">
          {/* <div className="total_text">
            총 사용 포인트
            <span className="bold">{usePoint}p</span>
          </div>
          <div className="flex_box d-flex flex-ac flex-jc">
            <div className="graph">
              <Graph mall={usePoint} />
            </div>
            <div className="graph_value">
              <p className="data1">
                포인트 전환 <span className="value">{usePoint}</span>
              </p>
            </div>
          </div> */}
        </div>
        <div className="point_list">
          <ul className="point_tab">
            {["전체", "적립", "사용"].map((el, idx) => {
              return (
                <li key={idx} className={tab === el ? "active" : ""}>
                  <button
                    className="btn_tab"
                    onClick={() => {
                      loadData(el);
                      setTab(el);
                    }}
                  >
                    {el}
                  </button>
                </li>
              );
            })}
          </ul>
          {/* 사용탭일 때 필터링 버튼 활성화 -- ㄴ디자인 참고 */}
          {/* <div className="filter_btn_wrap d-flex flex-ac flex-js">
            <button className="btn active">전체</button>
            <button className="btn">코인</button>
            <button className="btn">배출권</button>
          </div> */}

          <div className="list">
            {/* 전체 */}
            {pointHis &&
              Object.entries(pointHis).map(([date, records]) => {
                return (
                  <li className="day" key={date}>
                    <span className="date">{date}</span>

                    {records.reverse().map((record, index) => (
                      <p className="text_line plus" key={index}>
                        <span className="text">{record.po_content}</span>
                        {record.type === "acc" ? (
                          <span className="c_green num">
                            +
                            {record.po_point
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            p
                          </span>
                        ) : (
                          <span className="c_magenta num">
                            -
                            {record.po_point
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            p
                          </span>
                        )}
                      </p>
                    ))}
                  </li>
                );
              })}

            {/* <button className="btn_more"></button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
