import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import "../../assets/scss/device.css";
import DeviceHeader from "./DeviceHeader";
import DeviceRegImage from "../../assets/img/device/2.register-bluetooth--image.png";
import DevicePairingIcon from "../../assets/img/device/2.Register-pairing-icon.png";

export default function DeviceConnect() {

  const navigate = useNavigate();


  function moveToNextStep() {
    // window.flutter_inappwebview 객체가 존재하는지 확인
    if (window.flutter_inappwebview) {
      //다음페이지로 이동
      navigate('/DeviceBluetoothStep1');
    } else {
      // window.flutter_inappwebview 객체가 존재하지 않으면 경고창을 띄웁니다.
      alert('해당 동작은 앱에서만 가능합니다.');
    }
  }


  return (
    <div id="device" className="container init">
      <DeviceHeader msg={`바리미를 구입해 주셔서 감사합니다.`} />
      <div className="device_wrap">
        <div className="device_inner">
          <div className="device_guide">
            <p>제품 AI 등록을 시작합니다.</p>
          </div>
          <img
            className="device_img"
            src={DeviceRegImage}
            alt="바리미_이미지"
          />
          <div className="device_conn">
            <div className="device_conn_content">
              <div className="device_pairing">
                <img src={DevicePairingIcon} alt="블루투스_아이콘" />
                <span>제품의 페어링 버튼을 3초 이상 눌러 주세요.</span>
              </div>
              <p>* 핸드폰 블루투스 모드에서 GL-015KP 기기를 연결하여 주세요.</p>
            </div>
          </div>
        </div>

        <div className="device_btn" onClick={moveToNextStep} >
          <a>다음</a>
        </div>
      </div>
    </div>
  );
}
