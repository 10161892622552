import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import noPage from "../../assets/img/no_page.png";
import { serverUrl } from "../../variables/serverUrl";

export default function TipReferenceDetail() {
  const { state } = useLocation();
  const [postData, setPostData] = useState();
  const [errorImage, setErrorImage] = useState();

  const loadPostData = async () => {
    try {
      const res = await axios.get(
        serverUrl + "community/show?category=tip&wr_id=" + state.wr_id
      );
      setPostData(res.data.data.boardInfo[0]);
    } catch (err) {
      setErrorImage(noPage);
    }
  };

  useEffect(() => {
    loadPostData();
  }, []);

  if (errorImage) {
    return (
      <div className="no_data_wrap">
        <img src={errorImage} alt="error_image"></img>
      </div>
    );
  }
  return (
    <div id="tip_reference_detail" className="container community">
      <div className="detail_head">
        <Link to="/TipReference" className="back_to_list">
          <span className="arrow arrow_left"></span>
          <span>목록으로</span>
        </Link>
      </div>
      <div className="banner_area">
        <img
          src={serverUrl + "images" + postData?.topimage}
          alt="사랑 나눔 모금함 기부시 10,000 포인트 적립! 첫이용 +5,000p이상 기부"
        />
      </div>
      <div className="detail_wrap">
        <h4 className="title">{postData?.wr_seo_title}</h4>
        {postData?.communityfile.map((el, idx) => {
          console.log(el);
          return (
            <a
              href={
                serverUrl +
                "download" +
                postData?.communityfile[idx] +
                "?originalname=" +
                postData?.communityfile_origin[idx]
              }
              className="download_area d-flex flex-ac flex-js"
            >
              <p className="down_tit">
                {postData?.communityfile_origin[idx]?.split("/").reverse()[0]}
              </p>
              {/* 클릭시 팝업 */}
              <button className="btn_down"></button>
            </a>
          );
        })}

        <div className="text_wrap">
          <p>{postData?.wr_content}</p>
        </div>
      </div>
    </div>
  );
}
