import { Link, useParams, useNavigate } from "react-router-dom";
import "../../assets/scss/device.css";
import DeviceHeader from "./DeviceHeader";
import DeviceReady from "./DeviceReady";
import DeviceHomeMain from "./DeviceHome_main";
import DeviceHomeWeight from "./DeviceHome_weight";
import DeviceHomeStatus from "./DeviceHome_status";
import DeviceHomeVersion from "./DeviceHome_version";

export default function DeviceHome() {
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <>
      <div id="device" className="container init">
        <DeviceHeader msg={`안녕하세요 고객님`} />
        <ul className="tab_btn">
          {[
           /*  ["바리미홈", "main"], */
            ["투입량", "weight"],
            ["장치에러", "status"],
            ["버전정보", "version"],
          ].map((el, idx) => {
            return (
              <li
                key={idx}
                onClick={() => {
                  navigate("/DeviceHome/" + el[1]);
                }}
              >
                <Link
                  className={id === el[1] ? "active" : ""}
                  to={"/DeviceHome/" + el[1]}
                >
                  {el[0]}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="device_wrap">
          {/* <DeviceReady />
          <div className="device_btn">
            <Link to={"/DeviceMain"}>확인</Link>
          </div> */}
          {
            {
              /* main: <DeviceHomeMain />, */
              weight: <DeviceHomeWeight />,
              status: <DeviceHomeStatus />,
              version: <DeviceHomeVersion />,
            }[id]
          }
        </div>
      </div>
    </>
  );
}
