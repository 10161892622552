import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { joinDataAtom, referralAtom } from "../../recoil/atoms";
import { passUrl } from "../../variables/bundle_variables";

export default function JoinPass() {
  const navigate = useNavigate();
  const [joinData, setJoinData] = useRecoilState(joinDataAtom);
  const [referral, setReferral] = useRecoilState(referralAtom);

  console.log(referral);

  const next = async () => {
    try {
      const res = await axios.get(
        passUrl + `kcpcert_check.php?uuid=${joinData.uuid}`
      );
      console.log(res);
      if (res.data.code !== 200) {
        alert("PASS 인증에 실패하였습니다.");
        return navigate("/JoinAgree");
      }
      navigate("/JoinEssential", {
        state: {
          passInfo: {
            mb_name: res.data.data.name,
            mb_birth: res.data.data.birth,
            mb_sex: res.data.data.gender,
            mb_hp: res.data.data.phone,
          },
        },
      });
    } catch (err) {
      console.log(err);
      alert("PASS 인증에 실패하였습니다.");
      return navigate("/JoinAgree");
    }
    // navigate("/JoinEssential", {
    //   state: {
    //     passInfo: {
    //       mb_name: "천채범",
    //       mb_birth: "19800101",
    //       mb_sex: "남",
    //       mb_hp: "01012345678",
    //     },
    //   },
    // });
  };

  useEffect(() => {
    // window.open(`https://gl-iot-hpauth.wizclass.kr/?uuid=${joinData.uuid}`, "pass", "width=0", "height=0");
    window.addEventListener("message", function (e) {
      // console.log(e.data.click);
      if (e.data.click == "parent") {
        next();
      }
    });
  }, []);

  return (
    <div>
      <iframe
        src={`https://gl-iot-hpauth.wizclass.kr/?uuid=${joinData.uuid}`}
        id="hp_cert"
        name="hp_cert"
        width="100%"
        height="900px"
        scrolling="yes"
      ></iframe>
    </div>
  );
}
