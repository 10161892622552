import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "../../assets/scss/device.css";
import DeviceHeader from "./DeviceHeader";
import DeviceLodingImage from "../../assets/img/device/5.Loading_image.png";
import Spiner from "../../components/SpinerAni";
import { useCheckToken } from "../../hooks/bundle_hooks";
import { useCookies } from "react-cookie";

export default function DeviceBluetoothLoading() {
  const [viewSel, setViewSel] = useState(false);
  const [showSpiner, setShowSpiner] = useState(true);
  const navigate = useNavigate();
  const { isLogin, mb_no, device_mac_id, postData } = useCheckToken();
  const [cookies, setCookie, removeCookie] = useCookies();

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowSpiner(false);
  //   }, 3000);
  //   return () => clearTimeout(timer);
  // }, []);

  //화면 호출시 로딩
  useEffect(() => {
    req_WIFI_Connect();
  }, []);

  //이전 화면에서 받은 Wifi SSID와 PW로(플러터 앱에서 기억되고 있는) WIFI 연결 시도하고 결과 성공일시 완료 페이지로 넘어간다
  //Web -> 휴대폰 -> Web
  function req_WIFI_Connect() {
    console.log('req_WIFI_Connect Call');

    if (window.flutter_inappwebview) {
      
      window.flutter_inappwebview.callHandler('InAPP_REQ_WIFI_Connect', '')
        .then(function (response) {
          const { success, macId, device_model } = response;
          if (success) {
            console.log('Connection successful, MAC ID:', macId);

            const expires = new Date();
            expires.setDate(expires.getDate() + 3);
            setCookie("device_mac_id", macId, { path: "/", expires });


            //유저 정보에 Device 정보를 등록
            const Set_Mac_ID = async () => {
              const res = await postData("device/register_mac_id", {
                mb_no,
                mac_id: macId,
                device_model : device_model,
              }); 

              const expires = new Date();
              expires.setDate(expires.getDate() + 3);
              setCookie("device_mac_id", macId, { path: "/", expires });

              if (res.code === 200) {

                //플러터에서 여기 진입을 못함
                navigate('/DeviceBluetoothDone');
                
                const expires = new Date();
                expires.setDate(expires.getDate() + 3);
                setCookie("device_mac_id", macId, { path: "/", expires });

              }
            };

            Set_Mac_ID();

            //기기 기준 최초 등록 일시 등록 포인트 지급

            //완료 페이지로 이동
            //navigate('/DeviceBluetoothDone');
          } else {
            console.error('Connection failed, MAC ID:', macId);
          }
        });
    }
  }

  return (
    <div id="device" className="container init">
      <DeviceHeader msg={`안녕하세요 고객님`} />
      {/* {showSpiner && <Spiner />} */}
      <div className="device_wrap">
        <div className="device_inner">
          <div className="device_guide">
            <p>기기 등록 중입니다.</p>
          </div>
          <div className="device_bluetooth" style={{ paddingTop: "0px" }}>
            <img
              className="device_img"
              src={DeviceLodingImage}
              alt="바리미_이미지"
            />
          </div>
        </div>

        {/* <div className="device_btn">
          <Link to={"/DeviceBluetoothDone"}>다음</Link>
        </div> */}
      </div>
    </div>
  );
}
