import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCheckToken } from "../../hooks/bundle_hooks";
import { serverUrl } from "../../variables/bundle_variables";
import close from "../../assets/img/icon/btn_close.svg";
import Profile from "../../components/Profile";
import axios from "axios";

export default function SideMenu({ sideClose }) {
  const { mb_no, device_mac_id, isLogin, resData, postData, logout } = useCheckToken(true);
  const [partnersToggle, setPartnersToggle] = useState(false);
  const [familyToggle, setFamilyToggle] = useState(false);
  const partnersTg = () => setPartnersToggle(!partnersToggle);
  const familyTg = () => setFamilyToggle(!familyToggle);
  const [policyText, setpolicyText] = useState();
  const [agreeType, setAgreeType] = useState();

  const getPolicyText = async () => {
    const res = await axios.get(serverUrl + "auth/policy");
    const dataObj = {};
    res?.data?.data.forEach((el) => {
      dataObj[el.ca_name] = el;
    });
    setpolicyText({ ...dataObj });
  };

  useEffect(() => {
    postData("dashboard/index", { mb_no });
    getPolicyText();
  }, []);

  useEffect(() => {
    document.getElementById("sideMenu").scrollTo(0, 0);
  }, [agreeType]);

  return (
    <div id="sideMenu" className={"side_menu open"}>
      <div className="side_header">
        <button className="btn_close" onClick={sideClose}>
          <img src={close} alt="닫기 아이콘" />
        </button>
      </div>
      {agreeType && (
        <div className="agree_detail">
          <div className="agree_contents post">
            <h1>{policyText[agreeType].type}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: policyText[agreeType].wr_content,
              }}
            ></p>

            <div className="btn_wrap">
              <button
                className="btn_ty01 bBtn"
                onClick={() => {
                  setAgreeType();
                }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="side_profile">
        {isLogin && (
          <div className="d-flex flex-ac">
            <Profile width="58" height="58" url={resData?.memInfo.mb_profile} />
            <div className="info_wrap">
              <p className="name">
                {resData?.memInfo.mb_name}{" "}
                <span className="user_id"> {resData?.memInfo.mb_id}</span>
              </p>
              <Link onClick={sideClose} to="/MyActive/point" className="point">
                <strong className="c_green">
                  {Number(resData?.memInfo.mb_point)?.toLocaleString("ko-KR") ===
                    "NaN"
                    ? 0
                    : Number(resData?.memInfo.mb_point)?.toLocaleString("ko-KR")}
                </strong>{" "}
                ECO Point
              </Link>
            </div>
          </div>
        )}
        {!isLogin && (
          <>
            <p className="text">로그인이 필요합니다.</p>
            <div className="d-flex flex-ac">
              <Link onClick={sideClose} to="/Login" className="p_btn btn_login">
                로그인
              </Link>
              <Link
                onClick={sideClose}
                to="/JoinAgree"
                className="p_btn btn_join"
              >
                회원가입
              </Link>
            </div>
          </>
        )}
      </div>
      <div className="quick_menu">
        {isLogin && (
          <Link
            onClick={sideClose}
            to="/ChallengeList"
            className="quick_menu_btn"
          >
            데일리 챌린지 참여
          </Link>
        )}

        <Link
          onClick={sideClose}
          to="/Calculator/Start"
          className="quick_menu_btn"
        >
          탄소발자국 계산기
        </Link>
        {isLogin && (
          <Link
            onClick={sideClose}
            to="/Ranking/monthly"
            className="quick_menu_btn"
          >
            랭킹 확인하기
          </Link>
        )}

        <Link onClick={sideClose} to="/TipReference" className="quick_menu_btn">
          탄소중립 TIP 보기
        </Link>
      </div>
      <div className="box_group">
        <div className="menu_list box_ty01">
          <h4 className="menu_tit">데일리 챌린지</h4>
          <ul className="menu_box">
            <li className="challenge">
              <Link onClick={sideClose} to="/ChallengeMain">
                데일리 챌린지
              </Link>
            </li>
            {isLogin && (
              <>
                <li className="my_challenge">
                  <Link onClick={sideClose} to="/MyActive/point">
                    MY 챌린지 현황
                  </Link>
                </li>
                {/* <li className="my_challenge_save">
                  <Link onClick={sideClose} to="/MyActive/save">
                    MY 챌린지 적립 내역
                  </Link>
                </li> */}
              </>
            )}
          </ul>
          {/*           <span className="line"></span>
          <h4 className="menu_tit">탄소 중립 실천 랭킹</h4>
          <ul className="menu_box">
            <li className="ranking">
              <Link onClick={sideClose} to="/Ranking">
                전체 랭킹 현황
              </Link>
            </li>
             {isLogin && (
              <>
                <li className="my_ranking">
                  <Link onClick={sideClose} to="/MyActive/ranking">
                    MY 랭킹 현황
                  </Link>
                </li>
              </>
            )} 
          </ul> */}
          <span className="line"></span>
          <h4 className="menu_tit">탄소발자국 계산기</h4>
          <ul className="menu_box">
            <li className="calculator">
              <Link onClick={sideClose} to="/Calculator/Start">
                탄소발자국 계산하기
              </Link>
            </li>
            {/* {isLogin && (
              <li className="my_calculator">
                <Link onClick={sideClose} to="/MyActive/calc">
                  MY 발자국 계산 내역
                </Link>
              </li>
            )} */}
          </ul>
          <span className="line"></span>
          <h4 className="menu_tit">기기관리</h4>
          <ul className="menu_box">
            <li className="gl_product">
              <Link onClick={sideClose} to="/RecommendProduct">
                GL 추천 제품
              </Link>
            </li>
            {isLogin && (
              <>
                {device_mac_id && device_mac_id.trim() !== '' && (
                  <li className="my_device">
                    <Link onClick={sideClose} to="/DeviceHome/weight">
                      MY 기기관리
                    </Link>
                  </li>
                )}
                <li className="my_device">
                  <Link onClick={sideClose} to="/DeviceInit">
                    MY 기기등록
                  </Link>
                </li>
              </>
            )}
          </ul>
          <span className="line"></span>
          <h4 className="menu_tit">커뮤니티</h4>
          <ul className="menu_box">
            <li className="notice">
              <Link onClick={sideClose} to="/EventNews">
                EVENT / NEWS
              </Link>
            </li>
            <li className="reference">
              <Link onClick={sideClose} to="/TipReference">
                탄소중립 TIP 자료실
              </Link>
            </li>
            <li className="faq">
              <Link onClick={sideClose} to="/FAQ">
                FAQ
              </Link>
            </li>
            {isLogin && (
              <li className="qa">
                <Link onClick={sideClose} to="/Qna/qnaHis">
                  1:1문의 / 내역
                </Link>
              </li>
            )}
          </ul>
          {isLogin && (
            <>
              <span className="line"></span>
              <h4 className="menu_tit">마이페이지</h4>
              <ul className="menu_box">
                <li className="info_modify">
                  <Link onClick={sideClose} to="/Mypage">
                    개인 정보 수정
                  </Link>
                </li>
                {/* <li className="my_active">
                  <Link onClick={sideClose} to="/MyActive/challenge">
                    MY 활동 내역
                  </Link>
                </li>
                <li className="my_point">
                  <Link onClick={sideClose} to="/MyEcoPoint">
                    MY ECO Point
                  </Link>
                </li> */}
              </ul>
            </>
          )}

          <span className="line"></span>
          <div
            className={
              isLogin
                ? "my_menu d-flex flex-ac flex-js"
                : "my_menu d-flex flex-ac flex-jc"
            }
          >
            <Link
              onClick={sideClose}
              to="https://mall.greentalk.kr/"
              target="_blank"
              className="my_menu_btn info_modify"
            >
              그린톡 쇼핑몰
            </Link>

            {isLogin && (
              <>
                <Link
                  onClick={sideClose}
                  to="/MyActive/point"
                  className="my_menu_btn my_point"
                >
                  MY ECO Point
                </Link>
                <Link
                  onClick={() => {
                    if (window.confirm("로그아웃 하시겠습니까?")) {
                      sideClose();
                      logout();
                    }
                    // window.location.href = window.location.href;
                  }}
                  to="/"
                  className="my_menu_btn btn_logout"
                >
                  로그아웃
                </Link>
              </>
            )}
          </div>
        </div>
        <div
          className={
            partnersToggle
              ? "partners box_ty01 toggle show"
              : "partners box_ty01 toggle"
          }
        >
          <h4 className="menu_tit" onClick={partnersTg}>
            파트너스
          </h4>
          <ul className="list">
            <li>
              <Link
                onClick={sideClose}
                to="https://www.keco.or.kr/web/index.do"
                target="_blank"
                className="link"
              >
                <span>한국 환경 공단</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={sideClose}
                to="https://www.sisul.or.kr/"
                target="_blank"
                className="link"
              >
                <span>서울특별시청 서울 시설 공단</span>
              </Link>
            </li>
          </ul>
        </div>
        <div
          className={
            familyToggle
              ? "family box_ty01 toggle show"
              : "family box_ty01 toggle"
          }
        >
          <h4 className="menu_tit" onClick={familyTg}>
            패밀리 사이트
          </h4>
          <ul className="list">
            <li>
              <Link
                onClick={sideClose}
                to="http://gl-plus.com/"
                target="_blank"
                className="link"
              >
                <span>지엘플러스 공식 홈페이지</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={sideClose}
                to="https://mall.greentalk.kr/"
                target="_blank"
                className="link"
              >
                <span>그린톡 쇼핑몰</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="foot_banner box_ty01">
          <Link
            onClick={sideClose}
            to="https://www.glecomall.com/"
            target="_blank"
          >
            <p>
              자연으로 되돌리는 지속가능한 <br />
              GL시스템 음식물 처리기
            </p>
          </Link>
        </div>
      </div>
      <div className="side_foot">
        <p>GL GREEN TALK (그린톡)</p>
        <div className="link_group">
          <Link
            onClick={() => {
              setAgreeType("service");
            }}
          >
            이용약관
          </Link>{" "}
          |
          <Link
            onClick={() => {
              setAgreeType("handling_privacy");
            }}
          >
            개인정보 처리 방침
          </Link>
        </div>
        <p className="copy">copyright © ㈜지엘플러스 ALL RIGHTS RESERVED.</p>
      </div>
    </div>
  );
}
