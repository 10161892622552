import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { joinDataAtom } from "../../recoil/atoms";
import { serverUrl } from "../../variables/bundle_variables";
import axios from "axios";

export default function JoinComplete() {
  const [joinData, setJoinData] = useRecoilState(joinDataAtom);
  const [joinState, setJoinState] = useState();

  const join = async () => {
    try {
      console.log(joinData);
      const res = await axios.post(serverUrl + "auth/join", {
        ...joinData,
      });
      if (res?.data.code !== 200) {
        console.log(res);
        return setJoinState("fail");
      }
      setJoinState("success");
    } catch (err) {
      setJoinState("fail");
    } finally {
      setJoinData({});
    }
  };

  useEffect(() => {
    join();
    setJoinState("success");
  }, []);

  if (joinState)
    return (
      <div id="joinComplete">
        {joinState === "success" ? (
          <>
            <div className="text_wrap">
              <h3>회원가입 완료</h3>
              <p>회원가입이 완료되었습니다.</p>
              <p>로그인 후 서비스 이용이 가능합니다.</p>
            </div>
            {
              /* eslint-disable-line no-restricted-globals */ self !== top ? (
                <button
                  className="bBtn btn_ty01"
                  onClick={() => {
                    window.parent.location.href =
                      "https://mall.greentalk.kr/member/login";
                  }}
                  style={{ left: "35%", bottom: "35%", width: "30%" }}
                >
                  로그인 하러가기
                </button>
              ) : (
                <Link to="/Login" className="bBtn btn_ty01">
                  로그인
                </Link>
              )
            }
          </>
        ) : (
          <>
            <div className="text_wrap">
              <h3>회원가입 실패</h3>
              <p>회원가입에 실패하였습니다.</p>
            </div>
            {
              /* eslint-disable-line no-restricted-globals */ self !== top ? (
                <></>
              ) : (
                <Link to="/Login" className="bBtn btn_ty01">
                  홈으로
                </Link>
              )
            }
          </>
        )}
      </div>
    );
}
