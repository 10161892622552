import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ChallengeComment from "./ChallengeComment";
import { useCheckToken } from "../../hooks/useCheckToken";
import { dateFormat } from "../../utils/bundle_utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import { serverUrl } from "../../variables/bundle_variables";

export default function ChallengeDetail() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isLogin, mb_no, resData, postData, errorImage } = useCheckToken(true);
  const [dotMenu, setDotMenu] = useState(false);
  const [report, setReport] = useState(false);
  const [reasonSel, setReasonSel] = useState(false);
  const [reportMsg, setReportMsg] = useState("스팸 홍보/도배글");
  const [isLiked, setIsLiked] = useState();
  const [likeTotal, setLikeTotal] = useState();
  const [replyTarget, setReplyTarget] = useState({ wr_parent: 0 });
  const [commentList, setCommentList] = useState([]);
  const [commentLength, setCommentLength] = useState();
  const [comment, setComment] = useState("");
  const pageRef = useRef(1);
  //댓글 중복 체크
  const [isSubmit, setIsSubmit] = useState(false);

  // 썸네일 슬라이드
  const imgSlide = {
    modules: [Pagination],
    slidesPerView: 1,
  };

  // 댓글 입력시 입력창 자동 height
  const resize = (e) => {
    e.target.style.height = "1px";
    e.target.style.height = 4 + e.target.scrollHeight + "px";
  };

  const reportSubmit = async () => {
    await postData("challenge/report", {
      mb_no,
      wr_id: resData.wr_id,
      report_msg: reportMsg,
    });
    setReport(false);
  };

  const loadPostData = async () => {
    // const res = await postData("challenge/show", { mb_no, wr_id: 1 });
    const res = await postData("challenge/show", { mb_no, wr_id: state.wr_id });
    setCommentList(res.data.replyContents);
    setLikeTotal(res.data.challengeContents[0].wr_good);
    setIsLiked(res.data.challengeContents[0].liked === 1);
  };

  const viewMoreComment = async () => {
    const res = await postData(
      "reply/more",
      {
        type: "main",
        mb_no,
        wr_id: state.wr_id,
        offset: pageRef.current * 3,
      },
      true
    );
    pageRef.current += 1;
    // console.log(res);
    setCommentLength(res.data.comments);
    setCommentList([...commentList, ...res.data.replyContents]);
  };

  const scrollFetch = async () => {
    if (commentList.length >= commentLength) return;
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight < scrollHeight) return;
    viewMoreComment();
  };

  const like = async () => {
    if (!isLogin) return alert("로그인 후 이용 가능합니다.");
    const { wr_id, challenge_no } = resData?.challengeContents[0];
    await postData("challenge/like", {
      mb_no,
      wr_id,
      challenge_no,
      status: isLiked ? 0 : 1,
    });
    setIsLiked(!isLiked);
    setLikeTotal(isLiked ? likeTotal - 1 : likeTotal + 1);
  };

  const addComment = async () => {
    if (!comment) return;

    if (isSubmit) {
      return;
    }
    setIsSubmit(true);

    await postData("reply/create", {
      mb_no,
      wr_num: state.wr_id,
      wr_parent: replyTarget.wr_parent,
      wr_comment: comment,
    });
    let beforeData = [];
    pageRef.current =
      commentList.length === pageRef.current * 3
        ? pageRef.current + 1
        : pageRef.current;
    for (let i = 0; i <= pageRef.current; i++) {
      const res = await postData(
        "reply/more",
        {
          type: "main",
          mb_no,
          wr_id: state.wr_id,
          offset: i * 3,
        },
        true
      );
      beforeData = [...beforeData, ...res.data.replyContents];
    }
    setCommentList(beforeData);
    setComment("");
    setReplyTarget({ wr_parent: 0 });

    setIsSubmit(false);
  };

  useEffect(() => {
    loadPostData();
    return () => {
      window.removeEventListener("scroll", scrollFetch);
      // console.log("컴포넌트 언마운트");
    };
  }, []);

  useEffect(() => {
    if (resData) window.addEventListener("scroll", scrollFetch);
    return () => {
      window.removeEventListener("scroll", scrollFetch);
    };
  }, [commentList]);

  if (errorImage) {
    return (
      <div className="no_data_wrap">
        <img src={errorImage}></img>
      </div>
    );
  }
  return (
    <div id="challenge_reply" className="container challenge">
      <div className="inner">
        <div className="calendar_head d-flex flex-ac">
          <Link to="/ChallengeMain" className="back_to_challenge">
            <span className="arrow arrow_left"></span>
            <span>챌린지 목록</span>
          </Link>
        </div>
        <div className="challenge_item">
          <div className="item_img_box">
            <div className="item_img">
              <Swiper
                className="thumbnail_slide basic_slide"
                {...imgSlide}
                pagination={true}
              >
                {resData?.challengeContents[0].file.map((el, idx) => {
                  return (
                    <SwiperSlide key={idx}>
                      <img key={idx} src={serverUrl + "images" + el} alt="" />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="item_icon">
              <button
                className={"thumb_icon like " + (isLiked ? "on" : "")}
                onClick={like}
              ></button>
              {/* <button className="thumb_icon comment"></button> */}
              {isLogin && (
                <button
                  className="thumb_icon dot"
                  onClick={() => setDotMenu(true)}
                ></button>
              )}
            </div>
          </div>
          <div className="item_text_box">
            <span className="like_length">좋아요 {likeTotal}개</span>
            <div className="item_text">
              <span className="user_id">
                {resData?.challengeContents[0].mb_id}
              </span>
              {resData?.challengeContents[0].wr_content}
            </div>
            <span className="date c_darkGray">
              {resData?.challengeContents[0].wr_datetime
                .split("T")[0]
                .replace(/-/g, ".")}
            </span>
          </div>
        </div>
        <div className="challenge_reply_item">
          {commentList.map((el, idx) => {
            return (
              <ChallengeComment
                key={idx}
                dataObj={el}
                setReplyTarget={setReplyTarget}
                challenge_no={resData?.challengeContents[0].challenge_no}
                publisher={resData?.challengeContents[0].mb_no}
                wr_parent={resData?.challengeContents[0].wr_id}
              />
            );
          })}
        </div>
      </div>

      <div className="write_box">
        <div className="inner d-flex">
          <div className="input_box">
            {replyTarget.wr_parent !== 0 && (
              <p className="comment_state">
                {replyTarget.target_id}님에게 답글 입력중&nbsp;·&nbsp;
                <button
                  onClick={() => {
                    setReplyTarget({ wr_parent: 0 });
                  }}
                >
                  취소
                </button>
              </p>
            )}
            <textarea
              id="commentInput"
              className="textarea_reply"
              placeholder={
                isLogin ? "댓글을 입력해주세요." : "로그인 후 이용 가능합니다."
              }
              value={comment}
              onKeyDown={resize}
              onKeyUp={resize}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              readOnly={!isLogin}
            ></textarea>
            <button
              className="btn_wirte c_green"
              onClick={addComment}
              disabled={!isLogin}
            >
              게시
            </button>
          </div>
        </div>
      </div>

      {dotMenu && (
        <div className="dot_menu">
          <div className="dim" onClick={() => setDotMenu(false)}></div>
          {Number(mb_no) === resData?.challengeContents[0].mb_no ? (
            <div className="btn_wrap">
              <button
                className="btn btn_modify"
                onClick={() => {
                  navigate("/ChallengeEdit", {
                    state: { wr_id: resData?.challengeContents[0].wr_id },
                  });
                }}
              >
                수정하기
              </button>
              <button className="btn btn_del c_red">삭제하기</button>
            </div>
          ) : (
            <button
              className="btn btn_report c_red"
              onClick={() => [setReport(true), setDotMenu(false)]}
            >
              게시물 신고하기
            </button>
          )}
          <button className="btn btn_cancel" onClick={() => setDotMenu(false)}>
            취소
          </button>
        </div>
      )}
      {report && (
        <div className="toast_wrap report">
          <div className="dim" onClick={() => setReport(false)}></div>
          <div className="toast">
            <div className="toast_head">
              <h3 className="toast_tit">신고</h3>
              <button className="btn_close" onClick={() => setReport(false)}>
                &times;
              </button>
            </div>
            <div className="toast_body">
              <p className="text">
                {resData?.challengeContents[0].mb_id}님의 게시물을 <br />
                신고 하시겠습니까?
              </p>
              <span className="c_darkGray">이 게시물을 신고하는 이유</span>
              <div
                className="box select_input"
                onClick={() => setReasonSel(!reasonSel)}
              >
                <input type="text" value={reportMsg} readOnly />
                {reasonSel ? (
                  <ul className="select_box">
                    {[
                      "스팸 홍보/도배글",
                      "음란성/욕설 등 유해한 내용",
                      "명예훼손 또는 저작권 침해",
                      "개인정보 노출 게시물",
                    ].map((el, idx) => {
                      return (
                        <li
                          key={idx}
                          onClick={() => {
                            setReportMsg(el);
                          }}
                        >
                          {el}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="toast_foot">
              <button className="btn_ty01" onClick={reportSubmit}>
                확인
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
