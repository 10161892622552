import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Profile from "../../components/Profile";
import { useUploadFile, useCheckToken } from "../../hooks/bundle_hooks";
import profile from "../../assets/img/profile.png";
import { serverUrl } from "../../variables/serverUrl";

export default function Mypage() {
  const allowType = ["jpg", "jpeg", "png"];
  const { mb_no, resData, postData, errorImage } = useCheckToken();
  const { fileData, setFileData, uploadFile } = useUploadFile(allowType, 8, 1);
  const [profileImage, setProfileImage] = useState();
  const [auth, setAuth] = useState(0);
  const [referralLink, setReferralLink] = useState("");
  const [moreView, setMoreView] = useState(false);

  const dataSubmit = async () => {
    const formData = new FormData();
    formData.append("mb_no", mb_no);
    formData.append("profileimage", fileData[0].file);
    const res = await postData("mypage/profile", formData);
    if (res.code !== 200) return;
    // console.log(res);
    postData("mypage/index", { mb_no });
    setProfileImage(res.data);
    setFileData([]);
  };

  const createReferral = async () => {
    const createResult = await postData("referral/create", {
      mb_no,
    });
    if (createResult.code === 200) {
      window.location.reload();
    }
  };

  /* const copyReferralLink = async () => {
    setReferralLink(resData?.essentialRes.mb_referral_link);
    await navigator.clipboard.writeText(referralLink);
    console.log(referralLink);
  }; */

  const authCheck = async () => {
    const authResult = await postData("mypage/auth-check", {
      mb_no,
    });
    console.log(authResult);
    setAuth(authResult.mb_certify);
  };

  useEffect(() => {
    postData("mypage/index", { mb_no });
    authCheck();
  }, []);

  if (errorImage) {
    return (
      <div className="no_data_wrap">
        <img src={errorImage}></img>
      </div>
    );
  }
  return (
    <div id="mypage" className="container">
      <div className="inner mypage">
        <div className="pf_wrap">
          <div className="img_wrap">
            {
              <img
                src={
                  fileData[0]
                    ? fileData[0].url
                    : resData
                    ? resData.essentialRes.mb_profile
                      ? serverUrl + "images" + resData.essentialRes.mb_profile
                      : profile
                    : profile
                }
                alt="프로필 이미지"
              />
              // <Profile width="58" height="58" url={resData?.essentialRes.mb_profile} />
            }
          </div>
          <label htmlFor="file" className="btn_photo profile_edit">
            <input
              type="file"
              id="file"
              accept="image/*"
              onChange={uploadFile}
            ></input>
          </label>
          <button
            className="btn_photo profile_save"
            onClick={dataSubmit}
            disabled={!fileData[0]}
          ></button>
        </div>
        <div className="info_wrap">
          <div className="essential">
            <div className="title_wrap d-flex flex-ac flex-js">
              <h4>필수 정보</h4>
              <Link to="/MypageEssential" className="btn_ty02">
                수정
              </Link>
            </div>
            <ul className="info">
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">추천인 링크</span>
                <span className="info_right">
                  {resData?.essentialRes.mb_referral_link === "" ? (
                    <Link
                      to=""
                      className="btn_ty02"
                      onClick={() => {
                        createReferral();
                      }}
                    >
                      발급하기
                    </Link>
                  ) : (
                    <Link
                      to=""
                      className="btn_ty02"
                      onClick={() => {
                        const link =
                          window.location.origin +
                          "/JoinAgree?referral=" +
                          resData?.essentialRes.mb_referral_link;
                        navigator.clipboard.writeText(link);
                        alert("복사되었습니다.");
                      }}
                    >
                      링크 복사
                    </Link>
                  )}
                </span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">아이디</span>
                <span className="info_right">
                  {resData?.essentialRes.mb_id}
                </span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">이메일</span>
                <span className="info_right">
                  {resData?.essentialRes.mb_email}
                </span>
              </li>

              {auth === 0 ? (
                <li className="info_line d-flex flex-ac flex-jc">
                  <Link to="/AuthPass" className="btn_ty02">
                    본인인증 하기
                  </Link>
                </li>
              ) : (
                <>
                  <li className="info_line d-flex flex-ac flex-js">
                    <span className="info_left">휴대폰 번호</span>
                    <span className="info_right">
                      {resData?.essentialRes.mb_hp}
                    </span>
                  </li>
                  <li className="info_line d-flex flex-ac flex-js">
                    <span className="info_left">이름</span>
                    <span className="info_right">
                      {resData?.essentialRes.mb_name}
                    </span>
                  </li>
                  <li className="info_line d-flex flex-ac flex-js">
                    <span className="info_left">성별</span>
                    <span className="info_right">
                      {resData?.essentialRes?.mb_sex}
                    </span>
                  </li>
                  <li className="info_line d-flex flex-ac flex-js">
                    <span className="info_left">생년월일</span>
                    <span className="info_right">
                      {resData?.essentialRes.mb_birth}
                    </span>
                  </li>
                  <li className="info_line d-flex flex-ac flex-js">
                    <span className="info_left">거주 인원 수</span>
                    <span className="info_right">
                      {resData?.essentialRes.mb_family}
                    </span>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="select">
            <div className="title_wrap d-flex flex-ac flex-js">
              <h4>선택 정보</h4>
              <Link to="/MypageSelect" className="btn_ty02">
                수정
              </Link>
            </div>
            <ul className="info">
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">추천인</span>
                <span className="info_right">
                  {resData?.selectRes.referral}
                </span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">주소</span>
                <span className="info_right">{resData?.selectRes.addr}</span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">상세주소</span>
                <span className="info_right">
                  {resData?.selectRes.detail_addr}
                </span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">주택형태</span>
                <span className="info_right">
                  {resData?.selectRes.residence_type}
                </span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">주요 이동수단</span>
                <span className="info_right">
                  {resData?.selectRes.transportation}
                </span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">차종</span>
                <span className="info_right">
                  {resData?.selectRes.car_type}
                </span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">차종 배기량</span>
                <span className="info_right">{resData?.selectRes.cc}</span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">유종</span>
                <span className="info_right">
                  {resData?.selectRes.oil_type}
                </span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">음식물 처리기 소유 여부</span>
                <span className="info_right">
                  {resData?.selectRes.ownership}
                </span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">음식물 쓰레기 처리 방식</span>
                <span className="info_right">
                  {resData?.selectRes.handling}
                </span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">직업</span>
                <span className="info_right">{resData?.selectRes.job}</span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">최종학력</span>
                <span className="info_right">
                  {resData?.selectRes.graduation}
                </span>
              </li>
              <li className="info_line d-flex flex-ac flex-js">
                <span className="info_left">결혼여부</span>
                <span className="info_right">
                  {resData?.selectRes.marriage}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {!moreView && (
        <button
          className="btn_more"
          onClick={() => {
            setMoreView(true);
          }}
        ></button>
      )}

      {moreView && (
        <div className="box_withdrawal">
          <Link to="/Withdrawal" className="btn_leave">
            회원 탈퇴
          </Link>
        </div>
      )}
    </div>
  );
}
