export default function Cooking(props) {
  const { handleInput, dataForm } = props;
  return (
    <div className="calc_cooking step_box">
      <h5 className="question small">어떤 재료를 사용하여 요리하셨나요?</h5>
      <div className="grid_box">
        {[
          "소고기",
          "돼지고기",
          "닭고기",
          "양고기",
          "양식연어",
          "참치통조림",
          "해산물",
          "계란",
          "우유",
          "치즈",
          "두부",
          "감자",
          "쌀",
          "토마토",
          "바나나",
          "다크초콜릿",
        ].map((el, idx) => {
          return (
            <div key={idx} className="input_box input_ty02">
              <label htmlFor="">{el}</label>
              <input type="text" placeholder="숫자만 입력" data-type={el} value={dataForm[el]} onChange={handleInput} />
              <span>kg</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
