import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { calcDataAtom, calcPageCountAtom } from "../recoil/atoms";
import { useCalcFlow } from "./bundle_hooks";

export function useBackEvent() {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const [calcData, setCalcData] = useRecoilState(calcDataAtom);
  const [calcPageCount, setCalcPageCount] = useRecoilState(calcPageCountAtom);
  const { initial } = useCalcFlow();

  const addWindowBackEvent = () => {
    const backMainPage = ["/Calculator/Start", "/MyDevice", "/ChallengeMain", "/MyActive", "/Login", "/JoinAgree"];

    // console.log(state);

    if (pathname === "/" || backMainPage.some(el => pathname.includes(el))) {
      // window.onpopstate = null;
      window.onpopstate = e => {
        if (e) {
          // console.log("탭메인페이지 이벤트");
          navigate("/");
          // window.onpopstate = null;
        }
      };
    } else if (pathname === "/Calculator/Result") {
      // window.onpopstate = null;
      window.onpopstate = e => {
        if (e) {
          // console.log("결과페이지 이벤트");
          setCalcData(initial);
          navigate(-calcPageCount + 1);
          setCalcPageCount(0);
          // window.onpopstate = null;
        }
      };
    } else {
      setTimeout(() => {
        window.onpopstate = null;
      }, 0);
    }
  };

  const addHeaderBackEvent = () => {
    window.onpopstate = null;
    if (pathname === "/Login" || pathname === "/JoinAgree") {
      return navigate("/");
    }
    return navigate(-1);
  };

  return { addWindowBackEvent, addHeaderBackEvent };
}
