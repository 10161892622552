import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import ActiveChallenge from "./MyActive_challenge";
import ActiveSave from "./MyActive_save";
import ActiveCalc from "./MyActive_calc";
import ActivePoint from "./MyActive_point";

export default function MyActive() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [closeToast, setCloseToast] = useState(false);

  const slideTab = {
    slidesPerView: "auto",
    slidesOffsetBefore: 18,
    slidesOffsetAfter: 18,
    spaceBetween: 30,
  };

  return (
    <div
      id="my_active"
      className={id === "ranking" ? "container white" : "container"}
    >
      <Swiper className="active_tab_slide" {...slideTab}>
        {[
          ["My Eco Point", "point"],
          ["챌린지 현황", "challenge"],
          ["도장 적립 내역", "save"],
          ["발자국 계산 내역", "calc"],
        ].map((el, idx) => {
          return (
            <SwiperSlide key={idx}>
              <button
                type="botton"
                className={id === el[1] ? "btn_link active" : "btn_link"}
                onClick={() => {
                  navigate("/MyActive/" + el[1]);
                }}
              >
                {el[0]}
              </button>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {
        {
          point: <ActivePoint />,
          challenge: <ActiveChallenge />,
          save: <ActiveSave />,
          calc: <ActiveCalc />,
        }[id]
      }
    </div>
  );
}
