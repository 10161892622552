import { Link } from "react-router-dom";
import "../../assets/scss/device.css";
import { useCheckToken } from "../../hooks/bundle_hooks";
import { useEffect, useState } from "react";
import ChartAreaStacked from "../../components/ChartAreaStacked";

export default function DeviceHomeWeight() {
  const { isLogin, mb_no, device_mac_id, postData } = useCheckToken();
  const [myChart, setMyChart] = useState();
  const [chartData, setChartData] = useState();
  const [term, setTerm] = useState("2");

  const loadDeviceInfo = async (idx = "2") => {
    const res = await postData("device/weight_graph", {
      mb_no,
      mac_id: device_mac_id,
      term: idx,
    });

    if (res.code === 200) {
      setMyChart(res);
      const data = res.data.map((el) => ({
        x: el.date,
        y: el.prog_weight,
      }));

      setTerm(idx);
      setChartData(data);
      console.log(chartData);
    }
  };

  useEffect(() => {
    loadDeviceInfo();
  }, []);

  return (
    <>
      {isLogin && (
        <div className="device_inner">
          <div className="device_guide_chart">
            <li
              className={term === "2" ? "active" : ""}
              onClick={() => {
                loadDeviceInfo("2");
              }}
            >
              1주
            </li>
            <li
              className={term === "3" ? "active" : ""}
              onClick={() => {
                loadDeviceInfo("3");
              }}
            >
              1개월
            </li>
            <li
              className={term === "4" ? "active" : ""}
              onClick={() => {
                loadDeviceInfo("4");
              }}
            >
              1년
            </li>
          </div>

          <div className="device_active" style={{ height: "506px" }}>
            {myChart?.data && <ChartAreaStacked chartData={chartData} />}
          </div>
          <div className="device_weight">
            <p className="prog_name">당일 투입량</p>
            <span className="prog_weight">
              {chartData &&
                chartData[chartData.length - 1].y?.toLocaleString("Ko-KR")}
              g
            </span>
            <br></br>
            <p className="prog_name">누적 투입량</p>
            <p className="prog_name">
              {chartData &&
                chartData
                  .reduce((acc, curr) => Number(acc) + Number(curr.y), 0)
                  ?.toLocaleString("Ko-KR")}
              g
            </p>
          </div>
        </div>
      )}
    </>
  );
}
