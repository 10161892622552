import { Link, useLocation } from "react-router-dom";
import home from "../../assets/img/icon/home.svg";
import menu from "../../assets/img/icon/menu_g.svg";
import calc from "../../assets/img/icon/calculator.svg";
import power from "../../assets/img/icon/power.svg";
import challenge from "../../assets/img/icon/challenge.svg";
import user from "../../assets/img/icon/user.svg";

export default function Gnb(props) {
  const { sideState, sideClose } = props;
  const { pathname } = useLocation();
  const exceptPage = [
    "/Splash",
    "/Intro",
    "/JoinAgree",
    "/JoinPass",
    "/JoinComplete",
    "/JoinEssential",
    "/JoinSelect",
    "/FindID",
    "/FindIDComplete",
    "/FindPw",
    "/PwResetting",
    "/DeviceQR",
    "/Withdrawal",
    "/WithdrawalComplete",
    "/ChallengeMain/Detail",
    "/ChallengeWrite",
    "/ChallengeEdit",
    "/Qna/inquiry",
    "/MypageEssential",
    "/MypageSelect",
  ];

  return (
    !exceptPage.includes(pathname) &&
    !pathname.includes("ChallengeWrite") && (
      <nav id="gnb">
        <ul className="d-flex flex-ac flex-js">
          <li className={sideState ? "active" : ""} onClick={sideClose}>
            <Link className="hidden">
              <img src={menu} alt="메뉴" />
              <span>메뉴</span>
            </Link>
          </li>
          <li className={pathname === "/" ? "active" : ""}>
            <Link to="/" className="hidden">
              <img src={home} alt="홈" />
              <span>홈</span>
            </Link>
          </li>
          <li className={pathname.includes("Calculator") ? "active" : ""}>
            <Link to="/Calculator/Start" className="hidden">
              <img src={calc} alt="계산기" />
              <span>탄소발자국</span>
            </Link>
          </li>
          <li
            className={
              pathname === "/ChallengeMain" || pathname === "/ChallengeList"
                ? "active"
                : ""
            }
          >
            <Link to="/ChallengeMain" className="hidden">
              <img src={challenge} alt="데일리 챌린지" />
              <span>챌린지</span>
            </Link>
          </li>
          <li className={pathname.includes("Mypage") ? "active" : ""}>
            <Link to="/Mypage">
              <img src={user} alt="MY 활동내역" />
              <span>마이페이지</span>
            </Link>
          </li>
        </ul>
      </nav>
    )
  );
}
