export default function SpinerAni() {
  return (
    <div className="spinner_wrap">
      <div className="spinner"></div>
    </div>
    // <div className="spiner">
    //   <div className="dot_wrap">
    //     <span className="dot dot01"></span>
    //     <span className="dot dot02"></span>
    //     <span className="dot dot03"></span>
    //     <span className="dot dot04"></span>
    //     <span className="dot dot05"></span>
    //     <span className="dot dot06"></span>
    //     <span className="dot dot07"></span>
    //     <span className="dot dot08"></span>
    //   </div>
    // </div>
  );
}
