import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "../../assets/scss/device.css";
import DeviceHeader from "./DeviceHeader";
import DeviceWifiIcon from "../../assets/img/device/4.Connection-WIFI_icon.png";
import { useCheckToken } from "../../hooks/bundle_hooks";

export default function DeviceBluetoothStep2() {
  const { mb_no } = useCheckToken();
  const [viewSel, setViewSel] = useState(false);
  const [wifiList, setWifiList] = useState([]);
  const [selectedWifi, setSelectedWifi] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    req_WIFI_List();
  }, []);

  //검색되는 Wifi 리스트를 받아와서 화면에 표출한다
  //Web -> 휴대폰 -> Web
  function req_WIFI_List() {
    console.log('req_WIFI_List Call');
    if (window.flutter_inappwebview) {
      
      window.flutter_inappwebview.callHandler('InAPP_Req_WIFI', '')
        .then(function (response) {
          // response는 플러터에서 전달된 Wi-Fi 리스트
          if (Array.isArray(response)) {
            setWifiList(response);
          }

        });
    }
  }


  //Wifi를 선택하고 비밀번호를 입력한다
  //response 로 성공이 오면 다음 화면으로 넘어간다
  //Web -> 휴대폰 -> Web
  function moveToNextStep() {

    //공유기가 선택 되었는지? 비밀번호가 입력되었는지? 확인
    if (selectedWifi === "") {
      alert('공유기를 선택해 주세요.');
      return;
    }

    if (password.length < 8) {
      alert('비밀번호는 최소 8자리 이상이어야 합니다.');
      return;
    }

    // window.flutter_inappwebview 객체가 존재하는지 확인
    if (window.flutter_inappwebview) {
      // flutterHandler라는 이름의 핸들러로 메시지를 보냅니다.
      window.flutter_inappwebview.callHandler('InAPP_WIFI_Connect_Next', { ssid: selectedWifi, pass: password, mb_no : mb_no })
        .then(function (response) {
          if (response) // 플러터쪽에서 데이터를 수신 성공이면 다음 페이지 이동
          {
            navigate('/DeviceBluetoothLoading');
          }
          else {
            alert('WIFI 연결 정보 전달에 실패 하였습니다.\n 다시 시도하여 주세요');
          }
        });
    } else {
      // window.flutter_inappwebview 객체가 존재하지 않으면 경고창을 띄웁니다.
      alert('해당 동작은 앱에서만 가능합니다.');
    }

  }

  return (
    <div id="device" className="container init">
      <DeviceHeader msg={`바리미 AI를 시작하겠습니다.`} />
      <div className="device_wrap">
        <div className="device_inner">
          <div className="device_guide">
            <p>스마트폰에서 Wifi를 사용 상태로 해주세요.</p>
          </div>
          <div className="device_bluetooth">
            <img
              className="device_img"
              src={DeviceWifiIcon}
              alt="바리미_이미지"
            />
            <div className="device_bluetooth_content">
              <p>가정에서 사용중인 WIFI를 선택하세요.</p>
              <div
                className="device_input device_select"
                onClick={() => setViewSel(!viewSel)}
              >
                <input
                  type="text"
                  placeholder="공유기를 선택해 주세요"
                  readOnly
                  value={selectedWifi} // selectedWifi 값을 입력 필드에 표시 
                />
                {viewSel && (
                  <ul className="select_box">
                    {wifiList.map((wifi, index) => (
                      <li key={index} onClick={() => setSelectedWifi(wifi)}>{wifi}</li>
                    ))}
                  </ul>
                )}
              </div>

              <p>WIFI 비밀번호를 입력해 주세요.</p>
              <div className="device_input">
                <input
                  type="password"
                  placeholder="공유기 비밀번호 입력"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="device_btn" onClick={moveToNextStep}>
          <a>다음</a>
        </div>
      </div>
    </div>
  );
}
