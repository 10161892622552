import { useState } from "react";

export default function TostAlert() {
  const [alertOpen, setAlertOpen] = useState(false);
  const openAlert = () => {
    setAlertOpen(true);
    document.getElementById('TostAlert').classList.add('show');
  }
  const closeAlert = () => {
    setAlertOpen(false);
    document.getElementById('TostAlert').classList.remove('show');
  }

  return(
    <div id="TostAlert" className={alertOpen? "show" : ""}>
      <div className="dim"></div>
      <div className="alert">
        <div className="alert_head d-flex flex-ac flex-js">
          <span className="head_text">다음에 참여 할게요</span>
          <button className="btn_close" onClick={closeAlert}>&times;</button>
        </div>
        <div className="alert_body">
          <p className="main_text">오늘 하루는 어떤 방법을 통해 <br/><span className="c_green">지구를 아껴 주셨나요?</span></p>
          <p className="sub_text">나만의 지구를 아끼는 방법 <br />공유시 최대 10,000포인트</p>
          <button className="btn_ty01 radius">챌린지 참여하기</button>
        </div>
        <div className="alert_foot">
          <div className="check_type">
            <label htmlFor="noview">
              <input type="checkbox" id="noview" />
              <span>일주일 동안 보지 않기</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}