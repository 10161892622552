import { Link } from "react-router-dom";

export default function WithdrawalComplete() {
  return(
    <div id="withdrawal_complete">
      <div className="text_wrap">
        <h3>회원 탈퇴 완료</h3>
        <p>그동안 그린톡 회원이 되어 주셔서 감사합니다!</p>
        <p>언제든지 다시 방문해 주세요!</p>
      </div>
      <Link to="/" className="bBtn btn_ty01">메인으로</Link>
    </div>
  )
}