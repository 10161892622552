import { useState, useRef, useEffect } from "react";
import { Outlet, useLocation, useNavigate, Link } from "react-router-dom";
import { useCheckToken } from "../../hooks/useCheckToken";
import { dateFormat } from "../../utils/bundle_utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import ChallengePost from "./ChallengePost";
import Spiner from "../../components/SpinerAni";
import { serverUrl } from "../../variables/bundle_variables";
import "swiper/css/pagination";
import check from "../../assets/img/icon/check_darkGray.svg";
import checkGreen from "../../assets/img/icon/check_green.svg";
import noPost from "../../assets/img/no_post.png";
import chDummy from "../../assets/img/ch_dummy.png";
export default function Challenge() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { mb_no, resData, postData } = useCheckToken(true);
  const [viewSel, setViewSel] = useState(false);
  const [onlyMe, setOnlyMe] = useState(false);
  const [challengeFilter, setChallengeFilter] = useState(false);
  const [dotMenu, setDotMenu] = useState(false);
  const [report, setReport] = useState(false);
  const [reasonSel, setReasonSel] = useState(false);
  const [reportMsg, setReportMsg] = useState("스팸 홍보/도배글");
  const [challengeList, setChallengeList] = useState([]);
  const [challengeType, setChallengeType] = useState(["전체보기", "all"]);
  const [postFilter, setPostFilter] = useState({
    only_me: "n",
    challenge_type: ["전체보기", "all"],
  });
  const [postList, setPostList] = useState([]);
  const [myPost, setMyPost] = useState([]);
  const [hidePostList, setHidePostList] = useState([]);
  const [isLoadData, setIsLoadData] = useState(false);
  const pageRef = useRef(0);

  //달력 관련 변수
  const [today, setToday] = useState({
    y: new Date().getFullYear(),
    m: new Date().getMonth(),
    d: new Date().getDate(),
  });
  const [selDate, setSelDate] = useState(new Date().getDate());
  const [saveScrollTop, setSaveScrollTop] = useState(0);
  const calendarBody = useRef(null);
  const targetPost = useRef({ wr_id: "", mb_id: "" });
  const dayList = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const prevMonth = (targetDate) => {
    let copy = { ...today };
    copy.d = new Date(copy.y, copy.m, 0).getDate();
    copy.y = copy.m < 1 ? copy.y - 1 : copy.y;
    copy.m = copy.m < 1 ? 11 : copy.m - 1;
    setToday(copy);
    if (!isNaN(targetDate)) setSelDate(targetDate);
    else setSelDate(copy.d);
  };

  const nextMonth = () => {
    if (
      today.y === new Date().getFullYear() &&
      today.m === new Date().getMonth()
    )
      return;
    let copy = { ...today };
    copy.y = copy.m === 11 ? copy.y + 1 : copy.y;
    copy.m = copy.m === 11 ? 0 : copy.m + 1;
    if (today.m + 1 === new Date().getMonth()) copy.d = new Date().getDate();
    else copy.d = new Date(copy.y, copy.m + 1, 0).getDate();
    setToday(copy);
    setSelDate(1);
  };

  const reportSubmit = async () => {
    await postData("challenge/report", {
      mb_no,
      wr_id: targetPost.current.wr_id,
      report_msg: reportMsg,
    });
    setHidePostList([...hidePostList, targetPost.current.wr_id]);
    setReport(false);
  };

  const fetchPostData = (offset) => {
    const res = postData("challenge/index", {
      mb_no,
      date: dateFormat(today.y, today.m, selDate, "-"),
      offset,
      only_me: postFilter.only_me,
      challenge_type: postFilter.challenge_type[1],
    });
    return res;
  };

  const loadPost = async () => {
    if (pageRef.current >= 1) {
      let postListCopy = [];
      let myPostCopy = [];
      for (let i = 0; i <= pageRef.current - 1; i++) {
        const res = await fetchPostData(20 * i);
        if (res.code !== 200) return setPostList([]);
        postListCopy = [...postListCopy, ...res.data.challengeContents];
        myPostCopy = [...res.data.myChallenge];
      }
      const myPostResult = myPostCopy.map((obj) => obj.wr_datetime);
      setPostList(postListCopy);
      setMyPost(myPostResult);
      console.log(myPostResult);
    } else {
      const res = await fetchPostData(0);
      if (res.code !== 200) return setPostList([]);
      setPostList(res.data.challengeContents);
      setChallengeList(res.data.challengeList);
      setIsLoadData(true);
    }
  };

  const scrollFetch = async () => {
    // console.log(document.documentElement.scrollTop);
    if (
      postList.length >= resData?.posts ||
      window.location.href.includes("Detail")
    )
      return;
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight < scrollHeight - 400) return;
    const res = await fetchPostData(20 * pageRef.current);
    pageRef.current += 1;
    setPostList([...postList, ...res.data.challengeContents]);
  };

  const deletePost = async () => {
    await postData("challenge/del", { mb_no, wr_id: targetPost.current.wr_id });
    setDotMenu(false);
    setHidePostList([...hidePostList, targetPost.current.wr_id]);
  };

  // 상단 배너 슬라이드
  const challengeSlide = {
    modules: [Autoplay, Pagination],
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      type: "fraction",
    },
  };

  useEffect(() => {
    setReportMsg("스팸 홍보/도배글");
  }, [report]);

  useEffect(() => {
    console.log("selDate : " + selDate);
    if (selDate === 1) {
      calendarBody.current.scrollLeft = 0;
      console.log("calendarBody.current.scrollLeft = 0; ");
    }
    //null 체크 추가
    else if (calendarBody != null) {
      if (calendarBody.current != null) {
        console.log(
          "calendarBody.current.scrollWidth " + calendarBody.current.scrollWidth
        );
        calendarBody.current.scrollLeft = calendarBody.current.scrollWidth;
      }
    }
  }, [today]);

  useEffect(() => {
    loadPost();
    setHidePostList([]);
    setSaveScrollTop(0);
    window.scrollTo(0, 0);
    pageRef.current = 1;
  }, [selDate, postFilter]);

  useEffect(() => {
    if (postList[0]) window.addEventListener("scroll", scrollFetch);
    if (saveScrollTop > document.documentElement.scrollTop)
      window.scrollTo(0, saveScrollTop);
    return () => {
      window.removeEventListener("scroll", scrollFetch);
    };
  }, [postList]);

  useEffect(() => {
    if (pageRef.current > 1 && pathname === "/ChallengeMain") {
      loadPost();
    } else if (pageRef.current > 1) {
      setPostList([]);
    }
  }, [pathname]);

  if (pathname === "/ChallengeMain/Detail") return <Outlet />;
  else
    return (
      <div id="challenge" className="container challenge">
        <div className="inner">
          <div className="challenge_banner">
            {!resData?.bannerInfo && (
              <Swiper className="basic_slide w100" {...challengeSlide}>
                <SwiperSlide>
                  <img src={chDummy} alt="배너" />
                </SwiperSlide>
              </Swiper>
            )}
            {resData?.bannerInfo[0] && (
              <Swiper className="basic_slide w100" {...challengeSlide}>
                {resData.bannerInfo.map((el, idx) => {
                  return (
                    <SwiperSlide key={idx}>
                      <img
                        src={serverUrl + "images" + el.bannerImage}
                        alt="함께해요 친환경 챌린지"
                        onClick={() => {
                          navigate(
                            el.bannerPath &&
                              "/ChallengeWrite/" +
                                el.bannerPath.split("/").reverse()[0]
                          );
                        }}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
          <div
            className="filter_box d-flex flex-ac flex-js"
            onClick={() => {
              setChallengeFilter(!challengeFilter);
            }}
          >
            <span>{challengeType[0]}</span>
            <button
              className="btn_filter"
              style={{ marginLeft: "auto", paddingRight: "50px" }}
              onClick={() => {
                setChallengeFilter(!challengeFilter);
              }}
            ></button>
            <Link to="/ChallengeList">
              <button class="btn_edit"></button>
            </Link>
          </div>
          <div className="calendar">
            <div className="calendar_head d-flex flex-ac flex-js">
              <button className="arrow arrow_left" onClick={prevMonth}></button>
              <strong className="year-month">
                {today.y}. {today.m < 9 ? "0" + (today.m + 1) : today.m + 1}
              </strong>
              <button
                className="arrow arrow_right"
                onClick={nextMonth}
              ></button>
            </div>
            <div
              className="calendar_body d-flex flex-ac flex-js"
              ref={calendarBody}
            >
              {today.d < 7 &&
                [...new Array(7 - today.d)].map((el, idx) => {
                  const startDate = 7 - new Date(today.y, today.m, 1).getDate();
                  let currDate = new Date();
                  currDate.setDate(today.d - (startDate - idx));
                  return (
                    <div
                      key={idx}
                      className="week lastMonth"
                      onClick={() => {
                        prevMonth(currDate.getDate());
                      }}
                    >
                      <span className={!currDate.getDay() ? "day sun" : "day"}>
                        {dayList[currDate.getDay()]}
                      </span>
                      <span className="date">{currDate.getDate()}</span>
                    </div>
                  );
                })}

              {[...new Array(today.d)].map((el, idx) => {
                const currDate = new Date(today.y, today.m, 1 + idx);
                return (
                  <div
                    key={idx}
                    className={selDate === idx + 1 ? "week select" : "week"}
                    onClick={() => {
                      setSelDate(idx + 1);
                    }}
                  >
                    <span className={!currDate.getDay() ? "day sun" : "day"}>
                      {dayList[currDate.getDay()]}
                    </span>

                    <span
                      className={
                        myPost.includes(
                          dateFormat(today.y, today.m, idx + 1, "-")
                        ) === true
                          ? "date mypost"
                          : "date"
                      }
                    >
                      {idx + 1}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          {/* 글 */}
          {postList.map((el, idx) => {
            if (hidePostList.includes(el.wr_id)) {
              return false;
            } else {
              return (
                <ChallengePost
                  key={idx}
                  dataArr={el}
                  date={dateFormat(today.y, today.m, selDate, ".")}
                  target={targetPost}
                  setDotMenu={setDotMenu}
                  setSaveScrollTop={setSaveScrollTop}
                />
              );
            }
          })}
          {/* //글 */}
          {/* 글 없을때 */}
          {!isLoadData && <Spiner />}
          {isLoadData && !postList[0] && (
            <div className="no_data_wrap">
              <img src={noPost}></img>
            </div>
          )}
          {/* //글 없을때 */}
        </div>

        {challengeFilter && (
          <div className="toast_wrap filter">
            <div
              className="dim"
              onClick={() => setChallengeFilter(false)}
            ></div>
            <div className="toast">
              <div className="toast_head">
                <h3 className="toast_tit">필터</h3>
                <button
                  className="btn_close"
                  onClick={() => {
                    setChallengeFilter(false);
                    setChallengeType(postFilter.challenge_type);
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="toast_body">
                <div
                  className={
                    onlyMe
                      ? "box only_me d-flex flex-ac flex-js active"
                      : "box only_me d-flex flex-ac flex-js"
                  }
                  onClick={() => setOnlyMe(!onlyMe)}
                >
                  내가 쓴 글만 보기{" "}
                  {onlyMe ? (
                    <img src={checkGreen} alt="" />
                  ) : (
                    <img src={check} alt="" />
                  )}
                </div>
                <div
                  className="box select_input"
                  onClick={() => setViewSel(!viewSel)}
                >
                  <input type="text" value={challengeType[0]} readOnly />
                  {viewSel && (
                    <ul className="select_box">
                      <li
                        onClick={() => {
                          setChallengeType(["전체보기", "all"]);
                        }}
                      >
                        전체보기
                      </li>
                      {challengeList.map((el, idx) => {
                        return (
                          <li
                            key={idx}
                            onClick={() => {
                              setChallengeType([el.ch_title, el.idx]);
                            }}
                          >
                            {el.ch_title}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
              <div className="toast_foot">
                <button
                  className="btn_ty01"
                  onClick={() => {
                    setChallengeFilter(false);
                    setPostFilter({
                      only_me: onlyMe ? "y" : "n",
                      challenge_type: challengeType,
                    });
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        )}

        {dotMenu && (
          <div className="dot_menu">
            <div className="dim" onClick={() => setDotMenu(false)}></div>
            {/* 내가 쓴글 아닐 때 */}
            {mb_no == targetPost.current.mb_no ? (
              <div className="btn_wrap">
                <button
                  className="btn btn_modify"
                  onClick={() => {
                    navigate("/ChallengeEdit", {
                      state: { wr_id: targetPost.current.wr_id },
                    });
                  }}
                >
                  수정하기
                </button>
                <button className="btn btn_del c_red" onClick={deletePost}>
                  삭제하기
                </button>
              </div>
            ) : (
              <button
                className="btn btn_report c_red"
                onClick={() => {
                  setReport(true);
                  setDotMenu(false);
                }}
              >
                게시물 신고하기
              </button>
            )}
            {/* <button className="btn btn_report c_red" onClick={() => [setReport(true), setDotMenu(false)]}>
            게시물 신고하기
          </button> */}
            {/* 내가 쓴글 일 때 */}
            {/* <div className="btn_wrap">
            <button
              className="btn btn_modify"
              onClick={() => {
                navigate("/ChallengeEdit", { state: { wr_id: targetPost.current } });
              }}
            >
              수정하기
            </button>
            <button className="btn btn_del c_red">삭제하기</button>
          </div> */}
            {/* 공통버튼 */}
            <button
              className="btn btn_cancel"
              onClick={() => setDotMenu(false)}
            >
              취소
            </button>
          </div>
        )}
        {report && (
          <div className="toast_wrap report">
            <div className="dim" onClick={() => setReport(false)}></div>
            <div className="toast">
              <div className="toast_head">
                <h3 className="toast_tit">신고</h3>
                <button className="btn_close" onClick={() => setReport(false)}>
                  &times;
                </button>
              </div>
              <div className="toast_body">
                <p className="text">
                  이 게시물을 <br />
                  신고 하시겠습니까?
                </p>
                <span className="c_darkGray">이 게시물을 신고하는 이유</span>
                <div
                  className="box select_input"
                  onClick={() => setReasonSel(!reasonSel)}
                >
                  <input type="text" value={reportMsg} readOnly />
                  {reasonSel ? (
                    <ul className="select_box">
                      {[
                        "스팸 홍보/도배글",
                        "음란성/욕설 등 유해한 내용",
                        "명예훼손 또는 저작권 침해",
                        "개인정보 노출 게시물",
                      ].map((el, idx) => {
                        return (
                          <li
                            key={idx}
                            onClick={() => {
                              setReportMsg(el);
                            }}
                          >
                            {el}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="toast_foot">
                <button className="btn_ty01" onClick={reportSubmit}>
                  확인
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
}
