import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useRecoilState } from "recoil";
import { joinDataAtom } from "../../recoil/atoms";
import { useEssentialInfo, useSelectBox } from "../../hooks/bundle_hooks";
import { useEffect } from "react";
import { serverUrl } from "../../variables/serverUrl";

export default function JoinEssential() {
  const { state } = useLocation();
  const { form, setForm, valid, dupCheck, validPass, errorCheck } =
    useEssentialInfo();
  const navigate = useNavigate();
  const [joinData, setJoinData] = useRecoilState(joinDataAtom);
  const { selectList, handleSelectBox } = useSelectBox({ site: false });

  /* 서버전송 JSON 데이터
  {
    agree_ad: false,
    id: "",
    pw: "",
    name: "",
    gender: "",
    birth: "",
    family: "",
    email: "",
    ph: "",
    addr: "",
    detail_addr: "",
    housing_type: "",
    how_move: "",
    car_type: "",
    how_cc: "",
    oil_type: "",
    is_have: "",
    disposal: "",
    job: "",
    ability: "",
    is_married: "",
  },
  */

  const updateUserInfo = () => {
    if (!state?.passInfo) {
      return;
      // alert("PASS 인증 후 진행 가능합니다.");
      // return navigate("/JoinAgree");
    }
    let copy = { ...form };
    for (let prop in state.passInfo) {
      copy[prop].val = state.passInfo[prop];
      copy[prop].isValid = true;
    }
    setForm(copy);
  };

  const dataSubmit = async () => {
    let copy = { ...form };
    for (let prop in copy) {
      copy = { ...copy, [prop]: copy[prop].val };
    }
    const res = await axios.post(serverUrl + "auth/e_join", {
      ...copy,
      uuid: joinData.uuid,
    });
    if (res.data.code !== 200) return alert(res.data.msg);
    setJoinData({ ...joinData, ...copy });
    navigate("/JoinSelect");
  };

  useEffect(() => {
    updateUserInfo();
  }, []);

  return (
    <div id="joinEssential" className="join">
      <div className="inner">
        <h3 className="type_tit">
          개인정보입력 <span className="c_green">(필수입력)</span>
        </h3>
        <div className="form_wrap">
          <form action="">
            <div
              className={"d-flex flex_wrap " + errorCheck("mb_id_dup")?.state}
            >
              <div className="input_ty01">
                <label htmlFor="">
                  아이디
                  <input
                    type="text"
                    placeholder="영문,숫자 조합 6자리 이상"
                    value={form.mb_id.val}
                    data-type="mb_id"
                    onChange={valid}
                  />
                </label>
              </div>
              <button className="btn_ty01 small radius line" onClick={dupCheck}>
                중복확인
              </button>
              {errorCheck("mb_id_dup")?.alert}
            </div>
            <div className={"input_ty01 " + errorCheck("mb_password")?.state}>
              <label htmlFor="">
                비밀번호
                <input
                  type="password"
                  placeholder="영문,숫자, 특수문자 조합 8자리 이상"
                  value={form.mb_password.val}
                  data-type="mb_password"
                  onChange={valid}
                />
              </label>
              {errorCheck("mb_password")?.alert}
            </div>
            <div
              className={"input_ty01 " + errorCheck("mb_password_cfm")?.state}
            >
              <label htmlFor="">
                비밀번호 재확인
                <input
                  type="password"
                  placeholder="비밀번호 재입력"
                  value={form.mb_password_cfm.val}
                  data-type="mb_password_cfm"
                  onChange={valid}
                />
              </label>
              {errorCheck("mb_password_cfm")?.alert}
            </div>
            <div className="input_ty01">
              <label htmlFor="">
                이름
                <input type="text" value={form.mb_name.val} readOnly />
              </label>
            </div>
            <div className="input_ty01">
              <label htmlFor="">
                성별
                <input type="text" value={form.mb_sex.val} readOnly />
              </label>
            </div>
            <div className="input_ty01">
              <label htmlFor="">
                생년월일
                <input type="text" value={form.mb_birth.val} readOnly />
              </label>
            </div>
            <div className="input_ty01">
              <label htmlFor="">
                거주 인원 수
                <input
                  type="text"
                  placeholder="숫자만 입력"
                  value={form.mb_family.val}
                  data-type="mb_family"
                  onChange={valid}
                />
              </label>
            </div>
            <div
              className={"d-flex flex_wrap " + errorCheck("mb_email")?.state}
            >
              <div className="input_ty01 email">
                <label htmlFor="">
                  이메일
                  <input
                    type="text"
                    placeholder="이메일"
                    value={form.mb_email.val.split("@")[0] || ""}
                    data-type="mb_email_f"
                    onChange={valid}
                  />
                </label>
              </div>
              <span className="at">@</span>
              <div
                className="input_ty01 email select_input"
                onClick={() => handleSelectBox("site")}
              >
                <input
                  type="text"
                  placeholder="선택"
                  value={form.mb_email.val.split("@")[1] || ""}
                  data-type="mb_email_b"
                  onChange={valid}
                />
                {selectList.site && (
                  <ul className="select_box">
                    {/* <li>직접입력</li> */}
                    {[
                      "naver.com",
                      "gmail.com",
                      "nate.com",
                      "daum.net",
                      "yahoo.com",
                      "hanmail.net",
                      "kakao.com",
                    ].map((el, idx) => {
                      return (
                        <li key={idx} onClick={valid} data-type="mb_email_a">
                          {el}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              {errorCheck("mb_email")?.alert}
            </div>
            <div className="input_ty01">
              <label htmlFor="">
                휴대폰 번호
                <input type="text" value={form.mb_hp.val} readOnly />
              </label>
            </div>
          </form>
        </div>
      </div>
      {/* 모든 항목 입력시 활성화 > disabled 클래스 삭제 */}
      <button
        className={"bBtn btn_ty01"}
        disabled={!validPass()}
        onClick={dataSubmit}
      >
        다음
      </button>
      {/* <Link to="/JoinSelect" className={"bBtn " + formCheck() + " btn_ty01"}>다음</Link> */}
    </div>
  );
}
