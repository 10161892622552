import { useState, useRef, useEffect } from "react";
import { useCheckToken } from "../../hooks/useCheckToken";
import Profile from "../../components/Profile";

export default function ChallengeComment({
  dataObj,
  setReplyTarget,
  challenge_no,
  publisher,
  wr_parent,
}) {
  const {
    mb_id,
    wr_id,
    wr_comment,
    liked,
    wr_good,
    wr_deleted,
    regDatetime,
    mb_profile,
    subReply,
  } = dataObj;
  const targetMbNo = dataObj.mb_no;
  const { mb_no, resData, postData } = useCheckToken();
  const [CommentInCommentList, setCommentInCommentList] = useState();
  const [isLiked, setIsLiked] = useState(liked);
  const [likeTotal, setLikeTotal] = useState(wr_good);
  const [isDelete, setIsDelete] = useState(wr_deleted);
  const [dotMenu, setDotMenu] = useState(false);

  const commentLike = async () => {
    await postData("reply/like", { mb_no, wr_id, status: isLiked ? 0 : 1 });
    setIsLiked(!isLiked);
    setLikeTotal(isLiked ? likeTotal - 1 : likeTotal + 1);
    // term.current = true;
  };

  const deleteComment = async () => {
    await postData("reply/del", { mb_no, wr_id });
    setDotMenu(false);
    setIsDelete(1);
  };

  const moreViewCommnetInComment = async () => {
    const res = await postData("reply/more", {
      type: "sub",
      mb_no,
      wr_id: wr_parent,
      wr_parent: wr_id,
    });
    // // console.log(res);
    setCommentInCommentList(res.data.replyContents);
  };

  useEffect(() => {
    if (CommentInCommentList) moreViewCommnetInComment();
  }, [subReply]);

  return (
    <>
      <div className="reply d-flex">
        {isDelete === 1 ? (
          <p className="delete_msg">삭제된 댓글입니다.</p>
        ) : (
          <>
            <Profile width="30" height="30" url={mb_profile} />
            <div className="text_box">
              <p className="text">
                <span className="user_id">
                  {mb_id}
                  {publisher === targetMbNo && (
                    <span className="badge">작성자</span>
                  )}
                </span>
                {wr_comment}
              </p>
              <div className="bottom_text c_darkGray">
                <span className="date">
                  {regDatetime.split(" ")[0].replace(/-/g, ".")}
                </span>
                {/* <span className="date">{regDatetime}</span> */}
                <span
                  className={"like " + (isLiked ? "active" : "")}
                  onClick={commentLike}
                >
                  좋아요 {likeTotal}
                </span>
                <label
                  htmlFor="commentInput"
                  className="btn_reply"
                  onClick={() => {
                    setReplyTarget({ target_id: mb_id, wr_parent: wr_id });
                  }}
                >
                  답글입력
                </label>
                {Number(mb_no) === targetMbNo && (
                  <button
                    className="btn_reply_delete"
                    onClick={() => {
                      setDotMenu(true);
                    }}
                  >
                    삭제
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {/* 만약 대댓글 데이터가 있다면 우선 1개만 보여주기 */}
      {subReply && subReply[0] && !CommentInCommentList && (
        <CommentInComment
          publisher={publisher}
          commentData={subReply[0]}
          moreView={moreViewCommnetInComment}
        />
      )}
      {/* 답글 더보기를 누르면 모든 대댓글 데이터 불러와서 반복문으로 생성 */}
      {CommentInCommentList &&
        CommentInCommentList.map((el, idx) => {
          return (
            <CommentInComment
              key={idx}
              publisher={publisher}
              commentData={el}
              hideMoreBtn={true}
            />
          );
        })}
      {dotMenu && (
        <div className="dot_menu">
          <div className="dim" onClick={() => setDotMenu(false)}></div>
          <button className="btn btn_report c_red" onClick={deleteComment}>
            삭제하기
          </button>
          <button className="btn btn_cancel" onClick={() => setDotMenu(false)}>
            취소
          </button>
        </div>
      )}
    </>
  );
}

function CommentInComment({
  publisher,
  commentData,
  moreView,
  hideMoreBtn,
  length,
}) {
  const {
    wr_id,
    wr_parent,
    wr_comment,
    liked,
    wr_good,
    mb_id,
    wr_deleted,
    regDatetime,
    mb_profile,
  } = commentData;
  const targetMbNo = commentData.mb_no;
  const { mb_no, postData } = useCheckToken();
  const [isLiked, setIsLiked] = useState(liked);
  const [likeTotal, setLikeTotal] = useState(wr_good);
  const [isDelete, setIsDelete] = useState(wr_deleted);
  const [dotMenu, setDotMenu] = useState(false);

  const commentLike = async () => {
    await postData("reply/like", { mb_no, wr_id, status: isLiked ? 0 : 1 });
    setIsLiked(!isLiked);
    setLikeTotal(isLiked ? likeTotal - 1 : likeTotal + 1);
  };

  // console.log(targetMbNo);

  const deleteComment = async () => {
    await postData("reply/del", { mb_no, wr_id });
    setDotMenu(false);
    setIsDelete(1);
  };

  return (
    <div className="r_reply_wrap">
      <div className="r_reply d-flex">
        {isDelete === 1 ? (
          <p className="delete_msg">삭제된 댓글입니다.</p>
        ) : (
          <>
            <Profile width="30" height="30" url={mb_profile} />
            <div className="text_box">
              <p className="text">
                <span className="user_id">
                  {mb_id}
                  {publisher === targetMbNo && (
                    <span className="badge">작성자</span>
                  )}
                </span>
                {wr_comment}
              </p>
              <div className="bottom_text c_darkGray">
                <span className="date">
                  {regDatetime.split(" ")[0].replace(/-/g, ".")}
                </span>
                <span
                  className={"like " + (isLiked ? "active" : "")}
                  onClick={commentLike}
                >
                  좋아요 {likeTotal}
                </span>
                {Number(mb_no) === targetMbNo && (
                  <button
                    className="btn_reply_delete"
                    onClick={() => {
                      setDotMenu(true);
                    }}
                  >
                    삭제하기
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {!hideMoreBtn && (
        <button className="btn_more_reply c_darkGray" onClick={moreView}>
          답글 더보기
        </button>
      )}
      {dotMenu && (
        <div className="dot_menu">
          <div className="dim" onClick={() => setDotMenu(false)}></div>
          <button className="btn btn_report c_red" onClick={deleteComment}>
            삭제
          </button>
          <button className="btn btn_cancel" onClick={() => setDotMenu(false)}>
            취소
          </button>
        </div>
      )}
    </div>
  );
}
