export default function TrafficType02(props) {
  const { handleInput, dataForm, oilType } = props;
  const selectCheckSub = (el, type) => {
    if (dataForm[type] === el) 
    {
      return true;
    }
    else 
    {
      return false;
    }
  };
  
  return (
    <div className="calc_traffic">
      <div className="traffic_wrap step_box step02">
        <h5 className="question">유종을 선택해주세요.</h5>
        <ul className="category_list d-flex select_radio">
          {oilType.map((el, idx) => {
            return (
              <li key={idx}>
                <label htmlFor={el[1]} className="calc_radio">
                  <input
                    type="radio"
                    id={el[1]}
                    name="traffic_oil"
                    data-value={el[0]}
                    data-type="유종"
                    onClick={handleInput}
                    checked={selectCheckSub(el[0], "유종")}
                    readOnly
                  />
                  <span>{el[0]}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
