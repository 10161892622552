import axios from "axios";
import { useEssentialInfo } from "../../hooks/bundle_hooks";
import { useRecoilState } from "recoil";
import { findIDAtom } from "../../recoil/atoms";
import { serverUrl } from "../../variables/serverUrl";
import { useNavigate } from "react-router-dom";

export default function PwResetting() {
  const { form, valid, validPass, errorCheck } = useEssentialInfo([
    "mb_password",
    "mb_password_cfm",
  ]);
  const [findID, setFindID] = useRecoilState(findIDAtom);
  const navigate = useNavigate();

  const dataSubmit = async () => {
    const res = await axios.post(`${serverUrl}auth/reset`, {
      mb_password: form.mb_password.val,
      mb_password_cfm: form.mb_password_cfm.val,
      mb_id: findID.mb_id,
      mb_name: findID.mb_name,
      mb_birth: findID.mb_birth,
      mb_hp: findID.mb_hp,
    });

    if (res.data.code === 200) {
      alert("비밀번호 변경이 완료되었습니다. 로그인을 해주세요.");
      navigate("/Login");
    } else alert("오류가 발생했습니다.");
  };

  return (
    <div id="pwReseting" className="join inner find">
      <div className="form_wrap">
        <form action="">
          <div className={"input_ty01 " + errorCheck("mb_password")?.state}>
            <label htmlFor="">
              비밀번호
              <input
                type="password"
                placeholder="영문,숫자, 특수문자 조합 8자리 이상"
                data-type="mb_password"
                value={form.mb_password.val}
                onChange={valid}
              />
            </label>
            {errorCheck("mb_password")?.alert}
          </div>
          <div className={"input_ty01 " + errorCheck("mb_password_cfm")?.state}>
            <label htmlFor="">
              비밀번호 재확인
              <input
                type="password"
                placeholder="비밀번호 재입력"
                data-type="mb_password_cfm"
                value={form.mb_password_cfm.val}
                onChange={valid}
              />
            </label>
            {errorCheck("mb_password_cfm")?.alert}
          </div>
        </form>
      </div>
      <button
        className="btn_ty01 bBtn"
        onClick={dataSubmit}
        disabled={!validPass()}
      >
        변경하기
      </button>
    </div>
  );
}
