import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { calcDataAtom, calcPageCountAtom } from "../recoil/atoms";
import { cloneDeep } from "../utils/bundle_utils";
import axios from "axios";

export function useCalcFlow(cat) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [calcData, setCalcData] = useRecoilState(calcDataAtom);
  const [calcPageCount, setCalcPageCount] = useRecoilState(calcPageCountAtom);
  const [dataForm, setDataForm] = useState({});
  const [currCat, setCurrCat] = useState([]);
  const initial = require("../assets/json/initialCalcData.json");

  const searchCat = (arr, isNextStep) => {
    //JSON 중첩구조 탐색 함수
    if (isNextStep) return;
    let result = true;
    for (let el of arr) {
      if (!el.select || el.done) continue;
      if (!el.category || el.category.every((_el) => !_el.select)) {
        result = false;
        navigate("/Calculator/" + el.url[0]);
        break;
      }
      result = checkCat(el);
      el.done = result;
      if (!result) break;
    }
    return result;
  };

  const checkCat = (el) => {
    //하위 카테고리 여부 및 하위 카테고리들 done 상태 확인 함수
    const selected = el.category.filter((_el) => _el.select);
    if (selected.every((_el) => _el.done)) return true;
    else return searchCat(el.category);
  };

  const createDataForm = (arr) => {
    //데이터 양식 생성 함수
    if (arr.url.includes(id)) return setDataForm({ ...arr.data });
    for (let el of arr.category) {
      if (el.url.includes(id)) {
        setDataForm({ ...el.data });
        break;
      } else if (el.category) createDataForm(el, id);
    }
  };

  const findCat = (arr) => {
    //입력한 데이터 등록 함수
    let result;
    if (arr.url.includes(id)) return dataEntryt(arr);
    for (let el of arr.category) {
      if (el.url.includes(id)) return dataEntryt(el);
      else if (el.category) result = findCat(el, id);
    }
    return result;
  };

  const dataEntryt = (target) => {
    //데이터 등록 및 done값 변경 함수
    target.data = { ...dataForm };
    if (target.url.includes(id)) {
      const urlIndex = target.url.indexOf(id);
      if (target.url[urlIndex + 1]) {
        navigate("/Calculator/" + target.url[urlIndex + 1]);
        return true;
      } else {
        target.done = true;
        return false;
      }
    }
  };

  const depthCheck = (arr, url) => {
    //하위 카테고리 여부 확인 함수
    if (!arr.category) return arr;
    let result = arr;
    for (let el of arr.category) {
      if (el.category) result = depthCheck(el, url);
      if (el.url.includes(url)) {
        result = el;
        break;
      }
    }
    return result;
  };

  const handleInput = (e) => {
    let value = e.target.dataset.value || e.target.value;

    if (!e.target.dataset.value) {
      if (value.length > 6) {
        return false;
      }
      let regex = /[^0-9.]/g;
      value = value.replace(regex, "");
    }

    switch (e.target.dataset.type) {
      case "출발지역":
        return setDataForm({ ...dataForm, 출발지역: value, 도착지역: "" });
      default:
        setDataForm({ ...dataForm, [e.target.dataset.type]: value });
    }
  };

  const doneFalse = (arr) => {
    let result = false;
    for (let el of arr) {
      if (el.url.includes(id)) {
        el.done = false;
        return true;
      } else if (el.category) {
        result = doneFalse(el.category);
        if (result) {
          el.done = false;
          return true;
        }
      }
    }
    return result;
  };

  const getCurrCat = (arr) => {
    let result = [];
    if (arr.url.includes(id)) return [arr.title];
    for (let el of arr.category) {
      if (el.url.includes(id)) result = [arr.title, el.title];
      else if (el.category) result = [...result, arr.title, ...getCurrCat(el)];
    }
    return result;
  };

  const nextStep = (e, url) => {
    let copy = cloneDeep(calcData);
    let isNextStep = false;
    setCalcPageCount(calcPageCount + 1);
    if (!cat) return searchCat(copy);
    const find = copy.find((el) => el.title === cat);
    const isDepth = depthCheck(find, id);
    if (isDepth && !isDepth.category) isNextStep = findCat(find);
    const result = searchCat(copy, isNextStep);
    setCalcData(copy);
    if (result) {
      navigate("/Calculator/Result");
    }
  };

  // const backEvent = () => {
  //   window.onpopstate = function (e) {
  //     if (e) {
  //       // console.log("뒤로감");
  //       navigate(-(calcPageCount - 1));
  //       window.onpopstate = null;
  //       setCalcData(initial);
  //     }
  //   };
  // };

  useEffect(() => {
    setCalcData(initial);
  }, []);

  useEffect(() => {
    // if (id === "Result") return backEvent();
    if (!cat) return;
    let copy = cloneDeep(calcData);
    const find = copy.find((el) => el.title === cat);
    createDataForm(find);
    doneFalse(copy);
    // // console.log(getCurrCat(find));
    setCurrCat(getCurrCat(find));
    setCalcData(copy);
  }, [id]);

  useEffect(() => {
    // if (JSON.stringify(json) === JSON.stringify(calcData)) navigate("/Calculator/Start");
  }, [calcData]);

  return {
    currCat,
    dataForm,
    initial,
    setDataForm,
    nextStep,
    handleInput,
  };
}
