import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCheckToken } from "../../hooks/useCheckToken";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { serverUrl } from "../../variables/serverUrl";

export default function ChallengePost({
  dataArr,
  date,
  target,
  setDotMenu,
  setSaveScrollTop,
}) {
  const {
    wr_id,
    challenge_no,
    wr_comment,
    wr_content,
    wr_good,
    mb_id,
    liked,
    file,
  } = dataArr;
  const publisher = dataArr.mb_no;
  const navigate = useNavigate();
  const { isLogin, mb_no, postData } = useCheckToken(true);
  const [moreView, setMoreView] = useState(false);
  const [isLiked, setIsLiked] = useState(liked);
  const [likeTotal, setLikeTotal] = useState(wr_good);
  const term = useRef(true);
  const textLength = 15;
  const subStringText = (str) => {
    return str.length > textLength && !moreView
      ? str.substr(0, textLength) + "..."
      : str;
  };

  const like = async () => {
    if (!isLogin) return alert("로그인 후 이용 가능합니다.");
    if (!term.current) return;
    term.current = false;
    // console.log({ mb_no, wr_id, challenge_no, status: isLiked ? "0" : "1" });
    await postData("challenge/like", {
      mb_no,
      wr_id,
      challenge_no,
      status: isLiked ? 0 : 1,
    });
    setIsLiked(!isLiked);
    setLikeTotal(isLiked ? likeTotal - 1 : likeTotal + 1);
    term.current = true;
  };

  const MoveChallengeDetail = () => {
    setSaveScrollTop(document.documentElement.scrollTop);
    navigate("Detail", { state: { mb_no, wr_id } });
  };

  const imgSlide = {
    modules: [Pagination],
    slidesPerView: 1,
  };

  useEffect(() => {
    setIsLiked(liked);
    setLikeTotal(wr_good);
  }, [dataArr]);

  return (
    <div className="challenge_item" data-id={wr_id}>
      <div className="item_img_box">
        <div className="item_img">
          <Swiper
            className="thumbnail_slide basic_slide"
            {...imgSlide}
            pagination={true}
          >
            {file.map((el, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <img src={serverUrl + "images" + el} alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="item_icon">
          <button
            className={"thumb_icon like " + (isLiked ? "on" : "")}
            onClick={like}
          ></button>
          <span
            className="thumb_icon comment"
            onClick={MoveChallengeDetail}
          ></span>
          {isLogin && (
            <button
              className="thumb_icon dot"
              onClick={() => {
                setDotMenu(true);
                target.current = { wr_id, mb_no: publisher };
              }}
            ></button>
          )}
        </div>
      </div>
      <div className="item_text_box">
        <span className="like_length">좋아요 {likeTotal}개</span>
        <div className="item_text">
          <span className="user_id">{mb_id}</span>
          {subStringText(wr_content)}
          <button
            className={
              wr_content.length > textLength && !moreView
                ? "btn_more"
                : "btn_more none"
            }
            onClick={() => setMoreView(true)}
          >
            더보기
          </button>
        </div>
        {wr_comment > 0 && (
          <span className="reply_text c_darkGray" onClick={MoveChallengeDetail}>
            댓글 {wr_comment}개 모두 보기
          </span>
        )}
        <span className="date c_darkGray">{date}</span>
      </div>
    </div>
  );
}
