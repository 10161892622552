import { useEffect, useState } from "react";
import { useCheckToken } from "../../hooks/bundle_hooks";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useBackEvent } from "../../hooks/bundle_hooks";
// img
import imgBack from "../../assets/img/icon/arrow_left.svg";
import imgMenu from "../../assets/img/icon/menu.svg";
import logo from "../../assets/img/common/textLogo.svg";

export default function Header(props) {
  const { mb_no, isLogin, resData, postData } = useCheckToken(true);
  const { sideToggle } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { addHeaderBackEvent } = useBackEvent();

  const exceptPage = [
    "/Splash",
    "/Intro",
    "/JoinComplete",
    "/WithdrawalComplete",
  ];

  const themeColor = () => {
    switch (pathname) {
      case "/":
      case "/ServicePage": {
        return { bg: "gray", con: "black" };
      }
      case "/DeviceQR": {
        return { bg: "black", con: "white" };
      }
      default: {
        return { bg: "white", con: "black" };
      }
    }
  };

  const headerTitle = () => {
    switch (pathname) {
      case "/JoinAgree":
      case "/JoinEssential":
      case "/JoinSelect": {
        return "회원가입";
      }
      case "/FindID":
      case "/FindIDComplete": {
        return "아이디 찾기";
      }
      case "/FindPw": {
        return "비밀번호 찾기";
      }
      case "/PwResetting": {
        return "비밀번호 재설정";
      }
      case "/MyDevice":
      case "/DeviceDetail":
      case "/DeviceRecent": {
        return "기기관리";
      }
      case "/DeviceAdd": {
        return "기기등록하기";
      }
      case "/DeviceFind":
      case "/DeviceConnect":
      case "/DeviceConnect02":
      case "/DeviceConnect03": {
        return "연결 가능한 기기 찾기";
      }
      case "/RecommendProduct": {
        return "GL 추천 제품";
      }
      case "/Mypage":
      case "/MypageEssential":
      case "/MypageSelect": {
        return "개인정보 수정";
      }
      case "/Withdrawal": {
        return "회원탈퇴";
      }
      case "/ChallengeMain":
      case "/ChallengeMain/Detail": {
        return "데일리 발자국 챌린지";
      }
      case "/ChallengeWrite": {
        return "챌린지 글쓰기";
      }
      case "/MyActive/challenge":
      case "/MyActive/save":
      case "/MyActive/calc":
      case "/MyActive/ranking": {
        return "MY 활동 내역";
      }
      case "/Ranking": {
        return "탄소 중립 실천 랭킹";
      }
      case "/EventNews":
      case "/EventNewsDetail": {
        return "EVENT / NEWS";
      }
      case "/TipReference":
      case "/TipReferenceDetail": {
        return "탄소중립 TIP 자료실";
      }
      case "/FAQ": {
        return "FAQ";
      }
      case "/Qna/qnaHis":
      case "/Qna/inquiry": {
        return "1:1문의";
      }
      default: {
        if (pathname.includes("Calculator")) {
          return "탄소발자국 계산기";
        }
        return "";
      }
    }
  };

  useEffect(() => {
    if (mb_no !== undefined) {
      postData("dashboard/index", { mb_no });
    }
  }, [pathname]);

  const backBtnHide = () => {
    // const exceptPage = ["/Calculator", "/MyDevice", "/ChallengeMain", "/MyActive", "/Qna"];
    const exceptPage = ["/Calculator", "/MyDevice", "/MyActive", "/Qna"];
    return !(
      exceptPage.some((el) => pathname.includes(el)) ||
      pathname === "/ChallengeMain"
    );
  };

  return (
    !exceptPage.includes(pathname) && (
      <>
        {
          /* eslint-disable-line no-restricted-globals */ self !== top ? (
            ""
          ) : (
            <header id="header" className="gray">
              <div className="header_sub">
                <button className="btn_menu" onClick={sideToggle}>
                  <img src={imgMenu} alt="메뉴바 아이콘" />
                </button>
                <h1 className="logo">
                  <Link to="/">
                    <img src={logo} alt="GREEN TALK" />
                  </Link>
                </h1>
              </div>
              {isLogin && (
                <Link to="/MyActive/point" className="top_point">
                  {Number(resData?.memInfo.mb_point)?.toLocaleString("ko-KR") ===
                  "NaN"
                    ? 0
                    : Number(resData?.memInfo.mb_point)?.toLocaleString(
                        "ko-KR"
                      )}{" "}
                  P
                </Link>
              )}
            </header>
          )
        }

        {/* <header id="header" className={themeColor()["bg"]}>
            {backBtnHide() && (
              <button
                className={"btn_back " + themeColor()["con"]}
                onClick={addHeaderBackEvent}
              >
                <img src={imgBack} alt="뒤로가기 아이콘" />
              </button>
            )}
            <h2 className="header_tit">{headerTitle()}</h2>
            {pathname === "/DeviceQR" && (
              <button className="btn_cancel" onClick={() => navigate(-1)}>
                &times;
              </button>
            )}
          </header> */}
      </>
    )
  );
}
