import { Swiper, SwiperSlide } from "swiper/react";

export default function CalculatorTab(props) {
  const { catName } = props;
  const slideTab = {
    slidesPerView: "auto",
    slidesOffsetBefore: 18,
    slidesOffsetAfter: 18,
    spaceBetween: 8,
  };

  return (
    <div className="calculator_tab">
{/*       <Swiper className="calc_tab_slide" {...slideTab}>
        {["교통", "여행", "요리", "전기", "가스", "수도", "쓰레기배출"].map((el, idx) => {
          return (
            <SwiperSlide key={idx}>
              <button type="button" className={"btn_link btn_ty01 " + (el === catName() ? "active" : "")}>
                {el}
              </button>
            </SwiperSlide>
          );
        })}
      </Swiper> */}
    </div>
  );
}
