import { useEffect, useState } from "react";
import Spiner from "../../components/SpinerAni";
import { useCheckToken } from "../../hooks/bundle_hooks";
import noHistory from "../../assets/img/no_history.png";

export default function ActiveCalc() {
  const { mb_no, postData, errorImage } = useCheckToken();
  const [searchDate, setSearchDate] = useState(new Date().getFullYear());
  const [offset, setOffset] = useState(0);
  const [history, setHistory] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [viewMoreBtn, setViewMoreBtn] = useState(true);
  const [isLoadData, setIsLoadData] = useState(false);
  const [memberName, setMemberName] = useState();

  const loadData = async () => {
    const res = await postData("calculator/history", {
      mb_no,
      offset: 0,
      date: searchDate + "-01-01",
    });
    const mbRes = await postData("dashboard/index", {
      mb_no,
    });
    setMemberName(mbRes.data);
    if (res.data) {
      setHistory(res.data);
      setTotalCount(res.data[0].total_cnt);
      setOffset(10);
      if (res.data[0].total_cnt == res.data.length) {
        setViewMoreBtn(false);
      } else {
        setViewMoreBtn(true);
      }
    } else {
      setHistory([]);
    }
    setIsLoadData(true);
  };

  const moreData = async () => {
    // setIsLoadData(false);
    const res = await postData("calculator/history", {
      mb_no,
      offset,
      date: searchDate + "-01-01",
    });
    setHistory([...history, ...res.data]);
    setOffset(offset + 10);
    if ([...history, ...res.data].length >= totalCount) setViewMoreBtn(false);
  };

  useEffect(() => {
    loadData();
  }, [searchDate]);

  return (
    <div className="content_wrap">
      <div className="my_calc">
        <div className="year_area">
          <div className="inner d-flex flex-ac flex-js">
            <button
              className="btn btn_left"
              onClick={() => {
                if (searchDate <= 2022) return;
                setSearchDate(searchDate - 1);
              }}
            ></button>
            <p className="year">{searchDate}년</p>
            <button
              className="btn btn_right"
              onClick={() => {
                if (searchDate >= new Date().getFullYear()) return;
                setSearchDate(searchDate + 1);
              }}
            ></button>
          </div>
        </div>
        <div className="calc_area">
          {history.map((el, idx) => {
            return (
              <CalcItem
                key={idx}
                data={el.carbon}
                date={el.create_at}
                name={memberName?.memInfo.mb_name}
              />
            );
          })}
        </div>
      </div>

      {viewMoreBtn && history[0] && (
        <div className="btn_wrap view_more">
          <button className="btn box_ty01" onClick={moreData}>
            + 더보기
          </button>
        </div>
      )}

      {!isLoadData && <Spiner />}
      {isLoadData && !history[0] && (
        <div className="no_data_wrap">
          <img src={errorImage ? errorImage : noHistory}></img>
        </div>
      )}
    </div>
  );
}

function CalcItem({ data, date, name }) {
  const [detail, setDetail] = useState(false);
  const day = new Date(date.split(" ")[0]).getDay();
  const convDay = {
    0: "일",
    1: "월",
    2: "화",
    3: "수",
    4: "목",
    5: "금",
    6: "토",
  }[day];
  const [d, m] = [date.split("-")[1], date.split("-")[2].split(" ")[0]];

  const resultSum = (type) => {
    if (!data[type]) return;
    const target = data[type];
    let result = 0;
    if (typeof Object.values(target)[0] !== "object") {
      result += target["계산"];
    } else {
      for (let prop in target) {
        if (prop === "계산") continue;
        result += target[prop]["계산"];
      }
    }
    return result?.toLocaleString("ko-KR") || 0;
  };

  return (
    <div className={"calc " + (detail ? "open" : "")}>
      <div
        className="inner"
        onClick={() => {
          setDetail(!detail);
        }}
      >
        <div className="calc_head d-flex flex-ac flex-js">
          <h4 className="date">
            {d}월 {m}일 ({convDay})
          </h4>
          <button
            className="btn_detail"
            onClick={() => {
              setDetail(!detail);
            }}
          ></button>
        </div>
        <p className="calc_gray_box">
          총 CO2 배출량 : {data.총계산?.toLocaleString("ko-KR") || 0}Kg
        </p>
        <div className="calc_body">
          <ul className="list">
            {data &&
              Object.keys(data).map((el, idx) => {
                if (["총계산", "필요나무"].includes(el)) return;
                const target = Object.values(data[el]);
                const calcResult = target.pop();
                const stringData = target.join(" / ");
                return (
                  <li key={idx}>
                    <p className="category_total d-flex flex-ac flex-js">
                      <em className="left">{el}</em>
                      <span className="c_green right">{resultSum(el)}</span>
                    </p>

                    {typeof Object.values(data[el])[0] === "object" &&
                      Object.keys(data[el]).map((el02, idx) => {
                        if (el02 === "계산") return;
                        const target = Object.values(data[el][el02]);
                        const calcResult = target.pop();
                        const stringData = el02.includes("항공")
                          ? el02 +
                            " / " +
                            target.shift() +
                            " / " +
                            target.join("-")
                          : el02.split("(")[0] + " / " + target.join(" / ");
                        return (
                          <p key={idx} className="d-flex flex-ac flex-js">
                            <span className="left">{stringData} </span>
                            <span className="right">
                              {calcResult?.toLocaleString("ko-KR") || 0}
                            </span>
                          </p>
                        );
                      })}

                    {typeof Object.values(data[el])[0] !== "object" && (
                      <p key={idx} className="d-flex flex-ac flex-js">
                        <span className="left">{stringData} </span>
                        <span className="right">
                          {calcResult?.toLocaleString("ko-KR") || 0}
                        </span>
                      </p>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="calc_foot">
          <p>
            <strong>{name}</strong>님의
            <br />
            이산화탄소 배출량은{" "}
            <span className="c_green">
              총 {data.총계산?.toLocaleString("ko-KR") || 0}Kg
            </span>{" "}
            입니다. 이만큼의 이산화탄소를 없애기 위해서는{" "}
            <span className="c_green">
              약 {data.필요나무?.toLocaleString("ko-KR") || 0}
            </span>{" "}
            그루의 소나무를 심어야 합니다.
          </p>
        </div>
      </div>
    </div>
  );
}
