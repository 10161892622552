import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCheckToken } from "../../hooks/bundle_hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css/pagination";
import Profile from "../../components/Profile";
import { serverUrl } from "../../variables/bundle_variables";
import dummyTop from "../../assets/img/main_top_dummy.png";
import dummyMid from "../../assets/img/main_mid_dummy.png";
import dummyBot from "../../assets/img/main_bottom_dummy.png";
import gps from "../../assets/img/icon/gps.svg";
import trophy from "../../assets/img/icon/trophy.svg";

export default function Main() {
  // const day = new Date().toDateString().split(" ")[0];
  const navigate = useNavigate();
  const day = ["일", "월", "화", "수", "목", "금", "토"][new Date().getDay()];
  const date = new Date().toLocaleDateString().replace(/(\s*)/g, "");
  const weatherLoading = useRef(true);
  // const { mb_no, isLogin, resData, postData } = useCheckToken("dashboard/index?mb_no=", "메인요청");
  const { mb_no, isLogin, resData, postData } = useCheckToken();
  const [userList, setUserList] = useState();
  const [weatherData, setWeatherData] = useState({
    state: false,
    date: date + day,
    addr: "",
    T1H: 0,
    TMX: 0,
    TMN: 0,
    SKY: "",
    PTY: "",
    pm10Value: 0,
    pm10Grade1h: "",
    pm25Value: 0,
    pm25Grade1h: "",
    icon: "",
  });
  const [bannerResult, setBannerResult] = useState([]);
  const [isGpsLoading, setIsGpsLoading] = useState(false);
  let m_lat; //APP에서 받아온 위치정보
  let m_lng; //APP에서 받아온 위치정보

  const loadWeatherData = () => {
    setIsGpsLoading(true);
    weatherLoading.current = false;
    if (window.flutter_inappwebview) {
      //플러터 APP에서의 로직
      if(!m_lat || !m_lng)
      {
        loadWeatherData_Detail(m_lat, m_lng);
      }
      else
      {
        Req_InApp_Location();
        console.log("위치 정보가 유효하지 않습니다.");
      }
    }
    else {
      //기존 웹에서의 로직
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          loadWeatherData_Detail(lat, lng);
        },
        () => {
          /* alert("Can't find you. No weather for you."); */
        }
      );
    }
  };

  const loadWeatherData_Detail = async (lat, lng) => {
    const token = await axios.get(
      `https://sgisapi.kostat.go.kr/OpenAPI3/auth/authentication.json?consumer_key=6f6d2d9ac7a44c3dacc6&consumer_secret=aad102fb10ea4f70886a`
    );
    const accessToken = token.data.result.accessToken;
    const changeCoor =
      await axios.get(`https://sgisapi.kostat.go.kr/OpenAPI3/transformation/transcoord.json?src=4326&dst=5179&posX=${lng}&posY=${lat}&accessToken=${accessToken}
    `);
    const utmX = changeCoor.data.result.posX;
    const utmY = changeCoor.data.result.posY;
    const reverseGeo = await axios.get(
      `https://sgisapi.kostat.go.kr/OpenAPI3/addr/rgeocode.json?x_coor=${utmX}&y_coor=${utmY}&addr_type=20&accessToken=${accessToken}`
    );
    const addr = reverseGeo.data.result[0].full_addr;
    //여기까지 시, 구, 동 정보
    // const weatherAPIRes = await axios.get(serverUrl + `weather/${addr.split(" ")[0]}`);

    try {
      const weatherAPIRes = await axios.get(
        `${serverUrl}weather/${addr.split(" ")[0]}`
      );
      // console.log(weatherAPIRes.data.data);
      let { TMN, TMX } = weatherAPIRes.data.data.daily_temper;
      let { pm10Value, pm25Value, pm10Grade1h, pm25Grade1h } =
        weatherAPIRes.data.data.dust;
      let { SKY } = weatherAPIRes.data.data.daily_weather;
      let { PTY } = weatherAPIRes.data.data.now_temper;
      let { T1H } = weatherAPIRes.data.data.now_temper;
      pm10Grade1h = { 1: "좋음", 2: "보통", 3: "나쁨", 4: "매우나쁨" }[
        pm10Grade1h
      ];
      pm25Grade1h = { 1: "좋음", 2: "보통", 3: "나쁨", 4: "매우나쁨" }[
        pm25Grade1h
      ];
      SKY = { 1: "sunny", 3: "cloud", 4: "blur" }[SKY];
      SKY = !SKY ? "sunny" : SKY;
      PTY = {
        0: "none",
        1: "rain",
        2: "rain_snow",
        3: "snow",
        5: "raindrop",
        6: "raindrop_snowdrift",
        7: "snowdrift",
      }[PTY];
      let icon = SKY + "_" + PTY + ".png";
      //여기까지 날씨정보
      setWeatherData({
        ...weatherData,
        state: true,
        addr,
        T1H,
        TMX,
        TMN,
        SKY,
        PTY,
        pm10Value,
        pm10Grade1h,
        pm25Value,
        pm25Grade1h,
        icon,
      });
      weatherLoading.current = true;
      setIsGpsLoading(false);
    } catch (err) {
      // console.log(err);
    }
    // const weatherAPIRes = await axios.get(`${serverUrl}weather2/${addr.split(" ")[0]}`);
    // // console.log(weatherAPIRes.data.data);
    // let { TMN, TMX } = weatherAPIRes.data.data.daily_temper;
    // let { pm10Value, pm25Value, pm10Grade1h, pm25Grade1h } = weatherAPIRes.data.data.dust;
    // let { SKY } = weatherAPIRes.data.data.daily_weather;
    // let { PTY } = weatherAPIRes.data.data.now_temper;
    // let { T1H } = weatherAPIRes.data.data.now_temper;
    // pm10Grade1h = { 1: "좋음", 2: "보통", 3: "나쁨", 4: "매우나쁨" }[pm10Grade1h];
    // pm25Grade1h = { 1: "좋음", 2: "보통", 3: "나쁨", 4: "매우나쁨" }[pm25Grade1h];
    // SKY = { 1: "sunny", 3: "cloud", 4: "blur" }[SKY];
    // SKY = !SKY ? "sunny" : SKY;
    // PTY = { 0: "none", 1: "rain", 2: "rain_snow", 3: "snow", 5: "raindrop", 6: "raindrop_snowdrift", 7: "snowdrift" }[PTY];
    // let icon = SKY + "_" + PTY + ".png";
    // //여기까지 날씨정보
    // setWeatherData({
    //   ...weatherData,
    //   state: true,
    //   addr,
    //   T1H,
    //   TMX,
    //   TMN,
    //   SKY,
    //   PTY,
    //   pm10Value,
    //   pm10Grade1h,
    //   pm25Value,
    //   pm25Grade1h,
    //   icon,
    // });
    // weatherLoading.current = true;
    // // console.log("날씨데이터 불러오기 완료");
  };

  //플러터로 위치정보 요청
  const Req_InApp_Location = async () => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('InAPP_REQ_Location', '')
        .then(function (response) {
          const { success, lat, lng } = response;
          if (success) {
            console.log('Location successful, lat:', lat + ', lng:' + lng);
            m_lat = lat;
            m_lng = lng;
          }
        });
    }
  };

  const loadUserList = async () => {
    const res = await axios.get(serverUrl + "dashboard/index");
    console.log(res);
    setUserList(res.data.data.userInfoResult);
    setBannerResult(res.data.data.bannerInfo);
    // console.log(res.data.data.bannerInfo);
  };

  const refeshWeatherData = () => {
    if (!weatherLoading.current) return;
    loadWeatherData();
  };

  const mainTopSlide = {
    modules: [Autoplay, Pagination],
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      type: "fraction",
    },
  };

  const withSlide = {
    slidesPerView: "auto",
    spaceBetween: 25,
    slidesOffsetBefore: 18,
    slidesOffsetAfter: 18,
  };

  const greenTalkSlide = {
    modules: [Pagination],
    slidesPerView: 1,
    loop: true,
    pagination: {
      type: "fraction",
    },
  };

  function extractMountain(point) {
    const numString = Math.floor(point / 6600000).toString();

    return parseInt(numString.charAt(numString.length - 1));
  }

  function extractForest(point) {
    var numString = 0;
    const temp = point / 660000;

    if (temp > 1) {
      numString = Math.floor(point - (temp * 660000) / 66000).toString();
    } else {
      numString = Math.floor(point / 66000).toString();
    }

    return parseInt(numString.charAt(numString.length - 1));
  }

  function extractTree(point) {
    if (point < 6600) // 무조건 1그루는 보이게 해달라고 김실장이 요구 - 240807
    {
      point = 6600;
    }

    var numString = Math.floor(point / 6600).toString();

    return parseInt(numString.charAt(numString.length - 1));
  }

  useEffect(() => {
    loadUserList();
    Req_InApp_Location();
    loadWeatherData();
    postData("dashboard/index", { mb_no });
  }, []);

  return (
    <>
      <main id="main" className="container">
        <div className="inner">
          <div className="main_top_banner">
            {!bannerResult[0] && (
              <Swiper
                className="main_top_slide basic_slide w100"
                {...mainTopSlide}
              >
                <SwiperSlide>
                  <img src={dummyTop} alt="지속가능한 이야기를 나누는 곳" />
                </SwiperSlide>
              </Swiper>
            )}
            {bannerResult[0] && (
              <Swiper
                className="main_top_slide basic_slide w100"
                {...mainTopSlide}
              >
                {bannerResult.map((el, idx) => {
                  if (el.bannerType === "메인 상단")
                    return (
                      <SwiperSlide
                        key={idx}
                        onClick={() => {
                          navigate(el.bannerPath?.split(".kr")[1]);
                        }}
                      >
                        <img
                          src={serverUrl + "images" + el.bannerImage}
                          alt="지속가능한 이야기를 나누는 곳"
                        />
                      </SwiperSlide>
                    );
                })}
              </Swiper>
            )}
          </div>
          <section>
            <div className="weather_wrap">
              <div className="tit_wrap d-flex flex-ac">
                <h2 className="main_tit">날씨</h2>
                <span className="date">{weatherData.date}</span>
                <span className="gps" onClick={refeshWeatherData}>
                  {weatherData.addr}{" "}
                  <img
                    src={gps}
                    className={isGpsLoading ? "loading" : ""}
                    alt="gps_loading"
                  />
                </span>
              </div>
              <div className="box_ty01 d-flex flex-ac flex-js">
                <div className="weather_left d-flex flex-ac">
                  <img
                    src={
                      weatherData.icon &&
                      require(`../../assets/img/icon/${weatherData.icon}`)
                    }
                    alt=""
                  />
                  <strong>{weatherData.T1H}º</strong>
                  <span>
                    {weatherData.TMX} / {weatherData.TMN}
                  </span>
                </div>
                <div className="weather_right d-flex flex-ac">
                  <p>
                    미세{" "}
                    <span className="c_green">{weatherData.pm10Value}</span>{" "}
                    <span className="bg_green block">
                      {weatherData.pm10Grade1h}
                    </span>
                  </p>
                  <p>
                    초미세{" "}
                    <span className="c_orange">{weatherData.pm25Value}</span>{" "}
                    <span className="bg_orange block">
                      {weatherData.pm25Grade1h}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/****************** 로그인 되어있을 때만 노출******************/}
            {isLogin && (
              <div className="my_profile d-flex">
                <div className="profile_area box_ty01">
                  <div className="info_wrap">
                    <Profile
                      width="50"
                      height="50"
                      url={resData?.memInfo.mb_profile}
                    />
                    <p className="name">
                      {resData?.memInfo.mb_name}
                      <span className="user_id">{resData?.memInfo.mb_id}</span>
                    </p>
                  </div>
                  <div className="d-flex flex-ac point_wrap">
                    <Link to="/MyActive/point" className="point_icon">
                      <span className="my_point">MY ECO Point</span>
                      <strong className="c_green">
                        {Number(resData?.memInfo.mb_point)?.toLocaleString(
                          "ko-KR"
                        ) === "NaN"
                          ? 0
                          : Number(resData?.memInfo.mb_point)?.toLocaleString(
                            "ko-KR"
                          )}
                      </strong>
                    </Link>
                    <div className="food_bag_wrap d-flex">
                      <div className="food_bag"></div>
                      <p>음식물 처리 비용 </p>
                      <p>
                        {parseInt(
                          Number((resData?.memInfo.mb_acc_point / 6600) * 130)
                        )?.toLocaleString("ko-KR")}
                        원 절감 중
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-ac flex-js point_wrap">
                    {/* 포인트 수에 따라 아이콘 차등 적용 */}
                    <div className="point_icon_content">
                      <div className="point_to_icon mountain">
                        {/** 6600포인트 당 X1 적용 연산
                         * 나무 -> 숲 -> 산 단계로 적용
                         * 나무 20그루(66,000포인트) 이후 적립 시 숲 1그루로 계산
                         * 예시 적용이며 추후 바뀔 수 있음
                         * */}
                        <span>
                          {"x" +
                            extractMountain(
                              Number(resData?.memInfo.mb_acc_point)
                            )}
                        </span>
                      </div>
                      <p>산</p>
                    </div>
                    <div className="point_icon_content">
                      <div className="point_to_icon forest">
                        {/** 6600포인트 당 X1 적용 연산
                         * 나무 -> 숲 -> 산 단계로 적용
                         * 나무 20그루(66,000포인트) 이후 적립 시 숲 1그루로 계산
                         * 예시 적용이며 추후 바뀔 수 있음
                         * */}
                        <span>
                          {"x" +
                            extractForest(
                              Number(resData?.memInfo.mb_acc_point)
                            )}
                        </span>
                      </div>
                      <p>숲</p>
                    </div>
                    <div className="point_icon_content">
                      <div className="point_to_icon tree">
                        {/** 6600포인트 당 X1 적용 연산
                         * 나무 -> 숲 -> 산 단계로 적용
                         * 나무 20그루(66,000포인트) 이후 적립 시 숲 1그루로 계산
                         * 예시 적용이며 추후 바뀔 수 있음
                         * */}
                        <span>
                          {"x" +
                            extractTree(Number(resData?.memInfo.mb_acc_point))}
                        </span>
                      </div>
                      <p>나무</p>
                    </div>
                  </div>
                </div>
                <div className="gray_box">
                  <Link to="/ChallengeList" className="btn_link">
                    데일리 챌린지
                  </Link>
                  <Link to="/Calculator/Start" className="btn_link">
                    탄소발자국 <br />
                    계산하기
                  </Link>
                  <Link to="/MyActive/challenge" className="btn_link">
                    MY <br />
                    활동내역
                  </Link>
                </div>
              </div>
            )}
            {isLogin && (
              <div className="rank_box d-flex">
                <div
                  className="profile_area box_ty01"
                  style={{ width: "100%", padding: "18px 22px" }}
                >
                  <Link
                    to="/Ranking/monthly"
                    className="btn_link box_ty01 langking"
                  >
                    <div
                      className="info_wrap d-flex"
                      style={{ justifyContent: "space-around" }}
                    >
                      <div
                        className="name"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${trophy})`,
                            width: "22px",
                            height: "22px",
                            display: "inline-block",
                            marginRight: "8px",
                          }}
                        ></div>
                        <span
                          className="user_id"
                          style={{ marginRight: "4px", fontSize: "13px" }}
                        >
                          이번 달 랭킹
                        </span>

                        <span className="user_id">
                          {resData?.memInfo?.monthly_ranking ? `${resData.memInfo.monthly_ranking}위` : "집계 불가"}
                        </span>
                      </div>
                      <div
                        className="name"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${trophy})`,
                            width: "22px",
                            height: "22px",
                            display: "inline-block",
                            marginRight: "8px",
                          }}
                        ></div>
                        <span
                          className="user_id"
                          style={{ marginRight: "4px", fontSize: "13px" }}
                        >
                          누적 랭킹
                        </span>
                        <span className="user_id">
                          {resData?.memInfo?.total_ranking ? `${resData.memInfo.total_ranking}위` : "집계 불가"}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}
            {/****************** //로그인 되어있을 때만 노출******************/}
            {resData && (
              <div className="quick_menu">
                <Link
                  to="/Calculator/Start"
                  className="btn_link box_ty01 calculator"
                >
                  탄소발자국 계산기
                </Link>
                <Link
                  to="/Ranking/monthly"
                  className="btn_link box_ty01 langking"
                >
                  탄소중립 실천 랭킹
                </Link>
                <Link
                  to="/ChallengeMain"
                  className="btn_link box_ty01 challenge"
                >
                  데일리 챌린지
                </Link>
                <Link
                  to="https://mall.greentalk.kr/"
                  target="_blank"
                  className="btn_link box_ty01 shop"
                >
                  그린톡 쇼핑몰
                </Link>
              </div>
            )}
            {/* <div className="quick_menu">
              <Link to="/Calculator/Start" className="btn_link box_ty01 calculator">
                탄소발자국 계산기
              </Link>
              <Link to="/Ranking" className="btn_link box_ty01 langking">
                탄소중립 실천 랭킹
              </Link>
              <Link to="/Challenge" className="btn_link box_ty01 challenge">
                데일리 발자국 챌린지
              </Link>
              <Link to="" className="btn_link box_ty01 shop">
                그린톡 쇼핑몰
              </Link>
            </div> */}
          </section>
          <section className="with_green">
            <h2 className="main_tit">
              <Link to="/ChallengeMain">보아요! GREEN 톡톡</Link>
            </h2>
            <Swiper className="with_slide w100" {...withSlide}>
              {userList &&
                userList.map((el, idx) => {
                  return (
                    <SwiperSlide key={idx}>
                      <Profile width="54" height="54" url={el.mb_profile} />
                      <p className="name">{el.mb_id}</p>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </section>
          <section className="green_talk">
            <h2 className="main_tit">함께해요! GREEN 톡톡</h2>
            {!bannerResult[0] && (
              <Swiper
                className="green_talk_slide basic_slide w100"
                {...greenTalkSlide}
              >
                <SwiperSlide>
                  <img src={dummyMid} alt="dummy" />
                </SwiperSlide>
              </Swiper>
            )}
            {bannerResult[0] && (
              <Swiper
                className="green_talk_slide basic_slide w100"
                {...greenTalkSlide}
              >
                {bannerResult.map((el, idx) => {
                  if (el.bannerType === "메인 중간")
                    return (
                      <SwiperSlide
                        key={idx}
                        onClick={() => {
                          navigate(el.bannerPath?.split(".kr")[1]);
                        }}
                      >
                        <img
                          src={serverUrl + "images" + el.bannerImage}
                          alt="3월의 이벤트!"
                        />
                      </SwiperSlide>
                    );
                })}
              </Swiper>
            )}
          </section>
          <div className="bottom_banner">
            {!bannerResult[0] && (
              // <Swiper className="main_top_slide basic_slide w100" {...mainTopSlide}>
              <SwiperSlide>
                <img src={dummyBot} alt="지속가능한 이야기를 나누는 곳" />
              </SwiperSlide>
              // </Swiper>
            )}
            {bannerResult.map((el, idx) => {
              if (el.bannerType === "메인 하단")
                return (
                  <SwiperSlide
                    key={idx}
                    onClick={() => {
                      navigate(el.bannerPath?.split(".kr")[1]);
                    }}
                  >
                    <img
                      src={serverUrl + "images" + el.bannerImage}
                      alt="3월의 이벤트!"
                    />
                  </SwiperSlide>
                );
            })}
          </div>
        </div>
      </main>
    </>
  );
}
