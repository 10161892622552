import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

// const data = [
//   { name: "Group A", value: 3000 },
//   { name: "Group B", value: 1800 },
// ];

// const COLORS = ['#3BC996', '#11BDE3'];
const COLORS = [`var(--graph-data1)`, `var(--graph-data2)`];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

// export default class Example extends PureComponent {
export default function Example({ coin, etc }) {
  let demoUrl = "https://codesandbox.io/s/pie-chart-with-customized-label-dlhhj";
  const data = [
    { name: "Group A", value: coin },
    { name: "Group B", value: etc },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          // labelLine={true}
          // label={renderCustomizedLabel}
          // outerRadius={80}
          // fill="#8884d8"
          dataKey="value"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
