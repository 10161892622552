import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useCheckToken } from "../../hooks/bundle_hooks";
import "swiper/css/pagination";
import Profile from "../../components/Profile";
import Spiner from "../../components/SpinerAni";
import noHistory from "../../assets/img/no_history.png";

export default function Ranking() {
  const { mb_no, postData, resData, errorImage, isLogin } = useCheckToken();
  const [myRanking, setMyRanking] = useState();
  const [serchDate, setSearchDate] = useState(new Date().getFullYear());
  const [isLoadData, setIsLoadData] = useState();

  // console.log(serchDate.toLocaleDateString());

  const loadData = async () => {
    const res = await postData("point/ranking/monthly", {
      mb_no,
      date: serchDate + "-01-01",
    });
    const myRes = await postData("ranking/month", { mb_no });
    setMyRanking(myRes.data.myRanking[0]);
    if (res.data !== null) {
      setIsLoadData(res.data);
    }
  };

  useEffect(() => {
    loadData();
  }, [serchDate]);

  if (!myRanking) {
    return (
      <div className="no_data_wrap">
        <img src={errorImage}></img>
      </div>
    );
  }
  return (
    <div id="ranking" className="container">
      {isLogin && (
        <div className="profile_box line d-flex flex-ac flex-js">
          <div className="pf d-flex flex-ac">
            <Profile width="70" height="70" url={myRanking?.mb_profile} />
            <span className="user_id">{myRanking?.mb_id}</span>
          </div>
          <div className="current_ranking">
            <p className="text">
              이번 달 누적 포인트 :{" "}
              {myRanking?.po_point === 0
                ? "-"
                : Number(myRanking?.po_point)?.toLocaleString("Ko-KR")}
              p
            </p>
            {/* 주소 미입력시 */}
            {/* <Link to="/MypageSelect" className="text empty_addr">주소 입력하고 <br />내 랭킹을 확인하세요</Link> */}
          </div>
        </div>
      )}

      <div className="total_ranking line">
        <div className="ranking_graph">
          <h5 className="tit">월간 랭킹</h5>
          {/* <div className="graph_box inner d-flex">
            {rankerList &&
              rankerList.map((el, idx) => {
                if (idx > 4) return false;
                return (
                  <div key={idx} className="graph">
                    <span className="user_name">{el.mb_id === myRanking?.mb_id ? "Me" : el.mb_id}</span>
                    <Profile width="25" height="25" url={el.profile} />
                    <div className="graph_bar">
                      <span className="ranking_num">{idx + 1}</span>
                    </div>
                  </div>
                );
              })}
          </div> */}
        </div>
        {/* 랭킹 최대 30위 까지 노출 */}
        <div className="content_wrap">
          <div className="my_ranking">
            <ul className="info_box c_darkGray">
              <li>랭킹은 매 달 1일 00시 초기화 됩니다.</li>
              <li>초기화 전, 마지막으로 등록된 순위를 보여드립니다.</li>
            </ul>

            {!myRanking && <Spiner />}
            {!myRanking && (
              <div className="no_data_wrap">
                <img src={errorImage ? errorImage : noHistory}></img>
              </div>
            )}

            <ul className="ranking_list inner">
              {myRanking && (
                <>
                  <li className="title_li d-flex flex-ac flex-js">
                    <span className="date">기간</span>
                    <span className="rank">순위</span>
                    <span className="point">포인트</span>
                  </li>
                  <li className="list_li d-flex flex-ac flex-js">
                    <span className="date">{myRanking?.date}</span>
                    <span className="rank">{myRanking?.ranking}</span>
                    <span className="point">
                      {Number(myRanking?.po_point)?.toLocaleString("Ko-KR")}p
                    </span>
                  </li>
                </>
              )}

              {isLoadData &&
                isLoadData.map((el, idx) => {
                  return (
                    <li key={idx} className="list_li d-flex flex-ac flex-js">
                      <span className="date">{Object.keys(el)[0]}</span>
                      <span className="rank">
                        {Object.values(el)[0]["ranking"]}
                      </span>
                      <span className="point">
                        {Number(
                          Object.values(el)[0]["po_point"]
                        )?.toLocaleString("Ko-KR")}
                        p
                      </span>
                    </li>
                  );
                })}
            </ul>

            {/* <ul className="ranking_list inner">
            <li className="title_li d-flex flex-ac flex-js">
              <span className="date">날짜</span>
              <span className="rank">순위</span>
              <span className="point">포인트</span>
            </li>
            {resData &&
              resData.map((el, idx) => {
                return (
                  <li key={idx} className="list_li d-flex flex-ac flex-js">
                    <span className="date">{Object.keys(el)[0]}</span>
                    <span className="rank">{Object.values(el)[0]["ranking"]}</span>
                    <span className="point">{Object.values(el)[0]["po_point"]}</span>
                  </li>
                );
              })}
          </ul> */}
          </div>
        </div>
      </div>

      <div className="foot_banner">
        <Link to="">
          <p>
            자연으로 되돌리는 지속가능한 <br />
            GL시스템 음식물 처리기
          </p>
        </Link>
      </div>
    </div>
  );
}
