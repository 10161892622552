import { useEffect, useState } from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { useUploadFile, useCheckToken } from "../../hooks/bundle_hooks";
import { serverUrl } from "../../variables/bundle_variables";
import bannerImg from "../../assets/img/ch_write_bn.png";
import chDummy from "../../assets/img/ch_write_dummy.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import Spiner from "../../components/SpinerAni";

export default function ChallengeWrite() {
  const navigate = useNavigate();
  const allowType = ["jpg", "jpeg", "png"];
  const { id } = useParams();
  const { fileData, letter, uploadFile, deleteFile, writing } = useUploadFile(
    allowType,
    8,
    3
  );
  const { mb_no, postData, resData } = useCheckToken();
  const [bannerData, setBannerData] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [chInfo, setChInfo] = useState();

  //챌린지명 받기
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const challengeName = queryParams.get("challengeName");



  const getByte = (str) => {
    let byte = 0;
    for (let i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) > 127) {
        byte += 2;
      } else byte++;
    }
    return byte;
  };

  const dataSubmit = async (event) => {
    event.preventDefault();

    const letterByte = getByte(letter);
    console.log(letterByte);
    if (letterByte < 31) {
      alert("챌린지 글은 16자 이상 작성해야 합니다.");
      return false;
    } else if (letterByte > 400) {
      alert("챌린지 글은 200자 이하까지만 작성할 수 있습니다.");
      return false;
    }

    if (isSubmit) {
      return;
    }
    setIsSubmit(true);

    const formData = new FormData();
    formData.append("mb_no", mb_no);
    formData.append("letter", letter);
    formData.append("challenge_no", id);
    fileData.forEach((el) => {
      formData.append("challengeimage", el.file);
    });
    // console.log(formData.getAll("letter"), formData.getAll("mb_no"), formData.getAll("challenge_no"), formData.getAll("challengeimage"));
    const res = await postData("challenge/create", formData);

    //응답 체크
    if (res != undefined) {
      if (res.code == 200) {
        alert("챌린지 등록이 완료되었습니다.");
        setIsSubmit(false);
      } else {
        /* alert("챌린지 등록에 실패하였습니다.");  */
      }
    } else {
      /* alert("챌린지 등록에 실패하였습니다.U"); */
    }

    navigate("/ChallengeMain");
  };

  const loadBannerData = async () => {
    // console.log(mb_no);
    const bn_url = window.location.href.replace(":3000", ":5050");
    console.log(bn_url);
    const res = await postData("challenge/write", { mb_no, bn_url });
    if (res.code === 200) 
    {
      console.log('res.data:', res.data);
      setBannerData(res.data);
    }

    //서브타이틀, 본문 가져오기
    const res_detail = await postData("challenge/getdetail", { mb_no, ch_no: Number(id) });
    if (res_detail.code === 200) {
      setChInfo(res_detail.data.challengeList[0]);
    }

  };

  useEffect(() => {
    loadBannerData();
  }, []);

  const challengeWriteSlide = {
    modules: [Autoplay, Pagination],
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      type: "fraction",
    },
  };

  return (

    <div id="challenge_write" className="container">
      <div className="inner">

        {(!bannerData || !bannerData.bannerInfo) && <Spiner />}  {/* 조회 결과 로딩 전 스피너 */}
        {(!bannerData) && (
          <div className="challenge_banner" style={{ display: "none" }}>
            <img src={chDummy} alt="" />
          </div>
        )}
        {(bannerData && bannerData.bannerInfo && Array.isArray(bannerData.bannerInfo) && bannerData.bannerInfo.length > 0) && (
          <Swiper
            className="main_top_slide basic_slide w100"
            {...challengeWriteSlide}
          >
            {bannerData?.bannerInfo.map((el, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <img src={serverUrl + "images" + el.bannerImage} alt="배너" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
        <div className="challenge_desc">
          <strong>
            <h4 className="ch_tit c_green">{challengeName}</h4>
            {chInfo && chInfo.ch_sub_title}
          </strong>
          <p>
            {chInfo && chInfo.ch_describe}
          </p>
        </div>
        <div className="write_area">
          <textarea
            placeholder="내용을 입력하세요."
            value={letter}
            onChange={writing}
          ></textarea>
        </div>
        <p
          style={{
            fontSize: "12px",
            color: "#939393",
            marginBottom: "8px",
          }}
        >
          ※ 챌린지 내용은 16자 이상 입력해주세요.
        </p>
        <div className="photo_area d-flex">
          <button htmlFor="" className="b_box btn_camera">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={uploadFile}
            />
            <span className="length">
              <span className="c_green">{fileData.length}</span>/3
            </span>
          </button>
          {(fileData && fileData.length > 0) && fileData.map((el, idx) => {
            return (
              <div className="b_box" key={idx}>
                <img src={el.url} alt="" />
                <button
                  className="btn_del"
                  data-url={el.url}
                  onClick={deleteFile}
                >
                  &times;
                </button>
              </div>
            );
          })}
        </div>
        <div className="btn_wrap">
          <Link to="/" className="btn_ty01 cancel bBtn half">
            취소
          </Link>
          <button
            className="btn_ty01 bBtn half right"
            disabled={!(letter && fileData && fileData.length > 0)}
            onClick={dataSubmit}
          >
            등록
          </button>
        </div>
      </div>
    </div>
  );
}
