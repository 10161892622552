import { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { calcDataAtom, calcPageCountAtom } from "../../recoil/atoms";
import CalculatorTab from "./CalculatorTab";
import CalculatorDetail from "./CalculatorDetail";
import { useCalcSelect, useCalcFlow } from "../../hooks/bundle_hooks";

export default function Calculator() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const catName = () => {
    if (id === "Start") return false;
    if (id.includes("Traffic")) return "교통";
    if (id.includes("Travel")) return "여행";
    if (id === "Cooking") return "요리";
    if (id === "Electricity") return "전기";
    if (id === "Water") return "수도";
    if (id === "Gas") return "가스";
    if (id === "Trash") return "쓰레기배출";
  };
  const { selectMainCat, selectCheck } = useCalcSelect(catName());
  const { dataForm, initial, setDataForm, nextStep, handleInput } = useCalcFlow(
    catName()
  );
  const [calcData, setCalcData] = useRecoilState(calcDataAtom);
  const [calcPageCount, setCalcPageCount] = useRecoilState(calcPageCountAtom);

  const nextCondition = () => {
    if (id === "Start") return false;
    let entry = Object.keys(dataForm);
    if (id.includes("01")) entry = ["유종"];
    else if (id.includes("02")) entry = ["이동거리", "동승인원"];
    else if (id === "Cooking") return !entry.some((el) => dataForm[el]);
    return !entry.every((el) => dataForm[el]);
  };

  const pageMarginBottom = () => {
    const visibleGNB = [
      "Start",
      "Traffic",
      "TrafficSmallCar01",
      "TrafficSmallVan01",
      "TrafficMediumCar01",
      "TrafficLargeCar01",
      "TravelSmallCar01",
      "TravelSmallVan01",
      "TravelMediumCar01",
      "TravelLargeCar01",
      "Travel",
      "TravelCar",
    ];
    if (visibleGNB.includes(id)) return "";
    else return "input";
  };

  useEffect(() => {
    setCalcData(initial);
    setCalcPageCount(0);
    navigate("/Calculator/Start");
  }, []);

  useEffect(() => {
    if (id === "Start") setCalcPageCount(0);
  }, [id]);

  // useEffect(() => {
  //   if (pathname === "/Calculator/Start") return;
  // }, [calcData]);

  return (
    <div id="calculator" className={"container calc_content"}>
      {id !== "Start" && id !== "Result" && <CalculatorTab catName={catName} />}
      {/* <h3>
        {currCat.map((el, idx) => {
          if (idx === currCat.length - 1) return el;
          return el + ">";
        })}
      </h3> */}
      <div className="inner">
        {/* 첫화면 */}
        {/* 텍스트 쓰는 구간에는 gnb bar 없애기(디자인 참고) */}
        {id === "Start" ? (
          <div className="calc_intro">
            <h5 className="question">
              일상에서 배출하는 <br />
              이산화탄소의 양은 얼마일까요?
            </h5>
            {/* <span className="multi">복수선택 가능</span> */}
            <ul className="category_list d-flex">
              {[
                "교통",
                "여행",
                "요리",
                "전기",
                "가스",
                "수도",
                "쓰레기배출",
              ].map((el, idx) => {
                return (
                  <li
                    key={idx}
                    className={selectCheck(el)}
                    data-title={el}
                    onClick={selectMainCat}
                  >
                    {el}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <CalculatorDetail
            handleInput={handleInput}
            dataForm={dataForm}
            setDataForm={setDataForm}
            initial={initial}
            id={id}
          />
        )}
        {id !== "Result" && (
          <div className="btn_wrap">
            <button
              className="btn_ty01 gray bBtn half"
              onClick={() => {
                navigate(-1);
              }}
            >
              이전
            </button>
            <button
              className="btn_ty01 bBtn half right"
              disabled={nextCondition()}
              onClick={nextStep}
            >
              다음
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
