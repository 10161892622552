import { useCalcSelect } from "../../../hooks/bundle_hooks";
export default function TravelType02() {
  const { selectSubCat, selectCheck } = useCalcSelect("여행");
  return (
    <div className="calc_travel">
      <div className="travel_wrap step_box step02 pt20">
        <h5 className="question">여행 시, 어떤 차종을 이용하셨나요?</h5>
        {/* <span className="multi">복수선택 가능</span> */}
        <ul className="category_list d-flex">
          {["소형승용차(여행)", "소형승합차(여행)", "중형승용차(여행)", "대형승용차(여행)"].map((el, idx) => {
            return (
              <li key={idx} className={selectCheck(el)} data-title={el} onClick={selectSubCat}>
                {el.split("(")[0]}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
