import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEssentialInfo, useCheckToken } from "../../hooks/bundle_hooks";
import { editInfo } from "../../utils/bundle_utils";

export default function MypageEssential() {
  const navigate = useNavigate();
  const { form, setForm, valid, validPass, errorCheck } = useEssentialInfo([
    "mb_password",
    "mb_password_cfm",
    "mb_family",
    "mb_email",
  ]);
  const { resData, mb_no, postData, updateUserInfo, errorImage } =
    useCheckToken();

  const dataSubmit = async (e) => {
    e.preventDefault();
    const mb_password = form.mb_password.val;
    const mb_email = form.mb_email.val;
    const mb_family = form.mb_family.val;
    const res = await postData("mypage/update", {
      mb_password,
      mb_email,
      mb_family,
      mb_no,
      type: "essential",
    });
    // console.log(res);
    if (res.code === 200) {
      alert("정보 수정이 완료되었습니다.");
      return navigate("/Mypage");
    } else {
      alert("정보 수정에 실패했습니다.");
    }
  };

  useEffect(() => {
    postData("mypage/edit", { mb_no, type: "essential" });
  }, []);

  useEffect(() => {
    updateUserInfo(form, setForm, "essential");
  }, [resData]);

  if (errorImage) {
    return (
      <div className="no_data_wrap">
        <img src={errorImage}></img>
      </div>
    );
  }
  return (
    <div id="mypage_essential" className="container">
      <div className="inner mypage">
        <h3 className="mypage_tit">필수 정보</h3>
        <div className="form_wrap">
          <form action="">
            <div className="input_ty01">
              <label htmlFor="">
                아이디
                <input type="text" value={form.mb_id.val} readOnly />
              </label>
            </div>
            <div className={"input_ty01 " + errorCheck("mb_password")?.state}>
              <label htmlFor="">
                비밀번호
                <input
                  type="password"
                  placeholder="영문,숫자, 특수문자 조합 8자리 이상"
                  data-type="mb_password"
                  value={form.mb_password.val}
                  onChange={valid}
                />
              </label>
              {errorCheck("mb_password")?.alert}
            </div>
            <div
              className={"input_ty01 " + errorCheck("mb_password_cfm")?.state}
            >
              <label htmlFor="">
                비밀번호 재확인
                <input
                  type="password"
                  placeholder="비밀번호 재입력"
                  data-type="mb_password_cfm"
                  value={form.mb_password_cfm.val}
                  onChange={valid}
                />
              </label>
              {errorCheck("mb_password_cfm")?.alert}
            </div>
            <div className="input_ty01">
              <label htmlFor="">
                이름
                <input type="text" value={form.mb_name.val} readOnly />
              </label>
            </div>
            <div className="input_ty01">
              <label htmlFor="">
                성별
                <input type="text" value={form.mb_sex.val} readOnly />
              </label>
            </div>
            <div className="input_ty01">
              <label htmlFor="">
                생년월일
                <input type="text" value={form.mb_birth.val} readOnly />
              </label>
            </div>
            <div className="input_ty01 edit">
              <button className="btn_edit" onClick={editInfo}></button>
              <label htmlFor="">
                거주 인원 수
                <input
                  type="text"
                  placeholder="숫자만 입력"
                  data-type="mb_family"
                  value={form.mb_family.val}
                  onChange={valid}
                  readOnly
                />
              </label>
            </div>
            <div
              className={
                "input_ty01 edit edit_email " + errorCheck("mb_email")?.state
              }
            >
              <label htmlFor="">
                이메일
                <input
                  type="text"
                  placeholder="이메일"
                  data-type="mb_email"
                  value={form.mb_email.val}
                  onChange={valid}
                  readOnly
                />
              </label>
              {errorCheck("mb_email")?.alert}
            </div>
            <div className="input_ty01">
              <label htmlFor="">
                휴대폰 번호
                <input type="text" value={form.mb_hp.val} readOnly />
              </label>
            </div>
            <div className="btn_wrap">
              <Link to="/Mypage" className="btn_ty01 cancel bBtn half">
                취소
              </Link>
              <button
                type="submit"
                className="btn_ty01 bBtn half right"
                disabled={!validPass()}
                onClick={dataSubmit}
              >
                수정
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
