export default function Electricity(props) {
  const { handleInput, dataForm } = props;
  return (
    <div className="calc_electric step_box">
      <div className="q_item">
        <h5 className="question small">한달 사용량을 입력해주세요.</h5>
        <div className="input_box input_ty02 d-flex flex-ac">
          <input type="text" placeholder="숫자만 입력" data-type="사용량" value={dataForm["사용량"]} onChange={handleInput} />
          <span>kWh/월</span>
        </div>
      </div>
      <div className="q_item">
        <h5 className="question small">거주 인원 수를 입력해주세요.</h5>
        <div className="input_box input_ty02 d-flex flex-ac">
          <input type="text" placeholder="숫자만 입력" data-type="거주인원수" value={dataForm["거주인원수"]} onChange={handleInput} />
          <span>명</span>
        </div>
      </div>
    </div>
  );
}
