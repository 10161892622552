import { useUploadFile, useCheckToken } from "../../hooks/bundle_hooks";
import bannerImg from "../../assets/img/ch_write_bn.png";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import { serverUrl } from "../../variables/serverUrl";

export default function ChallengeEdit() {
  const allowType = ["jpg", "jpeg", "png"];
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    fileData,
    letter,
    setFileData,
    setLetter,
    uploadFile,
    deleteFile,
    writing,
  } = useUploadFile(allowType, 8, 3);
  const { mb_no, postData } = useCheckToken();
  const fileRef = useRef(null);

  const dataSubmit = async () => {
    const formData = new FormData();
    formData.append("letter", letter);
    formData.append("mb_no", mb_no);
    formData.append("wr_id", state.wr_id);
    formData.append("challenge_no", 1);
    fileData.forEach((el) => {
      formData.append("challengeimage", el.file);
    });
    const res = await postData("challenge/update", formData);
    alert(res.msg);
    if (res.code !== 200) return;
    navigate("/ChallengeMain");
  };

  // console.log(state.wr_id);

  const convertURLtoFile = async (url) => {
    url = serverUrl + "images" + url;
    const response = await fetch(url);
    const data = await response.blob();
    const ext = url.split(".").pop();
    const filename = url.split("/").pop();
    const metadata = { type: `image/${ext}` };
    return new File([data], filename, metadata);
  };

  const loadPostData = async () => {
    const res = await postData("challenge/edit", { mb_no, wr_id: state.wr_id });
    const urlArr = res.data.file;
    const dataTranster = new DataTransfer();
    for (let el of urlArr) {
      const convData = await convertURLtoFile(el);
      dataTranster.items.add(convData);
    }
    fileRef.current.files = dataTranster.files;
    const fileArr = Array.from(dataTranster.files);
    const newFileData = fileArr.map((el) => {
      return { file: el, url: URL.createObjectURL(el) };
    });
    setLetter(res.data.wr_content);
    setFileData(newFileData);
  };

  useEffect(() => {
    loadPostData();
  }, []);

  return (
    <div id="challenge_write" className="container">
      <div className="inner">
        <div className="challenge_banner">
          <img src={bannerImg} alt="" />
        </div>
        <div className="challenge_desc">
          <strong>
            <h4 className="ch_tit c_green">친환경 챌린지</h4>
            오늘부터 매일 한 발자국 씩!
          </strong>
          <p>꾸준한 기록으로 환경보호 하는 습관을 만들어 보세요.</p>
        </div>
        <div className="write_area">
          <textarea
            placeholder="내용을 입력하세요."
            value={letter}
            onChange={writing}
          ></textarea>
        </div>
        <p
          style={{
            fontSize: "12px",
            color: "#939393",
            marginBottom: "8px",
          }}
        >
          ※ 챌린지 내용은 16자 이상 입력해주세요.
        </p>
        <div className="photo_area d-flex">
          <button htmlFor="" className="b_box btn_camera">
            <input
              ref={fileRef}
              type="file"
              accept="image/*"
              multiple
              onChange={uploadFile}
            />
            <span className="length">
              <span className="c_green">{fileData.length}</span>/3
            </span>
          </button>
          {fileData.map((el, idx) => {
            return (
              <div className="b_box" key={idx}>
                <img src={el.url} alt="" />
                <button
                  className="btn_del"
                  data-url={el.url}
                  onClick={deleteFile}
                >
                  &times;
                </button>
              </div>
            );
          })}
        </div>
        <div className="btn_wrap">
          <Link to="/" className="btn_ty01 cancel bBtn half">
            취소
          </Link>
          <button
            className="btn_ty01 bBtn half right"
            disabled={!(letter && fileData[0])}
            onClick={dataSubmit}
          >
            등록
          </button>
        </div>
      </div>
    </div>
  );
}
