import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { joinDataAtom, referralAtom } from "../../recoil/atoms";
import { useSelectInfo, useSelectBox } from "../../hooks/bundle_hooks";
import FindAddr from "../../components/SearchAddr";
import FindReferral from "./../../components/SearchReferral";

export default function JoinSelect() {
  const navigate = useNavigate();
  const { choiceForm, setChoiceForm, dataSel } = useSelectInfo();
  const [joinData, setJoinData] = useRecoilState(joinDataAtom);
  const { selectList, handleSelectBox } = useSelectBox({
    transSel: false,
    carSel: false,
    oilSel: false,
    methodSel: false,
    jobSel: false,
    abilitySel: false,
  });
  const [showForm, setShowForm] = useState(true);
  const [addrBox, setAddrBox] = useState("");
  const [referral, setReferral] = useRecoilState(referralAtom);
  const [referralId, setReferralId] = useState("");
  const [referralBox, setReferralBox] = useState("");

  console.log(referral);

  const dataSubmit = () => {
    setJoinData({ ...joinData, ...choiceForm });
    navigate("/JoinComplete");
  };

  useEffect(() => {
    // 추천 코드로 가입하는 경우 ID 자동입력
    if (referral.link !== null && referral.link !== "") {
      setChoiceForm({ ...choiceForm, referral: referral.link });
    }
  }, []);

  return (
    <div id="joinSelect" className={addrBox ? "join stop_scroll" : "join"}>
      <div className="inner">
        <h3 className="type_tit">
          선택정보입력
          {/* <span className="s_info">
            (선택정보 모두 입력 시 <span className="c_green">1,000</span> 포인트
            지급)
          </span> */}
          {/* <button
            className={showForm ? "btn_fold" : "btn_fold close"}
            onClick={() => {
              setShowForm(!showForm);
            }}
          ></button> */}
        </h3>
        {showForm && (
          <div className="form_wrap">
            <form action="">
              <div
                className="d-flex flex_wrap"
                onClick={() => {
                  if (referral.link === null || referral.link === "") {
                    setReferralBox("active");
                  }
                }}
              >
                <div className="input_ty01 no-margin">
                  {/* 추천링크 없이 일반회원가입 */}
                  {referral.link === null || referral.link === "" ? (
                    <>
                      <input
                        type="hidden"
                        name="referral"
                        value={choiceForm.referral}
                        readOnly
                      />
                      <label htmlFor="">
                        추천인
                        <input
                          placeholder="추천인 검색"
                          type="text"
                          value={referralId}
                          readOnly
                        />
                      </label>
                    </>
                  ) : (
                    /* 추천링크로 가입 하는경우(추천인 자동 입력상태) */
                    <>
                      <input
                        type="hidden"
                        name="referral"
                        value={referral.link}
                        readOnly
                      />
                      <label htmlFor="">
                        추천인
                        <input type="text" value={referral.mb_id} readOnly />
                      </label>
                    </>
                  )}
                </div>
                {referral.link === null || referral.link === "" ? (
                  <button type="button" className="btn_ty01 small radius line">
                    검색
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="d-flex flex_wrap"
                onClick={() => {
                  setAddrBox("active");
                }}
              >
                <div className="input_ty01 no-margin">
                  <label htmlFor="">
                    주소
                    <input
                      placeholder="주소입력"
                      type="text"
                      name="address"
                      value={choiceForm.addr}
                      readOnly
                    />
                  </label>
                </div>
                <button type="button" className="btn_ty01 small radius line">
                  주소찾기
                </button>
              </div>
              <div className="input_ty01">
                <label htmlFor="">
                  상세주소
                  <input
                    type="text"
                    placeholder="상세주소입력"
                    data-type="detail_addr"
                    value={choiceForm.detail_addr}
                    onChange={dataSel}
                  />
                </label>
              </div>
              <div className="radio_group">
                <span className="tit">주택형태</span>
                {[
                  ["아파트", "apartment"],
                  ["다세대", "multi"],
                  ["단독", "alone"],
                ].map((el, idx) => {
                  return (
                    <div key={idx} className="check_type radio">
                      <label htmlFor={el[1]}>
                        <input
                          type="radio"
                          id={el[1]}
                          name="house"
                          data-type="residence_type"
                          data-value={el[0]}
                          onClick={dataSel}
                        />
                        <span>{el[0]}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
              <div
                className="input_ty01 select_input"
                onClick={() => handleSelectBox("transSel")}
              >
                <label htmlFor="">주요 이동수단</label>
                <input
                  type="text"
                  placeholder="선택하세요"
                  value={choiceForm.transportation}
                  readOnly
                />
                {selectList.transSel && (
                  <ul className="select_box">
                    {[
                      "자가용",
                      "지하철",
                      "버스",
                      "기차",
                      "오토바이",
                      "자전거",
                      "도보",
                    ].map((el, idx) => {
                      return (
                        <li
                          key={idx}
                          data-type="transportation"
                          data-value={el}
                          onClick={dataSel}
                        >
                          {el}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div
                className="input_ty01 select_input"
                onClick={() => handleSelectBox("carSel")}
              >
                <label htmlFor="">차종</label>
                <input
                  type="text"
                  placeholder="선택하세요"
                  value={choiceForm.car_type}
                  readOnly
                />
                {selectList.carSel && (
                  <ul className="select_box">
                    {[
                      "없음",
                      "경형",
                      "소형",
                      "준중형",
                      "중형",
                      "준대형",
                      "대형",
                      "스포츠카",
                    ].map((el, idx) => {
                      return (
                        <li
                          key={idx}
                          data-type="car_type"
                          data-value={el}
                          onClick={dataSel}
                        >
                          {el}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              {choiceForm.car_type !== "없음" && (
                <>
                  <div className="input_ty01 CC">
                    <label htmlFor="">
                      차종 배기량
                      <input
                        type="text"
                        placeholder="숫자만 입력"
                        data-type="cc"
                        value={choiceForm.cc}
                        onChange={dataSel}
                        maxLength={4}
                      />
                    </label>
                  </div>
                  <div
                    className="input_ty01 select_input"
                    onClick={() => handleSelectBox("oilSel")}
                  >
                    <label htmlFor="">유종</label>
                    <input
                      type="text"
                      placeholder="선택하세요"
                      value={
                        choiceForm.car_type === "없음"
                          ? "없음"
                          : choiceForm.oil_type
                      }
                      readOnly
                    />
                    {selectList.oilSel && (
                      <ul className="select_box">
                        {[
                          "없음",
                          "가솔린",
                          "디젤",
                          "하이브리드",
                          "LPG",
                          "전기",
                          "수도",
                        ].map((el, idx) => {
                          return (
                            <li
                              key={idx}
                              data-type="oil_type"
                              data-value={el}
                              onClick={dataSel}
                            >
                              {el}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </>
              )}

              <div className="radio_group">
                <span className="tit">음식물 처리기 소유 여부</span>
                {[
                  ["있음", "yes"],
                  ["없음", "no"],
                ].map((el, idx) => {
                  return (
                    <div key={idx} className="check_type radio">
                      <label htmlFor={el[1]}>
                        <input
                          type="radio"
                          id={el[1]}
                          name="have"
                          data-type="ownership"
                          data-value={el[0]}
                          onClick={dataSel}
                        />
                        <span>{el[0]}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
              <div
                className="input_ty01 select_input"
                onClick={() => handleSelectBox("methodSel")}
              >
                <label htmlFor="">음식물 쓰레기 처리 방식</label>
                <input
                  type="text"
                  placeholder="선택하세요"
                  value={choiceForm.handling}
                  readOnly
                />
                {selectList.methodSel && (
                  <ul className="select_box">
                    {choiceForm.ownership === "없음"
                      ? [
                          "공동주택 세대별 카드",
                          "공동주택 종량제 스티커",
                          "음식물 전용 봉투 및 전용 용기",
                        ].map((el, idx) => {
                          return (
                            <li
                              key={idx}
                              data-type="handling"
                              data-value={el}
                              onClick={dataSel}
                            >
                              {el}
                            </li>
                          );
                        })
                      : [
                          "음식물처리기",
                          "공동주택 세대별 카드",
                          "공동주택 종량제 스티커",
                          "음식물 전용 봉투 및 전용 용기",
                        ].map((el, idx) => {
                          return (
                            <li
                              key={idx}
                              data-type="handling"
                              data-value={el}
                              onClick={dataSel}
                            >
                              {el}
                            </li>
                          );
                        })}
                  </ul>
                )}
              </div>
              <div
                className="input_ty01 select_input"
                onClick={() => handleSelectBox("jobSel")}
              >
                <label htmlFor="">직업</label>
                <input
                  type="text"
                  placeholder="선택하세요"
                  value={choiceForm.job}
                  readOnly
                />
                {selectList.jobSel && (
                  <ul className="select_box">
                    {["주부", "공무원", "회사원", "자영업", "학생", "무직"].map(
                      (el, idx) => {
                        return (
                          <li
                            key={idx}
                            data-type="job"
                            data-value={el}
                            onClick={dataSel}
                          >
                            {el}
                          </li>
                        );
                      }
                    )}
                  </ul>
                )}
              </div>
              <div
                className="input_ty01 select_input"
                onClick={() => handleSelectBox("abilitySel")}
              >
                <label htmlFor="">최종학력</label>
                <input
                  type="text"
                  placeholder="선택하세요"
                  value={choiceForm.graduation}
                  readOnly
                />
                {selectList.abilitySel && (
                  <ul className="select_box">
                    {[
                      "대학원",
                      "대학",
                      "전문대",
                      "고등학교",
                      "중학교",
                      "초등학교",
                      "해당사항 없음",
                    ].map((el, idx) => {
                      return (
                        <li
                          key={idx}
                          data-type="graduation"
                          data-value={el}
                          onClick={dataSel}
                        >
                          {el}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div className="radio_group marry">
                <span className="tit">결혼여부</span>
                {[
                  ["기혼", "marriage"],
                  ["미혼", "single"],
                ].map((el, idx) => {
                  return (
                    <div key={idx} className="check_type radio">
                      <label htmlFor={el[1]}>
                        <input
                          type="radio"
                          id={el[1]}
                          name="marry"
                          data-type="marriage"
                          data-value={el[0]}
                          onClick={dataSel}
                        />
                        <span>{el[0]}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
              {/* 입력완료 클릭 시 JoinComplete로 페이지 빠지기 */}
            </form>
          </div>
        )}
        {!addrBox && (
          <button className="bBtn btn_ty01" onClick={dataSubmit}>
            다음
          </button>
        )}
      </div>
      <FindAddr
        addrBox={addrBox}
        setAddrBox={setAddrBox}
        choiceForm={choiceForm}
        setChoiceForm={setChoiceForm}
      />
      <FindReferral
        referralBox={referralBox}
        setReferralBox={setReferralBox}
        choiceForm={choiceForm}
        setChoiceForm={setChoiceForm}
        setReferral={setReferralId}
      />
    </div>
  );
}
