import { useState } from "react";
import { useRecoilState } from "recoil";
import { joinDataAtom, userDataAtom } from "../recoil/atoms";
import { serverUrl } from "../variables/bundle_variables";
import axios from "axios";

export function useEssentialInfo(option) {
  const [joinData, setJoinData] = useRecoilState(joinDataAtom);
  const [userData, setUserData] = useRecoilState(userDataAtom);

  // const [form, setForm] = useState({
  //   id: { val: userData.id || "", isValid: userData.id ? true : false },
  //   id_dup: { isValid: userData.id ? true : false },
  //   pw: { val: "", isValid: false },
  //   pw_cfm: { val: "", isValid: false },
  //   name: {
  //     val: joinData.name || userData.name || "",
  //     isValid: joinData.name || userData.name ? true : false,
  //   },
  //   gender: {
  //     val: joinData.gender || userData.gender || "",
  //     isValid: joinData.gender || userData.gender ? true : false,
  //   },
  //   birth: {
  //     val: joinData.birth || userData.birth || "",
  //     isValid: joinData.birth || userData.birth ? true : false,
  //   },
  //   family: {
  //     val: userData.family || "",
  //     isValid: userData.family ? true : false,
  //   },
  //   email: {
  //     val: userData.email || "",
  //     isValid: userData.email ? true : false,
  //   },
  //   ph: {
  //     val: joinData.ph || userData.ph || "",
  //     isValid: joinData.ph || userData.ph ? true : false,
  //   },
  // });

  const [form, setForm] = useState({
    mb_id: { val: "", isValid: false },
    mb_id_dup: { isValid: false },
    mb_password: { val: "", isValid: false },
    mb_password_cfm: { val: "", isValid: false },
    mb_name: { val: "", isValid: false },
    mb_sex: { val: "", isValid: false },
    mb_birth: { val: "", isValid: false },
    mb_family: { val: "", isValid: false },
    mb_email: { val: "", isValid: false },
    mb_hp: { val: "", isValid: false },
  });

  const errorText = {
    mb_id: "아이디 입력 형식이 잘못되었습니다.",
    mb_id_dup: "아이디 중복확인이 되지 않았습니다.",
    mb_password: "비밀번호 입력 형식이 잘못되었습니다.",
    mb_password_cfm: "입력하신 비밀번호와 일치하지 않습니다.",
    mb_name: "이름 입력 형식이 잘못되었습니다.",
    mb_birth: "생년월일 입력 형식이 잘못되었습니다",
    mb_email: "이메일 입력 형식이 잘못되었습니다.",
    mb_hp: "휴대폰번호 입력 형식이 잘못되었습니다.",
  };

  const valid = (e) => {
    let val = e.target.value && e.target.value.replace(/ /g, "");
    const type = e.target.dataset.type;
    const exp = {
      mb_name: /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/,
      // 한글 2~4자 또는 영문 성 2~10자, 띄어쓰기, 영문 이름 2~10자(한글, 영문 혼용X)
      mb_id: /^[a-z]+[a-z0-9]{5,29}$/,
      //영문으로 시작하는 영문, 숫자 조합 6자 이상 30자 이하
      mb_password:
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/,
      //영문, 숫자, 특수문자를 최소 한가지씩 조합 8자 이상 30자 이하
      mb_family: /^[0-9]+$/,
      //숫자만 입력

      mb_email:
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
      //이메일 형식
      mb_birth:
        /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
      //19 혹은 20 으로 시작하는 8자리 숫자
      mb_hp: /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/,
      //01로 시작하는 10~11자리 숫자
    };
    switch (type) {
      case "mb_id": {
        setForm({
          ...form,
          [type]: { val, isValid: exp[type].test(val) },
          mb_id_dup: { isValid: false },
        });
        break;
      }
      case "mb_password": {
        const pwCfmVal = form.mb_password_cfm.val;
        setForm({
          ...form,
          [type]: { val, isValid: exp[type].test(val) },
          mb_password_cfm: { val: pwCfmVal, isValid: val === pwCfmVal },
        });
        break;
      }
      case "mb_password_cfm": {
        setForm({
          ...form,
          [type]: { val, isValid: val === form.mb_password.val },
        });
        break;
      }
      case "mb_email_f": {
        const emailB = form.mb_email.val.includes("@")
          ? form.mb_email.val.split("@")[1]
          : "";
        val = val + "@" + emailB;
        setForm({
          ...form,
          mb_email: { val, isValid: exp["mb_email"].test(val) },
        });
        break;
      }
      case "mb_email_b": {
        const emailF = form.email.val.split("@")[0];
        val = emailF + "@" + val;
        setForm({
          ...form,
          mb_email: { val, isValid: exp["mb_email"].test(val) },
        });
        break;
      }
      case "mb_email_a": {
        const emailF = form.mb_email.val.split("@")[0];
        val = emailF + "@" + e.target.innerText;
        setForm({
          ...form,
          mb_email: { val, isValid: exp["mb_email"].test(val) },
        });
        break;
      }
      case "mb_family": {
        val = val.replace(/[^0-9]/, "");
        setForm({ ...form, [type]: { val, isValid: exp[type].test(val) } });
        break;
      }
      default: {
        setForm({ ...form, [type]: { val, isValid: exp[type].test(val) } });
      }
    }
  };

  const dupCheck = async (e) => {
    e.preventDefault();
    // console.log({
    //   mb_id: form.mb_id.val,
    //   mb_name: form.mb_name.val,
    //   mb_birth: form.mb_birth.val,
    //   mb_hp: form.mb_hp.val,
    //   mb_sex: form.mb_sex.val,
    //   uuid: joinData?.uuid,
    // });
    const res = await axios.post(serverUrl + "auth/dup_nick", {
      mb_id: form.mb_id.val,
      mb_name: form.mb_name.val,
      mb_birth: form.mb_birth.val,
      mb_hp: form.mb_hp.val,
      mb_sex: form.mb_sex.val,
      uuid: joinData?.uuid,
    });
    // console.log(res);
    if (res.data.code === 200) {
      alert(res.data.msg);
      return setForm({ ...form, mb_id_dup: { isValid: true } });
    }
    alert(res.data.msg);
    // setForm({ ...form, id_dup: {isValid:result} });
  };

  const validPass = () => {
    if (option) {
      return option.every((el) => form[el].isValid);
    } else return Object.values(form).every((el) => el.isValid);
  };

  const errorCheck = (type) => {
    switch (type) {
      case "mb_id_dup": {
        if (form["mb_id"].val && !form["mb_id"].isValid) {
          return {
            state: "error",
            alert: <p className="error_text">{errorText["mb_id"]}</p>,
          };
        } else if (form["mb_id"].isValid && !form[type].isValid) {
          return {
            state: "error",
            alert: <p className="error_text">{errorText[type]}</p>,
          };
        }
        break;
      }
      case "mb_email": {
        // if (form[type].val && !form[type].isValid && form[type].val !== "@") {
        if (form[type].val && !form[type].isValid) {
          return {
            state: "error",
            alert: <p className="error_text">{errorText[type]}</p>,
          };
        }
        break;
      }
      default: {
        if (form[type].val && !form[type].isValid) {
          return {
            state: "error",
            alert: <p className="error_text">{errorText[type]}</p>,
          };
        }
      }
    }
  };
  return { form, setForm, valid, dupCheck, validPass, errorCheck };
}
