import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import banner from "../../assets/img/GL_banner.png";
import noPage from "../../assets/img/no_page.png";
import axios from "axios";
import { serverUrl } from "../../variables/serverUrl";

export default function EventNews() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tab, setTab] = useState("전체");
  const [postList, setPostList] = useState([]);
  const [bannerResult, setBannerResult] = useState([]);
  const [errorImage, setErrorImage] = useState();

  const eventSlide = {
    modules: [Pagination],
    slidesPerView: 1,
    loop: true,
    pagination: {
      type: "fraction",
    },
  };

  const loadPostData = async () => {
    const param = {
      전체: "?category=all",
      EVENT: "?category=event",
      NEWS: "?category=news",
    }[tab];
    try {
      const res = await axios.get(serverUrl + "community/index" + param);
      setPostList(res.data.data.boardInfo);
      setBannerResult(res.data.data.bannerInfo);
    } catch (err) {
      setErrorImage(noPage);
    }
  };

  useEffect(() => {
    loadPostData();
  }, [tab]);

  if (errorImage) {
    return (
      <div className="no_data_wrap">
        <img src={errorImage}></img>
      </div>
    );
  }
  return (
    <div id="event_news" className="container community">
      {bannerResult[0] && (
        <Swiper className="basic_slide" {...eventSlide}>
          {bannerResult.map((el, idx) => {
            return (
              <SwiperSlide
                key={idx}
                onClick={() => {
                  navigate(el.bannerPath.split(".kr")[1]);
                }}
              >
                <img src={serverUrl + "images" + el.bannerImage} alt="" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}

      <div className="notice_box">
        <ul className="tab_btn">
          {/* <li className="active">전체</li> */}
          {["전체", "EVENT", "NEWS"].map((el, idx) => {
            return (
              <li
                key={idx}
                className={el === tab ? "active" : ""}
                onClick={() => {
                  setTab(el);
                }}
              >
                {el}
              </li>
            );
          })}
          {/* <li>EVENT</li>
          <li>NEWS</li> */}
        </ul>
        <ul className="notice_list">
          {/* 게시글 0개일 때 */}
          {/* <li className="empty_text">
            게시글이 없습니다.
          </li> */}
          {postList.map((el, idx) => {
            return (
              <li
                key={idx}
                className="list"
                onClick={() => {
                  // navigate("/EventNewsDetail", { state: { wr_id: el.wr_id, category: el.wr_subject } });
                  navigate(
                    "/EventNewsDetail/" + el.wr_subject + "/" + el.wr_id,
                    { state: { wr_id: el.wr_id, category: el.wr_subject } }
                  );
                }}
              >
                {/* <Link to={"/EventNewsDetail/" + el.wr_id}> */}
                <span className="category c_green">
                  [{el.wr_subject.toUpperCase()}]
                </span>
                {el.is_closed === 1 && <span className="end_badge">종료</span>}
                <p className="title">{el.wr_seo_title}</p>
                {/* </Link> */}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
