import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import "../../assets/scss/device.css";
import DeviceHeader from "./DeviceHeader";
import DeviceInitImage from "../../assets/img/device/1.START-_-image.png";

export default function DeviceInit() {
  const navigate = useNavigate();


  function moveToNextStep() {
    // window.flutter_inappwebview 객체가 존재하는지 확인
    if (window.flutter_inappwebview) {
      //다음페이지로 이동
      navigate('/DeviceConnect');
    } else {
      // window.flutter_inappwebview 객체가 존재하지 않으면 경고창을 띄웁니다.
      alert('해당 동작은 앱에서만 가능합니다.');
    }
  }


  return (
    <div id="device" className="container init">
      <DeviceHeader msg={`안녕하세요. 바리미 입니다.`} />
      <div className="device_wrap">
        <div className="device_inner">
          <img
            className="device_img"
            src={DeviceInitImage}
            alt="바리미_이미지"
          />
        </div>

        <div className="device_thanks">
          <div className="device_thanks_content">
            <p>바리미 음식물 처리기를</p>
            <p>구매해 주셔서 감사합니다.</p>
          </div>
          <span>음식물 처리기를 등록해주세요.</span>
        </div>
        <div className="device_btn" onClick={moveToNextStep}>
          <a>다음</a>
        </div>
      </div>
    </div>
  );
}
