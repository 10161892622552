import { useEffect, useState } from "react";
import { useCheckToken } from "../../hooks/bundle_hooks";
import { serverUrl } from "../../variables/serverUrl";
import Spiner from "../../components/SpinerAni";
import noPost from "../../assets/img/no_post.png";

export default function QnaHis() {
  const { mb_no, postData, resData, errorImage } = useCheckToken();
  const [closeToast, setCloseToast] = useState(false);
  const [category, setCategory] = useState("전체보기");
  const [isLoadData, setIsLoadData] = useState(false);

  const loadPostData = async () => {
    const qa_status = { 전체보기: "", 답변대기중: 0, 답변완료: 1 }[
      category.replace(/\s+/g, "")
    ];
    await postData("inquire/index", { mb_no, qa_status });
    setIsLoadData(true);
  };

  useEffect(() => {
    loadPostData();
  }, [category]);

  if (isLoadData && errorImage) {
    return (
      <div className="no_data_wrap">
        <img src={errorImage}></img>
      </div>
    );
  }
  return (
    <>
      <div
        className="filter_box d-flex flex-ac flex-js"
        style={{ display: "none" }}
      >
        <span>전체보기</span>
        <button className="btn_filter" onClick={setCloseToast}></button>
      </div>
      {closeToast ? (
        <div className="toast_wrap">
          <div className="dim" onClick={() => setCloseToast(false)}></div>
          <div className="toast">
            <div className="toast_head">
              <h3 className="toast_tit">필터</h3>
              <button
                className="btn_close"
                onClick={() => setCloseToast(false)}
              >
                &times;
              </button>
            </div>
            <div className="toast_body">
              <ul className="select_list">
                {["전체보기", "답변 완료", "답변 대기중"].map((el, idx) => {
                  return (
                    <li
                      key={idx}
                      className={el === category ? "select" : ""}
                      onClick={() => {
                        setCategory(el);
                        setCloseToast(false);
                      }}
                    >
                      {el}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="qna_wrap">
        <div className="inner">
          <div className="list_wrap list_ty01">
            {resData?.inquireInfo.map((el, idx) => {
              return <QnaItem key={idx} data={el} />;
            })}
          </div>
          {/* <div className="btn_wrap view_more">
              <button className="btn box_ty01">+ 더보기</button>
            </div> */}
          {!isLoadData && <Spiner />}
          {isLoadData && !resData && (
            <div className="no_data_wrap">
              <img src={noPost}></img>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function QnaItem({ data }) {
  // console.log(data.qa_category);
  const [showDetail, setShowDetail] = useState(false);
  return (
    <div className={"list box_ty01 " + (data.qa_status ? "complete" : "")}>
      <div
        onClick={() => {
          setShowDetail(!showDetail);
        }}
        className={"list_question " + (showDetail ? "show" : "")}
      >
        <span className="q_badge">
          {data.qa_status === 0 ? "답변 대기중" : "답변 완료"}
        </span>
        <span className="category c_darkGray">
          {"[" + data.qa_category + "]"}&nbsp;&nbsp;&nbsp;
          {data.qa_datetime.replace(/-/g, ".")}
        </span>
        <div className="text question">{data.qa_content}</div>
        <div className="file_box">
          {data.file &&
            data.file.map((el, idx) => {
              return (
                <a
                  key={idx}
                  href={
                    serverUrl +
                    "download" +
                    el +
                    "?originalname=" +
                    data.file_original[idx]
                  }
                  className="btn_file c_darkGray"
                  download="d.jpeg"
                >
                  {data.file_original[idx]}
                </a>
              );
            })}
          {/* <button className="btn_file c_darkGray">123574881054154146697.jpg</button> */}
        </div>
        <button className="btn_more"></button>
      </div>
      <div className="list_answer">
        <p className="text answer">{data.qa_answer_content}</p>
        <span className="date c_darkGray">
          {data.qa_answer_datetime.replace(/-/g, ".")}
        </span>
      </div>
    </div>
  );
}
