import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCheckToken } from "../../hooks/bundle_hooks";

export default function Withdrawal() {
  const { mb_no, postData } = useCheckToken();
  const [isCheckEtc, setIsCheckEtc] = useState(false);
  const [reason, setReason] = useState({ select: [], etc: "" });
  const navigate = useNavigate();

  const showEtcInput = (e) => {
    if (!e.target.checked) {
      setReason({ ...reason, etc: "" });
    }
    setIsCheckEtc(!isCheckEtc);
  };

  const writtenReason = (e) => {
    setReason({ ...reason, etc: e.target.value });
  };

  const selectReason = (e) => {
    let copy = { ...reason };
    if (!e.target.checked) {
      copy.select.splice(copy.select.indexOf(e.target.dataset.reason), 1);
      return setReason(copy);
    }
    copy.select.push(e.target.dataset.reason);
    setReason(copy);
  };

  const dataSubmit = async () => {
    const data = reason.etc ? [...reason.select, reason.etc] : reason.select;
    const confirm = window.confirm(
      "탈퇴하시면 보유하고 있는 포인트가 모두 소멸됩니다. 탈퇴하시겠습니까?"
    );
    if (confirm) {
      const res = await postData("mypage/withdrawal", { mb_no, reason: data });
      if (res.code === 200) {
        alert("탈퇴처리 되었습니다.");
        navigate("/Login");
      }
    } else {
      return false;
    }
  };

  return (
    <div id="withdrawal" className="container">
      <div className="inner mypage">
        <p className="strong">
          <span className="c_green">탈퇴</span>하시는 이유를 알려주시면, <br />
          앞으로 보다 나은 서비스를 제공하는데 <br />
          도움이 됩니다.
        </p>
        <div className="reasons_box">
          <h4>
            탈퇴이유 <span className="c_gray">(중복 선택 가능)</span>
          </h4>
          {[
            "원하는 서비스가 없어요",
            "정보가 부족해요",
            "어플 사용을 안해요",
          ].map((el, idx) => {
            return (
              <div className="check_type radius" key={idx}>
                <label htmlFor={"c" + (idx + 1)}>
                  <input
                    type="checkbox"
                    id={"c" + (idx + 1)}
                    data-reason={el}
                    onClick={selectReason}
                  />
                  <span>{el}</span>
                </label>
              </div>
            );
          })}
          {/* <div className="check_type radius">
            <label htmlFor="c1">
              <input type="checkbox" id="c1" onClick={selectReason} />
              <span>원하는 서비스가 없어요.</span>
            </label>
          </div>
          <div className="check_type radius">
            <label htmlFor="c2">
              <input type="checkbox" id="c2" />
              <span>정보가 부족해요.</span>
            </label>
          </div>
          <div className="check_type radius">
            <label htmlFor="c3">
              <input type="checkbox" id="c3" />
              <span>어플 사용을 안해요.</span>
            </label>
          </div> */}
          <div className="check_type radius etc">
            <label htmlFor="c4">
              <input type="checkbox" id="c4" onClick={showEtcInput} />
              <span>기타</span>
            </label>
          </div>
          {isCheckEtc && (
            <textarea
              className="textarea"
              placeholder="탈퇴하시는 이유를 입력해 주세요."
              value={reason.etc}
              onChange={writtenReason}
            ></textarea>
          )}
        </div>
        <h4>탈퇴 하시겠습니까?</h4>
        <p className="info">
          탈퇴하시면 보유하고 있는 포인트가 모두 소멸되며,
          <br /> 탈퇴한 아이디로 재가입하실 수 없습니다.
        </p>
        <div className="btn_wrap">
          <Link to="/Mypage" className="btn_ty01 cancel bBtn half">
            취소
          </Link>
          <button
            className="btn_ty01 bBtn half right"
            onClick={dataSubmit}
            disabled={!(reason.select.length > 0 || reason.etc)}
          >
            탈퇴
          </button>
        </div>
      </div>
    </div>
  );
}
