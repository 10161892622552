import { atom } from "recoil";

export const resDataAtom = atom({
  key: "resDataAtom",
  default: null,
});

export const isLoginAtom = atom({
  key: "isLoginAtom",
  default: false,
});

export const joinDataAtom = atom({
  key: "joinDataAtom",
  default: {
    //임시데이터
    // agree_ad: false,
    // id: "",
    // pw: "",
    name: "천채범",
    gender: "남",
    birth: "19700101",
    // family: "",
    // email: "",
    ph: "01011112222",
    // addr: "",
    // detail_addr: "",
    // housing_type: "",
    // how_move: "",
    // car_type: "",
    // how_cc: "",
    // oil_type: "",
    // is_have: "",
    // disposal: "",
    // job: "",
    // ability: "",
    // is_married: "",
  },
});

export const userDataAtom = atom({
  key: "userDataAtom",
  default: {
    //임시데이터
    // agree_ad: false,
    // id: "glplus2",
    // pw: "",
    // name: "천채범",
    // gender: "남",
    // birth: "19700101",
    // family: 4,
    // email: "glplusss@gl.kr",
    // ph: "01011112222",
    // addr: "서울시 강남구 테헤란로",
    // detail_addr: "123호",
    // housing_type: "아파트",
    // how_move: "자가용",
    // car_type: "경형",
    // how_cc: "",
    // oil_type: "가솔린",
    // is_have: "있음",
    // disposal: "음식물처리기",
    // job: "주부",
    // ability: "대학원",
    // is_married: "기혼",
  },
});

export const referralAtom = atom({
  key: "referralAtom",
  default: {
    link: "",
    mb_id: "",
  },
});

export const findIDAtom = atom({
  key: "findIDAtom",
  default: {
    mb_id: "",
    mb_name: "",
    mb_hp: "",
    mb_birth: "",
  },
});

export const calcPageCountAtom = atom({
  key: "calcPageCountAtom",
  default: 0,
});

export const calcDataAtom = atom({
  key: "calcDataAtom",
  default: [
    {
      title: "교통",
      select: false,
      done: false,
      url: ["Traffic"],
      category: [
        {
          title: "1,000cc 미만 자가용",
          select: false,
          done: false,
          url: ["TrafficSmallCar01", "TrafficSmallCar02"],
          data: {
            유종: "",
            이동거리: "",
            동승인원: "",
          },
        },
        {
          title: "1,600cc 미만 자가용",
          select: false,
          done: false,
          url: ["TrafficSmallVan01", "TrafficSmallVan02"],
          data: {
            유종: "",
            이동거리: "",
            동승인원: "",
          },
        },
        {
          title: "2,000cc 미만 자가용",
          select: false,
          done: false,
          url: ["TrafficMediumCar01", "TrafficMediumCar02"],
          data: {
            유종: "",
            이동거리: "",
            동승인원: "",
          },
        },
        {
          title: "2,000cc 초과 자가용",
          select: false,
          done: false,
          url: ["TrafficLargeCar01", "TrafficLargeCar02"],
          data: {
            유종: "",
            이동거리: "",
            동승인원: "",
          },
        },
        {
          title: "버스",
          select: false,
          done: false,
          url: ["TrafficBus"],
          data: {
            탑승시간: "",
            이용횟수: "",
          },
        },
        {
          title: "지하철",
          select: false,
          done: false,
          url: ["TrafficSubway"],
          data: {
            탑승시간: "",
            이용횟수: "",
          },
        },
        {
          title: "일반기차",
          select: false,
          done: false,
          url: ["TrafficTrain"],
          data: {
            이동거리: "",
          },
        },
        {
          title: "KTX",
          select: false,
          done: false,
          url: ["TrafficKTX"],
          data: {
            이동거리: "",
          },
        },
      ],
    },
    {
      title: "여행",
      select: false,
      done: false,
      url: ["Travel"],
      category: [
        {
          title: "자가용",
          select: false,
          done: false,
          url: ["TravelCar"],
          category: [
            {
              title: "소형승용차(여행)",
              select: false,
              done: false,
              url: ["TravelSmallCar01", "TravelSmallCar02"],
              data: {
                유종: "",
                이동거리: "",
                동승인원: "",
              },
            },
            {
              title: "소형승합차(여행)",
              select: false,
              done: false,
              url: ["TravelSmallVan01", "TravelSmallVan02"],
              data: {
                유종: "",
                이동거리: "",
                동승인원: "",
              },
            },
            {
              title: "중형승용차(여행)",
              select: false,
              done: false,
              url: ["TravelMediumCar01", "TravelMediumCar02"],
              data: {
                유종: "",
                이동거리: "",
                동승인원: "",
              },
            },
            {
              title: "대형승용차(여행)",
              select: false,
              done: false,
              url: ["TravelLargeCar01", "TravelLargeCar02"],
              data: {
                유종: "",
                이동거리: "",
                동승인원: "",
              },
            },
          ],
        },
        {
          title: "택시(여행)",
          select: false,
          done: false,
          url: ["TravelTexi"],
          data: {
            탑승인원: "",
            이동거리: "",
            이용횟수: "",
          },
        },
        {
          title: "국내선항공",
          select: false,
          done: false,
          url: ["TravelFlightDomestic"],
          data: {
            탑승인원: "",
            출발지역: "",
            도착지역: "",
          },
        },
        {
          title: "국제선항공",
          select: false,
          done: false,
          url: ["TravelFlightInternational"],
          data: {
            탑승인원: "",
            출발지역: "",
            도착지역: "",
          },
        },
        {
          title: "버스(여행)",
          select: false,
          done: false,
          url: ["TravelBus"],
          data: {
            동승인원: "",
            이용횟수: "",
          },
        },
        {
          title: "지하철(여행)",
          select: false,
          done: false,
          url: ["TravelSubway"],
          data: {
            동승인원: "",
            이용횟수: "",
          },
        },
        {
          title: "일반기차(여행)",
          select: false,
          done: false,
          url: ["TravelTrain"],
          data: {
            동승인원: "",
            이용횟수: "",
          },
        },
        // {
        //   title: "KTX(여행)",
        //   select: false,
        //   done: false,
        //   url: ["TravelKTX"],
        //   data: {
        //     동승인원: "",
        //     이용횟수: "",
        //   },
        // },
      ],
    },
    {
      title: "요리",
      select: false,
      done: false,
      url: ["Cooking"],
      data: {
        소고기: "",
        돼지고기: "",
        닭고기: "",
        양고기: "",
        연어: "",
        참치통조림: "",
        해산물: "",
        계란: "",
        우유: "",
        치즈: "",
        두부: "",
        감자: "",
        쌀: "",
        토마토: "",
        바나나: "",
        다크초콜릿: "",
      },
    },
    {
      title: "전기",
      select: false,
      done: false,
      url: ["Electricity"],
      data: {
        사용량: "",
        거주인원수: "",
      },
    },
    {
      title: "가스",
      select: false,
      done: false,
      url: ["Gas"],
      data: {
        유종: "",
        사용량: "",
        거주인원수: "",
      },
    },
    {
      title: "수도",
      select: false,
      done: false,
      url: ["Water"],
      data: {
        사용량: "",
        거주인원수: "",
      },
    },
    {
      title: "쓰레기배출",
      select: false,
      done: false,
      url: ["Trash"],
      data: {
        배출량: "",
        거주인원수: "",
      },
    },
  ],
});
