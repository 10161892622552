import { useSelectBox } from "../../../hooks/bundle_hooks";

export default function TravelType08(props) {
  const { handleInput, dataForm } = props;
  const { selectList, handleSelectBox } = useSelectBox({ start: false, arrive: false });
  const route = {
    김포: ["제주", "광주", "여수", "울산", "진주", "무안", "포항"],
    인천: ["부산", "대구", "제주"],
    대구: ["제주", "인천"],
    청주: ["제주"],
    광주: ["제주", "김포"],
    여수: ["김포"],
    울산: ["김포"],
    진주: ["김포"],
    무안: ["김포"],
    포항: ["김포"],
  };

  return (
    <div className="calc_travel">
      <div className="travel_wrap step_box step08">
        <div className="q_item">
          <h5 className="question small">탑승 인원을 입력해주세요.</h5>
          <div className="input_box input_ty02 d-flex flex-ac">
            <input type="text" placeholder="숫자만 입력" data-type="탑승인원" value={dataForm["탑승인원"]} onChange={handleInput} />
            <span>명/총</span>
          </div>
        </div>
        <div className="q_item">
          <h5 className="question small">출발/도착 지역을 선택해주세요.</h5>
          <div
            className={"input_box input_ty02 select_input " + (selectList.start ? "active" : "")}
            onClick={() => {
              handleSelectBox("start");
            }}
          >
            <input type="text" placeholder="출발지" value={dataForm["출발지역"]} readOnly />
            {selectList.start && (
              <ul className="select_box">
                {Object.keys(route).map((el, idx) => {
                  return (
                    <li key={idx} data-value={el} data-type="출발지역" onClick={handleInput}>
                      {el}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div
            className={"input_box input_ty02 select_input " + (selectList.arrive ? "active" : "")}
            onClick={() => {
              handleSelectBox("arrive");
            }}
          >
            <input type="text" placeholder="도착지" value={dataForm["도착지역"]} readOnly />
            {selectList.arrive && (
              <ul className="select_box">
                {dataForm["출발지역"] &&
                  route[dataForm["출발지역"]].map((el, idx) => {
                    return (
                      <li key={idx} data-value={el} data-type="도착지역" onClick={handleInput}>
                        {el}
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
