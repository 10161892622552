export default function TrafficType04(props) {
  const { handleInput, dataForm } = props;
  return (
    <div className="calc_traffic">
      <div className="traffic_wrap step_box step04">
        <div className="q_item">
          <h5 className="question small">탑승 시간을 입력해주세요.</h5>
          <div className="input_box input_ty02 d-flex flex-ac">
            <input type="text" placeholder="숫자만 입력" data-type="탑승시간" value={dataForm["탑승시간"]} onChange={handleInput} />
            <span>분/일</span>
          </div>
        </div>
        <div className="q_item">
          <h5 className="question small">이용 횟수를 입력해주세요.</h5>
          <div className="input_box input_ty02 d-flex flex-ac">
            <input type="text" placeholder="숫자만 입력" data-type="이용횟수" value={dataForm["이용횟수"]} onChange={handleInput} />
            <span>회/월</span>
          </div>
        </div>
      </div>
    </div>
  );
}
