import profile from "../assets/img/profile.png";
import { serverUrl } from "../variables/bundle_variables";

export default function Profile({ width, height, url }) {
  return (
    <div className="profile_wrap" style={{ width: width + "px", height: height + "px" }}>
      <img src={url ? serverUrl + "images" + url : profile} alt="프로필 이미지" />
    </div>
  );
}
