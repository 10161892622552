import { Link } from "react-router-dom";
import "../../assets/scss/device.css";
import { useEffect, useState } from "react";
import { useCheckToken } from "../../hooks/bundle_hooks";

export default function DeviceHomeVersion() {
  const { isLogin, mb_no, device_mac_id, postData } = useCheckToken();
  const [myDevice, setMyDevice] = useState({});

  useEffect(() => {
    const loadDeviceInfo = async () => {
      const res = await postData("device/data", {
        mb_no,
        mac_id: device_mac_id,
      });

      if (res.code === 200) {
        setMyDevice(res.data);
      }
    };

    loadDeviceInfo();
  }, []);

  return (
    <>
      {isLogin && (
        <div className="device_inner">
          <div className="device_version">
            <li>
              <p className="title">버전 넘버</p>
              <p className="contents">{myDevice.firm_ver}</p>
            </li>
            <li>
              <p className="title">시리얼 넘버</p>
              <p className="contents">{myDevice.serial}</p>
            </li>
          </div>
        </div>
      )}
    </>
  );
}
