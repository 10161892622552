import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spiner from "../../components/SpinerAni";
import { useCheckToken } from "../../hooks/bundle_hooks";
import noHistory from "../../assets/img/no_history.png";

export default function ActiveSave() {
  const { mb_no, postData, resData, errorImage } = useCheckToken();
  const [closeToast, setCloseToast] = useState(false);
  const [filter, setFilter] = useState("전체보기");
  const [isLoadData, setIsLoadData] = useState(false);

  const loadData = async () => {
    await postData("history/stamp/index", {
      mb_no,
      filter: filter === "전체보기" ? 1 : 0,
    });
    setIsLoadData(true);
  };

  useEffect(() => {
    loadData();
  }, [filter]);

  return (
    <>
      <div className="filter_box d-flex flex-ac flex-js" style={{display: 'none'}}>
        <span>{filter}</span>
        <button className="btn_filter" onClick={setCloseToast}></button>
      </div>
      <div className="content_wrap">
        {/* 적립 내역 하나도 없을 때 */}
        {!isLoadData && <Spiner />}
        {isLoadData && !resData?.challengeList[0] && (
          <div className="no_data_wrap">
            <img src={errorImage ? errorImage : noHistory}></img>
          </div>
        )}
        {resData?.challengeList[0] && (
          <div className="my_save inner">
            <div className="save_box_wrap">
              {resData?.challengeList.map((el, idx) => {
                return (
                  <SaveItem
                    key={idx}
                    challenge_no={el.challenge_no}
                    title={el.ch_title}
                    isClosed={el.is_closed}
                  />
                );
              })}
              {/* <Link to="/MyActive/point" className="bBtn btn_ty01">
                MY ECO POINT 확인하기
              </Link> */}
            </div>
          </div>
        )}
      </div>
      {closeToast && (
        <div className="toast_wrap">
          <div className="dim" onClick={() => setCloseToast(false)}></div>
          <div className="toast">
            <div className="toast_head">
              <h3 className="toast_tit">필터</h3>
              <button
                className="btn_close"
                onClick={() => setCloseToast(false)}
              >
                &times;
              </button>
            </div>
            <div className="toast_body">
              <ul className="select_list">
                {["전체보기", "적립중"].map((el, idx) => {
                  return (
                    <li
                      key={idx}
                      className={filter === el ? "select" : ""}
                      onClick={() => {
                        setFilter(el);
                        setCloseToast(false);
                      }}
                    >
                      {el}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function SaveItem({ challenge_no, title, isClosed }) {
  const { mb_no, postData, resData } = useCheckToken();
  const [showList, setShowList] = useState(false);

  const loadDetailData = () => {
    postData("history/stamp/show", { mb_no, challenge_no });
  };

  return (
    <div className={"save_box box_ty01 " + (isClosed === 1 ? "end" : "")}>
      <h4
        className={"ch_tit " + (showList ? "open" : "")}
        onClick={() => {
          if (!showList && !resData) loadDetailData();
          setShowList(!showList);
        }}
      >
        {title}
      </h4>
      {showList && resData && (
        <div className="ch_stamp_area">
          <div className="stamp_group">
            {[...new Array(resData.stampResult.ch_max_stamp)].map((el, idx) => {
              const data = resData.stampResult;
              const className = idx + 1 <= data.stamp ? "active " : "";
              const stampType =
                (idx + 1) % data.stamp_to_point === 0 ? "point" : "";
              const pointText = stampType
                ? (data.ch_point_price?.toLocaleString("ko-KR") || 0) + "P"
                : "";
              return (
                <span key={idx} className={"stamp " + className + stampType}>
                  {pointText}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
