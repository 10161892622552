import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { findIDAtom } from "../../recoil/atoms";

export default function FindIDComplete() {
  const [findID, setFindID] = useRecoilState(findIDAtom);

  return (
    <div id="findIdComplete" className="inner">
      <div className="id_box">
        <p className="c_green">{findID.mb_id}</p>
      </div>
      <p className="text">입력하신 휴대폰 번호로 가입된 아이디입니다.</p>
      <p className="text">개인정보 보호를 위해 뒷자리는 **으로 표시됩니다.</p>
      <div className="btn_wrap">
        <Link to="/FindPw" className="btn_ty01 gray bBtn half">
          비밀번호 찾기
        </Link>
        <Link to="/Login" className="btn_ty01 bBtn right half">
          로그인
        </Link>
      </div>
    </div>
  );
}
