import { useCalcSelect } from "../../../hooks/bundle_hooks";
export default function TrafficType01() {
  const { selectSubCat, selectCheck } = useCalcSelect("교통");
  return (
    <div className="calc_traffic">
      <div className="traffic_wrap step_box step01 pt20">
        <h5 className="question">
          어떤 교통 수단을 <br />
          이용하셨나요?
        </h5>
        {/* <span className="multi">복수선택 가능</span> */}
        <ul className="category_list d-flex">
          {[
            "1,000cc 미만 자가용",
            "1,600cc 미만 자가용",
            "2,000cc 미만 자가용",
            "2,000cc 초과 자가용",
            "버스",
            "지하철",
            "일반기차",
            "KTX",
          ].map((el, idx) => {
            return (
              <li
                key={idx}
                className={selectCheck(el)}
                data-title={el}
                onClick={selectSubCat}
              >
                {el}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
