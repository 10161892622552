import TrafficType01 from "./pages/TrafficType01";
import TrafficType02 from "./pages/TrafficType02";
import TrafficType03 from "./pages/TrafficType03";
import TrafficType04 from "./pages/TrafficType04";
import TrafficType05 from "./pages/TrafficType05";
import TravelType01 from "./pages/TravelType01";
import TravelType02 from "./pages/TravelType02";
import TravelType03 from "./pages/TravelType03";
import TravelType04 from "./pages/TravelType04";
import TravelType05 from "./pages/TravelType05";
import TravelType06 from "./pages/TravelType06";
import TravelType07 from "./pages/TravelType07";
import TravelType08 from "./pages/TravelType08";
import TravelType09 from "./pages/TravelType09";
import Cooking from "./pages/Cooking";
import Electricity from "./pages/Electricity";
import Water from "./pages/Water";
import Gas from "./pages/Gas";
import Trash from "./pages/Trash";
import Result from "./CalculatorResult";

export default function CalculatorDetail(props) {
  const { handleInput, dataForm, setDataForm, id, initial } = props;
  const oilType = {
    smallCar: [
      ["휘발유", "gasoline"],
      ["경유", "disel"],
      ["LPG", "lgp"],
    ],
    smallVan: [
      ["휘발유", "gasoline"],
      ["경유", "disel"],
      ["LPG", "lgp"],
    ],
    mediumCar: [
      ["휘발유", "gasoline"],
      ["경유", "disel"],
      ["LPG", "lgp"],
    ],
    largeCar: [
      ["휘발유", "gasoline"],
      ["경유", "disel"],
      ["LPG", "lgp"],
    ],
  };
  return (
    <>
      {
        {
          Traffic: <TrafficType01 />,
          TrafficSmallCar01: <TrafficType02 handleInput={handleInput} dataForm={dataForm} oilType={oilType.smallCar} />,
          TrafficSmallCar02: <TrafficType03 handleInput={handleInput} dataForm={dataForm} />,
          TrafficSmallVan01: <TrafficType02 handleInput={handleInput} dataForm={dataForm} oilType={oilType.smallVan} />,
          TrafficSmallVan02: <TrafficType03 handleInput={handleInput} dataForm={dataForm} />,
          TrafficMediumCar01: <TrafficType02 handleInput={handleInput} dataForm={dataForm} oilType={oilType.mediumCar} />,
          TrafficMediumCar02: <TrafficType03 handleInput={handleInput} dataForm={dataForm} />,
          TrafficLargeCar01: <TrafficType02 handleInput={handleInput} dataForm={dataForm} oilType={oilType.largeCar} />,
          TrafficLargeCar02: <TrafficType03 handleInput={handleInput} dataForm={dataForm} />,
          TrafficBus: <TrafficType04 handleInput={handleInput} dataForm={dataForm} />,
          TrafficSubway: <TrafficType04 handleInput={handleInput} dataForm={dataForm} />,
          TrafficTrain: <TrafficType05 handleInput={handleInput} dataForm={dataForm} />,
          TrafficKTX: <TrafficType05 handleInput={handleInput} dataForm={dataForm} />,
          Travel: <TravelType01 handleInput={handleInput} dataForm={dataForm} />,
          TravelCar: <TravelType02 />,
          TravelSmallCar01: <TravelType03 handleInput={handleInput} dataForm={dataForm} oilType={oilType.smallCar} />,
          TravelSmallCar02: <TravelType04 handleInput={handleInput} dataForm={dataForm} />,
          TravelSmallVan01: <TravelType03 handleInput={handleInput} dataForm={dataForm} oilType={oilType.smallVan} />,
          TravelSmallVan02: <TravelType04 handleInput={handleInput} dataForm={dataForm} />,
          TravelMediumCar01: <TravelType03 handleInput={handleInput} dataForm={dataForm} oilType={oilType.mediumCar} />,
          TravelMediumCar02: <TravelType04 handleInput={handleInput} dataForm={dataForm} />,
          TravelLargeCar01: <TravelType03 handleInput={handleInput} dataForm={dataForm} oilType={oilType.largeCar} />,
          TravelLargeCar02: <TravelType04 handleInput={handleInput} dataForm={dataForm} />,
          TravelTexi: <TravelType05 handleInput={handleInput} dataForm={dataForm} />,
          TravelFlightDomestic: <TravelType08 handleInput={handleInput} dataForm={dataForm} setDataForm={setDataForm} />,
          TravelFlightInternational: <TravelType09 handleInput={handleInput} dataForm={dataForm} setDataForm={setDataForm} />,
          TravelBus: <TravelType06 handleInput={handleInput} dataForm={dataForm} />,
          TravelSubway: <TravelType06 handleInput={handleInput} dataForm={dataForm} />,
          TravelTrain: <TravelType07 handleInput={handleInput} dataForm={dataForm} />,
          TravelKTX: <TravelType07 handleInput={handleInput} dataForm={dataForm} />,
          Cooking: <Cooking handleInput={handleInput} dataForm={dataForm} />,
          Electricity: <Electricity handleInput={handleInput} dataForm={dataForm} />,
          Water: <Water handleInput={handleInput} dataForm={dataForm} />,
          Gas: <Gas handleInput={handleInput} dataForm={dataForm} />,
          Trash: <Trash handleInput={handleInput} dataForm={dataForm} />,
          Result: <Result initial={initial} />,
        }[id]
      }
    </>
  );
}
