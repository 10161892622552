import { useState } from "react";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../recoil/atoms";

export function useSelectInfo() {
  const [userData, setUserData] = useRecoilState(userDataAtom);
  // const [choiceForm, setChoiceForm] = useState({
  //   addr: userData.addr || "",
  //   addr_detail: userData.addr_detail || "",
  //   residence_type: userData.residence_type || "",
  //   transportation: userData.transportation || "",
  //   car_type: userData.car_type || "",
  //   cc: userData.cc || "",
  //   oil_type: userData.oil_type || "",
  //   ownership: userData.ownership || "",
  //   handling: userData.handling || "",
  //   job: userData.job || "",
  //   graduation: userData.graduation || "",
  //   marriage: userData.marriage || "",
  // });

  const [choiceForm, setChoiceForm] = useState({
    referral: "",
    addr: "",
    detail_addr: "",
    residence_type: "",
    transportation: "",
    car_type: "",
    cc: "",
    oil_type: "",
    ownership: "",
    handling: "",
    job: "",
    graduation: "",
    marriage: "",
  });

  const dataSel = (e) => {
    let val = e.target.dataset.value || e.target.value;
    const type = e.target.dataset.type;
    if (type === "cc") val = val.replace(/[^0-9]/, "");
    setChoiceForm({ ...choiceForm, [type]: val });
  };

  return { choiceForm, setChoiceForm, dataSel };
}
